import { useVaxHealthDamagesLazyQuery } from 'api/glob.api.js';
import { SearchableFilter } from 'components/impfschaeden/uebersicht/components/SearchableFilter.js';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cloneDeep from 'lodash/cloneDeep';
import orderBy from 'lodash/orderBy';
import pull from 'lodash/pull';
import { useSelector } from 'react-redux';

export const getChildrenIds = (tag, tags) => {
	let result = [];
	let iterationResult = [tag.glob_health_damage_id];
	while (iterationResult.length > 0) {
		iterationResult = tags.filter((item) => {
			return iterationResult.indexOf(item.parent_health_damage_id) > -1;
		});
		iterationResult = iterationResult.map((item) => {
			return item.glob_health_damage_id;
		});
		result = [...result, ...iterationResult];
	}
	return result;
};

const VaxDamageFilter = ({ filter, updateFilter, onBack, handleClose }) => {
	const [t] = useTranslation();
	const [options, setOptions] = useState([]);
	const [selectChilds, setSelectChilds] = useState(true);
	const vaxHealthDamages = useSelector(
		(state) => state.masterData.vaxHealthDamages
	);

	const updateSelectChilds = () => {
		setSelectChilds(!selectChilds);
	};

	useEffect(() => {
		if (vaxHealthDamages?.length > 0) {
			setOptions(
				vaxHealthDamages.map((health_damage) => {
					return {
						name: t(
							'master_data.health_damage.' +
								health_damage.health_damage_i18n_key
						),
						key: health_damage.health_damage_i18n_key,
						id: health_damage.glob_health_damage_id,
						count: health_damage.vax_health_damages_count,
					};
				})
			);
		}
	}, [vaxHealthDamages]);

	const onSelect = (tag) => {
		const remove =
			filter?.vax_damage?.indexOf(tag.glob_health_damage_id) > -1 &&
			filter?.vax_damage?.indexOf(tag.glob_health_damage_id.toString()) > -1;
		const currentVaxDamages = filter.vax_damage || [];
		let selectedIds = [];
		if (!selectChilds) {
			selectedIds = [tag.glob_health_damage_id];
			// updateFilter({
			// 	vax_damage: [...currentVaxDamages, tag.glob_health_damage_id],
			// });
		} else {
			selectedIds = [
				tag.glob_health_damage_id,
				...getChildrenIds(tag, globHealthDamages),
			];
		}
		selectedIds = selectedIds.map((id) => {
			return id.toString();
		});

		if (remove) {
			let copy = [...currentVaxDamages];
			pull(copy, ...selectedIds);
			updateFilter({
				vax_damage: copy,
			});
		} else {
			updateFilter({
				vax_damage: [...currentVaxDamages, ...selectedIds],
			});
		}
	};

	const defaultOption = null;

	return (
		<SearchableFilter
			selected={filter.vax_damage || []}
			selectChilds={selectChilds}
			setSelectChilds={updateSelectChilds}
			onSelect={onSelect}
			options={options}
			defaultOption={defaultOption}
			updateFilter={updateFilter}
			filter={filter}
			onBack={onBack}
			filterKey='vax_damage'
			title={t('common.impfschaden')}
			placeholder={t('common.impfschaden_suchen')}
			handleClose={handleClose}
			skipTranslation={true}
			display={(option, rawOption) => {
				return '' + rawOption.count + ' ' + t('common.faelle');
			}}
		/>
	);
};

VaxDamageFilter.propTypes = {
	filter: PropTypes.object,
	updateFilter: PropTypes.func,
	onBack: PropTypes.func,
	handleClose: PropTypes.func,
};

export { VaxDamageFilter };
