import { useLoginMutation } from 'api/user.api';
import { useRouter } from 'next/router';
import { setJWT, getCurrentUser } from 'service/user.service';
import { settingsActions } from 'store/settingsSlice.ts';
import { useDispatch } from 'react-redux';
import { errorActions } from 'store/errorSlice.js';

const useLogin = () => {
	const [submitLogin] = useLoginMutation();
	const dispatch = useDispatch();

	const params = new URLSearchParams(window.location.search);

	const returnUrl = params.get('returnUrl');
	const router = useRouter();

	const login = async (payload) => {
		const result = await submitLogin(payload).unwrap();

		if (result.success) {
			setJWT(result.token);
			dispatch(settingsActions.setToken(result.token));
			dispatch(settingsActions.setUser(getCurrentUser()));
			dispatch(errorActions.removeAllErrors());
			const pathname =
				getCurrentUser()?.is_moderator == true
					? '/adminbereich'
					: '/nutzerbereich';
			if (returnUrl === null) {
				router.push({
					pathname: pathname,
					query: {},
				});
			} else {
				router.push(decodeURIComponent(returnUrl));
			}
		} else {
			//TODO handle error
			return result;
		}
	};
	return login;
};

export default useLogin;
