import { createSlice } from '@reduxjs/toolkit';

export type AgeGroup = {
	glob_age_group_id: number;
	age_group_i18n_key: string;
	lang_iso_code: string;
	created_at: string;
	updated_at: string;
};

export type DamageOccurred = {
	glob_damage_occurred_id: number;
	occurred_i18n_key: string;
	lang_iso_code: string;
	ordnr: number;
	created_at: string;
	updated_at: string;
};

export type MasterData = {
	globAgeGroups: AgeGroup[];
	globDamageOccured: DamageOccurred[];
	globHealthResult: any[];
	globReferrers: any[];
	vaxHealthDamages: any[];
};

const masterDataSlice = createSlice({
	name: 'masterData',
	initialState: {
		globAgeGroups: [],
		globDamageOccurred: [],
		globHealthResults: [],
		globReferrers: [],
		globHealthDamages: [],
		vaxRelationships: [],
		vaxManufacturers: [],
		vaxHealthDamages: [],
		vaxTypeCounts: [],
		vaxManufacturerCounts: [],
		countryCounts: [],
		languageCounts: [],
		vaxPreIllnessCounts: [],
		vaxTypes: [],
		countries: [],
		languages: [],
		salutations: [],
		titles: [],
	},
	reducers: {
		update(state, action) {
			return { ...state, ...action.payload };
		},
	},
});

export const masterDataActions = masterDataSlice.actions;

export default masterDataSlice.reducer;
