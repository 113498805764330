import PropTypes from 'prop-types';

const FilterOptionTile = ({ onClick, selected, option, isDefault }) => {
	let className = 'p-3 border w-100';
	if (selected || isDefault) {
		className += ' fw-bolder border-primary';
	}

	return (
		<div
			className='d-flex  align-items-center cursor-pointer px-3 pt-3 justify-items-between'
			onClick={onClick}
		>
			<div className={className}>{option}</div>
		</div>
	);
};

FilterOptionTile.propTypes = {
	selected: PropTypes.bool,
	isDefault: PropTypes.bool,
	onClick: PropTypes.func,
	option: PropTypes.string,
	index: PropTypes.number,
};

export { FilterOptionTile };
