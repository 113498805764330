import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { emptyFilter } from 'components/impfschaeden/uebersicht/VaxOverview.js';

export const vaxApi = createApi({
	reducerPath: 'vaxApi',
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.API_URL + 'v1/vax/',
		prepareHeaders: (headers, api) => {
			headers.set('accept', 'application/json');
			headers.set(
				'X-User-Language',
				api.getState().settings.language?.toLowerCase()
			);
			return headers;
		},
	}),
	endpoints: (build) => ({
		saveVaxFormData: build.mutation({
			query: (payload) => {
				return {
					url: 'entries',
					method: 'POST',
					body: payload,
				};
			},
		}),
		manufacturers: build.query({
			query: () => ({
				url: 'manufacturers',
			}),
		}),
		relationships: build.query({
			query: () => ({
				url: 'relationships',
			}),
		}),
		types: build.query({
			query: () => ({
				url: 'types',
			}),
		}),
		entries: build.query({
			query: (payload) => {
				let url = 'get_entries';
				if (payload.page) {
					url += '?page=' + payload.page;
				} else {
					url += '?page=1';
				}
				if (payload.sort.col) {
					url += '?sort=' + payload.sort.col + '&asc=' + payload.sort.asc;
				}
				return {
					url: url,
					method: 'POST',
					body: { ...emptyFilter, ...payload.filter, seed: payload.seed },
				};
			},
		}),
		entriesCount: build.query({
			query: (payload) => {
				let url = 'get_entries_count';
				if (payload?.page) {
					url += '?page=' + payload.page;
				} else {
					url += '?page=1';
				}
				if (payload?.sort.col) {
					url += '?sort=' + payload.sort.col + '&asc=' + payload.sort.asc;
				}
				return {
					url: url,
					method: 'POST',
					body: payload?.filter,
				};
			},
		}),
		entry: build.query({
			query: (payload) => {
				return { url: 'entry/' + payload };
			},
		}),
		getLexicon: build.query({
			query: () => {
				return { url: 'get_lexicon?v=' + Date.now() };
			},
		}),
		countryCounts: build.query({
			query: () => {
				return { url: 'country_counts' };
			},
		}),
		vaccinationCounts: build.query({
			query: () => {
				return { url: 'vaccination_counts' };
			},
		}),

		manufacturerCounts: build.query({
			query: () => {
				return { url: 'manufacturer_counts' };
			},
		}),

		test: build.query({
			query: () => {
				return {
					url: 'test',
					method: 'POST',
					body: { ...emptyFilter },
				};
			},
		}),
	}),
});

export const useManufacturersLazyQuery =
	vaxApi.endpoints.manufacturers.useLazyQuery;
export const useRelationshipsLazyQuery =
	vaxApi.endpoints.relationships.useLazyQuery;
export const useTypesLazyQuery = vaxApi.endpoints.types.useLazyQuery;
export const useEntriesLazyQuery = vaxApi.endpoints.entries.useLazyQuery;
export const useEntriesCountLazyQuery =
	vaxApi.endpoints.entriesCount.useLazyQuery;
export const useCountryCountsLazyQuery =
	vaxApi.endpoints.countryCounts.useLazyQuery;
export const useVaccinationCountsLazyQuery =
	vaxApi.endpoints.vaccinationCounts.useLazyQuery;
export const useManufacturerCountsLazyQuery =
	vaxApi.endpoints.manufacturerCounts.useLazyQuery;
export const useEntryLazyQuery = vaxApi.endpoints.entry.useLazyQuery;
export const useTestLazyQuery = vaxApi.endpoints.test.useLazyQuery;

export const {
	useSaveVaxFormDataMutation,
	useManufacturersQuery,
	useRelationshipsQuery,
	useTypesQuery,
	useEntriesQuery,
	useEntriesCountQuery,
	useCountryCountsQuery,
	useEntryQuery,
	useLazyGetLexiconQuery,
} = vaxApi;
