import { OriginId } from './OriginId.tsx';

export type SourceReferenceProps = {
	caseId?: string;
	caseIdLabel?: string;
	source: string;
	sourceLabel: string;
	href: string;
	sealDate: string;
};

const SourceReference = (props: SourceReferenceProps) => {
	const { caseId, caseIdLabel, source, sourceLabel, href, sealDate } = props;
	return (
		<>
			{source && (
				<>
					<div className='mt-2'>
						{sourceLabel}
						{!href && ` ${source}`}
						{href && (
							<a
								className='ms-1'
								style={{ wordBreak: 'break-all' }}
								href={href}
								target='_blank'
								rel='noreferrer'
							>
								{source}
							</a>
						)}
						{caseId && <OriginId label={caseIdLabel} id={caseId} />}
					</div>
					{sealDate && (
						<div className='justify-content-right d-right mt-4'>{sealDate}</div>
					)}
				</>
			)}
		</>
	);
};
export { SourceReference };
