import { API_URL } from 'env.js';

const getLocalStorage = () => {
	return { ...localStorage };
};

const getCookieValues = () => {
	let pairs = document.cookie.split(/; ?/);
	let cookies = {};
	for (const element of pairs) {
		let pair = element.split('=');
		cookies[(pair[0] + '').trim()] = decodeURIComponent(
			pair.slice(1).join('=')
		);
	}
	return cookies;
};

const getErrorContext = () => {
	return {
		...getLocalStorage(),
		...getCookieValues(),
	};
};

const postError = (error: any) => {
	fetch(`${API_URL}v1/glob/frontend_error`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			error: {
				error: JSON.stringify(error, Object.getOwnPropertyNames(error)),
				...getErrorContext(),
			},
		}),
	})
		.then((response) => response.json())
		.then((data) => {
			if (data.result == 'success') {
				console.log('error was successfully sent to backend');
			}
		})
		.catch((err) => {
			console.log('error sending error to backend', err);
		});
};

export default postError;
