import styled from 'styled-components';
import React from 'react';
import { PersonFillLock, Tags } from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';
import {
	VaxIcon,
	NewspaperIcon,
	HandPointRightIcon,
	PencilIcon,
	HomeIcon,
	ActiveLink,
	Accordion,
	ListGroupItem as ListGroupsItem_old,
	ListGroup,
	PersonCircle,
	PhoneVibrateFill,
	CapsulePill,
	PencilSquare,
	PersonLinesFill,
	SignInIcon,
	TvIcon,
} from 'components/ui';
import { useTranslation } from 'next-i18next';
import { Groups, Language } from '@mui/icons-material';
const Group = styled(ListGroup)``;

const ListGroupItem = styled(ListGroupsItem_old)`
	padding-right: 0;
`;

const SidebarAccordion = styled(Accordion)`
	.list-group-item {
		background: transparent;
		padding-left: 0;
	}

	.accordion-item {
		.accordion-header {
			.accordion-button {
				padding: 0;
				background-color: transparent;
			}
		}
		.accordion-collapse {
			background-color: transparent;
		}
	}
`;

function NavItems() {
	const [t] = useTranslation();
	const user = useSelector((state) => state.settings.currentUser);

	const getUserAreaLink = () => {
		if (user && !user.is_moderator && !user.is_admin) {
			return '/nutzerbereich';
		} else if (user && (user.is_moderator || user.is_admin)) {
			return '/adminbereich';
		} else {
			return '/';
		}
	};
	return (
		<Group variant='flush' className='rounded-0'>
			{user && (
				<SidebarAccordion flush className='border-bottom' alwaysOpen>
					<Accordion.Item eventKey='0'>
						<Accordion.Header>
							<ListGroupItem className='d-flex align-items-center cursor-pointer'>
								<PersonCircle size={20} />
								{user && !(user.is_moderator == 1 || user.is_admin == 1) && (
									<span className='ms-3' style={{ fontWeight: 600 }}>
										{t('common.nutzerbereich')}
									</span>
								)}
								{user && (user.is_moderator == 1 || user.is_admin == 1) && (
									<span className='ms-3' style={{ fontWeight: 600 }}>
										{t('common.adminbereich')}
									</span>
								)}
							</ListGroupItem>
						</Accordion.Header>
						<Accordion.Body className='py-0 ps-4 pe-0'>
							<ActiveLink href={getUserAreaLink()}>
								<ListGroupItem className='py-2 cursor-pointer'>
									<PencilSquare size={20} />
									<span className='ms-3'>
										{user &&
											user.is_moderator != 1 &&
											user.is_admin != 1 &&
											t('navigation.meine_eintraege')}
										{user &&
											(user.is_moderator == 1 || user.is_admin == 1) &&
											t('common.eintraege')}
									</span>
								</ListGroupItem>
							</ActiveLink>
							<ActiveLink href='/nutzerbereich/profil'>
								<ListGroupItem className='py-2 cursor-pointer'>
									<PersonLinesFill size={20} />
									<span className='ms-3'>{t('navigation.mein_profile')}</span>
								</ListGroupItem>
							</ActiveLink>
							<ActiveLink href='/logout'>
								<ListGroupItem className='py-2 cursor-pointer'>
									<SignInIcon />
									<span className='ms-3'>{t('navigation.abmelden')}</span>
								</ListGroupItem>
							</ActiveLink>
							{user && user.is_admin == 1 && (
								<ActiveLink href='/adminbereich/schlagworte'>
									<ListGroupItem className='py-2 cursor-pointer'>
										<Tags size={20} />
										<span className='ms-3'>{t('common.schlagworte')}</span>
									</ListGroupItem>
								</ActiveLink>
							)}
							{user &&
								(user.is_expert == 1 ||
									user.is_admin == 1 ||
									user.is_moderator == 1) && (
									<ActiveLink href='/adminbereich/arbeitsgruppen'>
										<ListGroupItem className='py-2 cursor-pointer'>
											<Groups size={20} />
											<span className='ms-3'>{t('common.arbeitsgruppen')}</span>
										</ListGroupItem>
									</ActiveLink>
								)}
							{user &&
								(user.is_moderator == 1 ||
									user.is_translator == 1 ||
									user.is_expert == 1 ||
									user.is_admin == 1) && (
									<ActiveLink href='/adminbereich/benutzerrechte'>
										<ListGroupItem className='py-2 cursor-pointer'>
											<PersonFillLock size={20} />
											<span className='ms-3'>{t('common.benutzerrechte')}</span>
										</ListGroupItem>
									</ActiveLink>
								)}
						</Accordion.Body>
					</Accordion.Item>
				</SidebarAccordion>
			)}
			{user && user.is_translator == 1 && (
				<ActiveLink href='/uebersetzung'>
					<ListGroupItem className='border-bottom px-0 cursor-pointer'>
						<Language />
						<span className='ms-3'>{t('common.uebersetzung')}</span>
					</ListGroupItem>
				</ActiveLink>
			)}
			{user && user.user_id == 1 && (
				<ActiveLink href='/test'>
					<ListGroupItem className='border-bottom px-0 cursor-pointer'>
						<HomeIcon />
						<span className='ms-3'>TEST</span>
					</ListGroupItem>
				</ActiveLink>
			)}
			<ActiveLink href={'/' + t('routing.startpage')}>
				<ListGroupItem className='border-bottom px-0 cursor-pointer'>
					<HomeIcon />
					<span className='ms-3'>{t('navigation.startseite')}</span>
				</ListGroupItem>
			</ActiveLink>
			<SidebarAccordion flush className='border-bottom'>
				<Accordion.Item eventKey='2'>
					<Accordion.Header>
						<ListGroupItem className='d-flex align-items-center cursor-pointer'>
							<VaxIcon />
							<span className='ms-3' style={{ fontWeight: 600 }}>
								{t('common.impfschaden')}
							</span>
						</ListGroupItem>
					</Accordion.Header>
					<Accordion.Body className='py-0 ps-4 pe-0'>
						{/* <ActiveLink href='/impfschaden/news'>
							<ListGroupItem className='py-2 cursor-pointer'>
								<NewspaperIcon />
								<span className='ms-3'>{t('navigation.news')}</span>
							</ListGroupItem>
						</ActiveLink> */}
						<ActiveLink href={'/' + t('routing.impfschaden')}>
							<ListGroupItem className='py-2 cursor-pointer'>
								<HandPointRightIcon />
								<span className='ms-3'>{t('common.uebersicht')}</span>
							</ListGroupItem>
						</ActiveLink>
						<ActiveLink
							href={
								'/' + t('routing.impfschaden') + '/' + t('routing.erfassen')
							}
						>
							<ListGroupItem className='py-2 cursor-pointer'>
								<PencilIcon />
								<span className='ms-3'>{t('common.erfassen')}</span>
							</ListGroupItem>
						</ActiveLink>
					</Accordion.Body>
				</Accordion.Item>
				<Accordion.Item eventKey='3'>
					<Accordion.Header>
						<ListGroupItem className='d-flex align-items-center cursor-pointer'>
							<PhoneVibrateFill size={25} />
							<span className='ms-3' style={{ fontWeight: 600 }}>
								{t('navigation.mobilfunk_und_krebs')}
							</span>
						</ListGroupItem>
					</Accordion.Header>
					<Accordion.Body className='py-0 ps-4 pe-0'>
						{/* <ActiveLink href='/mobilfunkundkrebs/news'>
							<ListGroupItem className='py-2 cursor-pointer'>
								<NewspaperIcon />
								<span className='ms-3'>{t('navigation.news')}</span>
							</ListGroupItem>
						</ActiveLink> */}
						<ActiveLink href='https://v1.vetopedia.org/mobilfunk-krebs'>
							<ListGroupItem className='py-2 cursor-pointer'>
								<HandPointRightIcon />
								<span className='ms-3'>{t('common.uebersicht')}</span>
							</ListGroupItem>
						</ActiveLink>
						<ActiveLink href='https://my.vetopedia.org/?v=krebs'>
							<ListGroupItem className='py-2 cursor-pointer'>
								<PencilIcon />
								<span className='ms-3'>{t('common.erfassen')}</span>
							</ListGroupItem>
						</ActiveLink>
					</Accordion.Body>
				</Accordion.Item>
				<Accordion.Item eventKey='4'>
					<Accordion.Header>
						<ListGroupItem className='d-flex align-items-center cursor-pointer'>
							<CapsulePill size={25} />
							<span className='ms-3' style={{ fontWeight: 600 }}>
								{t('navigation.pharmamedizinopfer')}
							</span>
						</ListGroupItem>
					</Accordion.Header>
					<Accordion.Body className='py-0 ps-4 pe-0'>
						{/* <ActiveLink href='/pharmamedizinopfer/news'>
							<ListGroupItem className='py-2 cursor-pointer'>
								<NewspaperIcon />
								<span className='ms-3'>{t('navigation.news')}</span>
							</ListGroupItem>
						</ActiveLink> */}
						<ActiveLink href='https://v1.vetopedia.org/pharma'>
							<ListGroupItem className='py-2 cursor-pointer'>
								<HandPointRightIcon />
								<span className='ms-3'>{t('common.uebersicht')}</span>
							</ListGroupItem>
						</ActiveLink>
						<ActiveLink href='https://my.vetopedia.org/?v=pharma'>
							<ListGroupItem className='py-2 cursor-pointer'>
								<PencilIcon />
								<span className='ms-3'>{t('common.erfassen')}</span>
							</ListGroupItem>
						</ActiveLink>
					</Accordion.Body>
				</Accordion.Item>
				<Accordion.Item eventKey='5'>
					<Accordion.Header>
						<ListGroupItem className='d-flex align-items-center cursor-pointer'>
							<TvIcon size={25} />
							<span className='ms-3' style={{ fontWeight: 600 }}>
								{t('navigation.medienverleumdungen_umbruch')}
							</span>
						</ListGroupItem>
					</Accordion.Header>
					<Accordion.Body className='py-0 ps-4 pe-0'>
						{/* <ActiveLink href='/medienverleumdungen/news'>
							<ListGroupItem className='py-2 cursor-pointer'>
								<NewspaperIcon />
								<span className='ms-3'>{t('navigation.news')}</span>
							</ListGroupItem>
						</ActiveLink> */}
						<ActiveLink href='https://v1.vetopedia.org/medienverleumdung'>
							<ListGroupItem className='py-2 cursor-pointer'>
								<HandPointRightIcon />
								<span className='ms-3'>{t('common.uebersicht')}</span>
							</ListGroupItem>
						</ActiveLink>
						<ActiveLink href='https://my.vetopedia.ch/?v=form'>
							<ListGroupItem className='py-2 cursor-pointer'>
								<PencilIcon />
								<span className='ms-3'>{t('common.erfassen')}</span>
							</ListGroupItem>
						</ActiveLink>
					</Accordion.Body>
				</Accordion.Item>
				<Accordion.Item eventKey='6'>
					<Accordion.Header>
						<ListGroupItem className='d-flex align-items-center cursor-pointer'>
							<NewspaperIcon />
							<span className='ms-3' style={{ fontWeight: 600 }}>
								{t('common.zensurkiosk')}
							</span>
						</ListGroupItem>
					</Accordion.Header>
					<Accordion.Body className='py-0 ps-4 pe-0'>
						<ActiveLink href={'/' + t('routing.zensurkiosk')}>
							<ListGroupItem className='py-2 cursor-pointer'>
								<HandPointRightIcon />
								<span className='ms-3'>{t('common.uebersicht')}</span>
							</ListGroupItem>
						</ActiveLink>
						<ActiveLink
							href={
								'/' + t('routing.zensurkiosk') + '/' + t('routing.erfassen')
							}
						>
							<ListGroupItem className='py-2 cursor-pointer'>
								<PencilIcon />
								<span className='ms-3'>{t('common.erfassen')}</span>
							</ListGroupItem>
						</ActiveLink>
					</Accordion.Body>
				</Accordion.Item>
			</SidebarAccordion>
		</Group>
	);
}

export default NavItems;
