import { SearchableFilter } from 'components/impfschaeden/uebersicht/components/SearchableFilter.js';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import orderBy from 'lodash/orderBy';

const CountryFilter = ({ filter, updateFilter, onBack, handleClose }) => {
	const [t] = useTranslation();
	const [options, setOptions] = useState([]);
	const countries = useSelector((state) => state.masterData.countries);
	const countryCounts = useSelector((state) => state.masterData.countryCounts);

	useEffect(() => {
		if (countries?.length > 0 && countryCounts?.length > 0) {
			setOptions(
				orderBy(
					countries
						.map((country) => {
							return {
								key: country.country_iso_code?.toLowerCase(),
								name: country.country_name
									? [country.country_name, country.country_name_de].join(', ')
									: country.country_name_de,
								id: country.country_iso_code,
								count: countryCounts.find((item) => {
									return (
										item.country_iso_code?.toLowerCase() ==
										country.country_iso_code?.toLowerCase()
									);
								})?.total,
							};
						})
						.filter((item) => {
							return !!item.count;
						}),
					['count'],
					['desc']
				)
			);
		}
	}, [countries]);

	const defaultOption = null;

	// console.log('country filter', options, countries, countryCounts);

	return (
		<SearchableFilter
			options={options}
			defaultOption={defaultOption}
			updateFilter={updateFilter}
			filter={filter}
			onBack={onBack}
			filterKey='country'
			title={t('common.land')}
			placeholder={t('common.land_suchen')}
			handleClose={handleClose}
			keyInsteadId={true}
			skipTranslation={true}
			display={(option, rawOption) => {
				return '' + rawOption.count + ' ' + t('common.faelle');
			}}
		/>
	);
};

CountryFilter.propTypes = {
	filter: PropTypes.object,
	updateFilter: PropTypes.func,
	onBack: PropTypes.func,
	handleClose: PropTypes.func,
};

export { CountryFilter };
