// import MainNavigation from './MainNavigation';
import Header from 'components/layout/Header/Header';
import Footer from './Footer';
import SideNav from './Navigation/SideNav';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import { useState } from 'react';
// import Breadcrumb from './Breadcrumb';
import useVaxFormMasterData from 'components/impfschaeden/assistent/hooks/useVaxFormMasterData.js';
import Breadcrumb from 'components/layout/Breadcrumb.js';
import { CircularProgress } from '@mui/material';

const Wrapper = styled.div`
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
`;

const Centered = styled.div`
	width: 100%;
	max-width: 1440px;
	${media.xxl`
		width: 1320px;
	`}
	${media.xl`
		width: 1320px;
	`}
	${media.lg`
		width: 1320px;
	`}
`;

const PageContainer = styled.div`
	display: flex;
	justify-content: space-between;
	justify-items: center;
	align-content: space-between;
	align-items: start;
`;

const SidebarContainer = styled.div`
	position: relative;
	height: 100vh;
	width: 270px;
	@media (min-width: 1400px) {
		// xxl
		display: block;
	}
	@media (min-width: 1200px) and (max-width: 1400px) {
		// xl
		display: none;
	}
	@media (min-width: 992px) and (max-width: 1200px) {
		// lg
		display: none;
	}
	@media (min-width: 768px) and (max-width: 992px) {
		// md
		display: none;
	}
	@media (min-width: 576px) and (max-width: 768px) {
		// sm
		display: none;
	}
	@media (min-width: 0px) and (max-width: 576px) {
		// xs
		display: none;
	}
	@media print {
		display: none;
	}
`;

const MainContainer = styled.div`
	@media (min-width: 1400px) {
		// xxl
		width: 1050px;
	}
	@media (min-width: 1200px) and (max-width: 1400px) {
		// xl
		width: 100vw;
	}
	@media (min-width: 992px) and (max-width: 1200px) {
		// lg
		width: 100vw;
	}
	@media (min-width: 768px) and (max-width: 992px) {
		// md
		width: 100vw;
	}
	@media (min-width: 576px) and (max-width: 768px) {
		// sm
		width: 100vw;
	}
	@media (min-width: 0px) and (max-width: 576px) {
		// xs
		width: 100vw;
	}
	@media print {
		width: 100vw;
	}
`;

const Main = styled.div``;

const StickySidebar = styled.div`
	display: none;
	position: fixed;
	z-index: 500;
	bottom: 0;
	transition: 0.5s;
	overflow: scroll;
	background: ${(props) => props.theme.white};
	> div {
		background: ${(props) => props.theme.white};
		height: 100%;
	}
	&.open {
		max-height: 100vh;
		display: block !important;
	}
	${media.xs`
		width: 100vw;
		top: 20px;
	`}
	${media.sm`
		width: 100vw;
		top: 20px;
	`}
	${media.md`
		width: 50vw;
		top: 20px;
	`}
	${media.lg`
			width: 270px;
	`}
	${media.xl`
		/* max-height: 100vh;
		display: block !important; */
		width: 270px;
	`}
	${media.xxl`
		max-height: 100vh;
		display: block !important;
		width: 270px;
	`}
	@media print {
		display: none;
	}
`;

const MainContent = styled.div`
	background-color: ${(props) => props.theme.contentBg};
`;

const StickyHeader = styled.div`
	background: white;
	top: 0;
	z-index: 1200;
	position: sticky;
`;

const layout = (props) => {
	const [open, setOpen] = useState(false);
	const masterDataFetchers = useVaxFormMasterData();
	if (window) {
		window.getHealthDamages = masterDataFetchers.getHealthDamages;
	}
	const CustomBreadcrumb = props.children.props.customBreadcrumb;
	const CustomNav = props.children.props.customNav;
	if (!window.translationLoaded) {
		return (
			<div className='d-flex justify-content-center w-100 h-100'>
				<div
					className='d-flex flex-column justify-content-center'
					style={{ height: '100vh' }}
				>
					<CircularProgress />
				</div>
			</div>
		);
	}
	console.log('layout', props);
	return (
		<Wrapper>
			<Centered className='px-0'>
				<PageContainer>
					<SidebarContainer>
						<StickySidebar className={open ? 'open' : ''}>
							<SideNav theme={props.theme} />
						</StickySidebar>
					</SidebarContainer>
					<StickySidebar className={open ? 'open' : ''}>
						<SideNav theme={props.theme} />
					</StickySidebar>
					<MainContainer className='ps-xl-0'>
						<Main className='p-0'>
							<StickyHeader>
								<Header
									menuOpen={(expanded) => {
										setOpen(expanded);
									}}
								/>
								{props.children.props.useBreadcrumb && <Breadcrumb />}
								{CustomBreadcrumb && <CustomBreadcrumb />}
								{CustomNav && <CustomNav />}
							</StickyHeader>
							<MainContent className='pb-4'>
								<div className='px-4 pt-4'>{props.children}</div>
							</MainContent>
						</Main>
						<Footer />
					</MainContainer>
				</PageContainer>
			</Centered>
		</Wrapper>
	);
};

export default layout;
