import { Button as ButtonDefault, ButtonProps } from 'react-bootstrap';
import styled from 'styled-components';

// Workaround: Create new base Button component, because font color of default react-bootstrap wasn't correct
const ButtonBase = styled(ButtonDefault)`
	color: ${(props) =>
		[undefined, 'primary', 'secondary', 'success', 'danger', 'dark'].includes(
			props.variant
		)
			? props.theme.white
			: ''};
`;

export const KontoButton = styled(ButtonBase)`
	background-color: #00e5ff !important;
`;

// Use <button variant="link"></button> as default for new <ButtonNoneStyle></ButtonNoneStyle> component
export const ButtonNoneStyle = styled(ButtonBase).attrs({ variant: 'link' })`
	color: ${(props) => props.theme.bodyColor};
`;

export type ButtonType = ButtonProps & {
	variant?: string;
	type?: string;
	onClick: () => void;
	size?: string;
};

const Button = (props: ButtonType) => {
	switch (props.variant) {
		case 'secondary':
			return <KontoButton {...props} variant='contained' />;
		case 'light':
			return <ButtonBase {...props} variant='outline-secondary' />;
		case 'danger':
			return <ButtonBase {...props} variant='danger' />;
		case 'primary':
		default:
			return <ButtonBase {...props} variant={props.variant} />;
	}
};

export { Button };
