import { useTranslation } from 'next-i18next';
import {
	FormGroup,
	Stack,
	FormText,
	RadioButton,
	FormHelperText,
	DropdownSelect,
	Input,
	Form,
} from 'components/ui';
import { getLastYearsList } from 'lib/utils';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import range from 'lodash/range';
import without from 'lodash/without';
import dayjs from 'dayjs';
import * as duration from 'dayjs/plugin/duration';
dayjs.extend(duration);

export const isPlaceholderImage = (avatarId) => {
	const placeholderIds = range(1, 512);
	return placeholderIds.indexOf(parseInt(avatarId, 10)) > -1;
};

const PersonData = ({ update, formData, validation }) => {
	const [t] = useTranslation(['translation']);
	const { sex, age, age_unit } = formData;
	const { globAgeGroups } = useSelector((state) => state.masterData);

	const getAgeGroupOptions = (ageGroups) => {
		let ageGroupOptions = [];
		if (Array.isArray(ageGroups)) {
			ageGroupOptions = ageGroups.map((ageGroup) => ({
				value: ageGroup.glob_age_group_id,
				text: t('master_data.age_group.' + ageGroup.age_group_i18n_key),
			}));
		}
		ageGroupOptions.unshift({ value: '', text: '--' });
		return ageGroupOptions;
	};

	const getAgeDuration = (age, age_unit) => {
		const unit = age_unit == 'tag' ? 'd' : age_unit == 'monat' ? 'M' : 'y';
		return dayjs.duration(age, unit);
	};

	const getAgeGroupByAge = (age, age_unit) => {
		if (!age) {
			return 0;
		}
		const duration = getAgeDuration(age, age_unit);
		const durationMonth = duration.asMonths();
		const durationYear = duration.asYears();
		if (durationMonth <= 1) {
			return 2;
		} else if (durationMonth <= 3) {
			return 3;
		} else if (durationMonth <= 4) {
			return 4;
		} else if (durationMonth <= 6) {
			return 5;
		} else if (durationYear <= 1) {
			return 6;
		} else if (durationYear < 3) {
			return 7;
		} else if (durationYear < 5) {
			return 8;
		} else if (durationYear < 7) {
			return 9;
		} else if (durationYear < 13) {
			return 10;
		} else if (durationYear < 19) {
			return 11;
		} else if (durationYear < 30) {
			return 12;
		} else if (durationYear < 40) {
			return 13;
		} else if (durationYear < 50) {
			return 14;
		} else if (durationYear < 60) {
			return 15;
		} else if (durationYear < 70) {
			return 16;
		} else if (durationYear < 80) {
			return 17;
		} else if (durationYear < 90) {
			return 18;
		} else {
			return 19;
		}
	};

	const oneOf = (items) => {
		return items[Math.floor(Math.random() * items.length)];
	};

	const setAvatarByAgeGroupId = (ageGroupId) => {
		switch (ageGroupId) {
			case 2: // 0-1 monate
			case 3: // 2-3 monate
			case 4: // 3-4 monate
			case 5: // 5-6 monate
			case 6: // 7-12 monate
				if (formData.sex == 'm') {
					update({ avatar_glob_file_id: oneOf([10, 11]) });
				} else {
					update({ avatar_glob_file_id: oneOf([12, 13]) });
				}
				break;
			case 7: // 1-2 jahre
				if (formData.sex == 'm') {
					update({ avatar_glob_file_id: oneOf([202, 203, 204, 205, 206]) });
				} else {
					update({ avatar_glob_file_id: oneOf([230, 231, 232, 233, 234]) });
				}
				break;
			case 8: // 3-4 jahre
				if (formData.sex == 'm') {
					update({ avatar_glob_file_id: oneOf([213, 214, 215, 216, 217]) });
				} else {
					update({ avatar_glob_file_id: oneOf([248, 249, 250, 251, 252]) });
				}
				break;
			case 9: // 5-6 jahre
				if (formData.sex == 'm') {
					update({ avatar_glob_file_id: oneOf([218, 219, 220, 221, 222]) });
				} else {
					update({ avatar_glob_file_id: oneOf([253, 254, 255, 256, 257]) });
				}
				break;
			case 10: // 7-12 jahre
				if (formData.sex == 'm') {
					update({
						avatar_glob_file_id: oneOf([223, 224, 225, 226, 227, 228, 229]),
					});
				} else {
					update({
						avatar_glob_file_id: oneOf([258, 259, 260, 261, 262, 263, 264]),
					});
				}
				break;
			case 11: // 13-18 jahre
				if (formData.sex == 'm') {
					update({
						avatar_glob_file_id: oneOf([207, 208, 209, 210, 211, 212]),
					});
				} else {
					update({
						avatar_glob_file_id: oneOf([
							235, 236, 237, 238, 239, 240, 241, 242, 243, 244, 245, 246, 247,
						]),
					});
				}
				break;
			case 12: // 19-29 jahre
				if (formData.sex == 'm') {
					update({
						avatar_glob_file_id: oneOf(range(265, 292)),
					});
				} else {
					update({
						avatar_glob_file_id: oneOf(range(14, 41)),
					});
				}
				break;
			case 13: // 30-39 jahre
				if (formData.sex == 'm') {
					update({
						avatar_glob_file_id: oneOf(without(range(293, 331), 300)),
					});
				} else {
					update({
						avatar_glob_file_id: oneOf(range(42, 69)),
					});
				}
				break;
			case 14: // 40-49 jahre
				if (formData.sex == 'm') {
					update({
						avatar_glob_file_id: oneOf(range(332, 368)),
					});
				} else {
					update({
						avatar_glob_file_id: oneOf(range(71, 82)),
					});
				}
				break;
			case 15: // 50-59 jahre
				if (formData.sex == 'm') {
					update({
						avatar_glob_file_id: oneOf(range(369, 394)),
					});
				} else {
					update({
						avatar_glob_file_id: oneOf(range(83, 116)),
					});
				}
				break;
			case 16: // 60-69 jahre
				if (formData.sex == 'm') {
					update({
						avatar_glob_file_id: oneOf(without(range(395, 429), 411)),
					});
				} else {
					update({
						avatar_glob_file_id: oneOf(range(117, 154)),
					});
				}
				break;
			case 17: // 70-79 jahre
				if (formData.sex == 'm') {
					update({
						avatar_glob_file_id: oneOf(without(range(430, 454), 433)),
					});
				} else {
					update({
						avatar_glob_file_id: oneOf([
							...range(155, 159),
							...range(503, 512),
						]),
					});
				}
				break;
			case 18: // 80-89 jahre
				if (formData.sex == 'm') {
					update({
						avatar_glob_file_id: oneOf(range(455, 478)),
					});
				} else {
					update({
						avatar_glob_file_id: oneOf(range(164, 182)),
					});
				}
				break;
			case 19: // > 90 jahre
				if (formData.sex == 'm') {
					update({
						avatar_glob_file_id: oneOf(range(479, 502)),
					});
				} else {
					update({
						avatar_glob_file_id: oneOf(range(183, 201)),
					});
				}
				break;
		}
	};

	const editAge = (newData) => {
		let age = newData.age;
		if (newData.age === undefined || newData.age === null) {
			age = formData.age;
		}
		let age_unit = newData.age_unit;
		if (newData.age_unit === undefined || newData.age_unit === null) {
			age_unit = formData.age_unit;
		}
		let glob_age_groups_id = newData.glob_age_groups_id;
		if (
			newData.glob_age_groups_id === undefined ||
			newData.glob_age_groups_id === null
		) {
			glob_age_groups_id = formData.glob_age_groups_id;
		}
		if (newData.age !== null && newData.age !== undefined) {
			glob_age_groups_id = getAgeGroupByAge(age, age_unit);
		}
		if (
			isPlaceholderImage(formData.avatar_glob_file_id) &&
			formData.sex != 'unknown'
		) {
			setAvatarByAgeGroupId(parseInt(glob_age_groups_id, 10));
		}
		update({
			age,
			age_unit,
			glob_age_groups_id,
		});
	};

	const years = getLastYearsList(120).map((year) => ({
		value: year,
		text: year,
	}));
	years.unshift({ value: 0, text: t('common.unbekannt') });
	years.unshift({ value: '', text: '----' });

	const singularAgeUnitOptions = [
		{ value: 'tag', text: t('common.tag') },
		{ value: 'monat', text: t('common.monat') },
		{ value: 'jahr', text: t('common.jahr') },
	];
	const pluralAgeUnitOptions = [
		{ value: 'tag', text: t('common.tage') },
		{ value: 'monat', text: t('common.monate') },
		{ value: 'jahr', text: t('common.jahre') },
	];

	let ageLabel;
	let ageUnitOptions = pluralAgeUnitOptions;
	if (parseInt(age, 10).toString() == age) {
		if (parseInt(age, 10) > 1) {
			ageUnitOptions = pluralAgeUnitOptions;
			if (age_unit == 'tag') {
				ageLabel = t('common.tage');
			} else if (age_unit == 'monat') {
				ageLabel = t('common.monate');
			} else if (age_unit == 'jahr') {
				ageLabel = t('common.jahre');
			}
		} else {
			ageUnitOptions = singularAgeUnitOptions;
			ageLabel = t('common.' + age_unit);
		}
	}
	return (
		<FormGroup className='mb-3'>
			<Stack gap={3}>
				<div>
					<Stack direction='horizontal' gap={3} spacing={2}>
						<FormText
							className={validation['sex'] ? '' : 'text-muted'}
							style={{ color: validation['sex'] ? '#dc3545' : '' }}
						>
							{t('common.geschlecht')}:*
						</FormText>
						<RadioButton
							name='group1'
							value='m'
							label={t('impfschaeden.erfassen.maennlich')}
							checked={sex === 'm'}
							onChange={(e) => update({ sex: e.target.value })}
							isInvalid={validation['sex']}
						/>
						<RadioButton
							name='group1'
							value='w'
							label={t('impfschaeden.erfassen.weiblich')}
							checked={sex === 'w'}
							onChange={(e) => update({ sex: e.target.value })}
							isInvalid={validation['sex']}
						/>
						<RadioButton
							name='group1'
							value='unknown'
							label={t('impfschaeden.erfassen.unknown')}
							checked={sex === 'unknown'}
							onChange={(e) =>
								update({ sex: e.target.value, avatar_glob_file_id: 1 })
							}
							isInvalid={validation['sex']}
						/>
					</Stack>
					{validation['sex'] && (
						<FormHelperText style={{ color: '#dc3545' }}>
							{t('common.pflichtfeld')}
						</FormHelperText>
					)}
				</div>
				<span>
					{t(
						'impfschaeden.erfassen.genaues_alter_bei_auftreten_des_impfschadens'
					)}
				</span>
				<div className='d-flex w-50'>
					<div className='w-50'>
						<Input
							name='personData_age'
							type='number'
							placeholder={ageLabel}
							label={ageLabel}
							value={formData.age}
							onChange={(e) =>
								editAge({
									age: e.target.value,
								})
							}
							isInvalid={validation['age']}
							id='personData_age_id'
						/>
					</div>
					<DropdownSelect
						className='ms-2'
						onChange={(e) => {
							editAge({
								age_unit: e.target.value,
							});
						}}
						value={formData.age_unit}
						options={ageUnitOptions}
					/>
				</div>
				<span>{t('common.oder')}</span>
				<Form.Group>
					<DropdownSelect
						disabled={formData.age}
						label={
							t(
								'impfschaeden.erfassen.ungefaehres_alter_bei_auftreten_des_impfschadens'
							) + ' *'
						}
						aria-label={
							t(
								'impfschaeden.erfassen.ungefaehres_alter_bei_auftreten_des_impfschadens'
							) + ' *'
						}
						onChange={(e) => {
							editAge({
								glob_age_groups_id: e.target.value,
							});
						}}
						value={formData.glob_age_groups_id}
						isInvalid={validation['glob_age_groups_id']}
						options={getAgeGroupOptions(globAgeGroups)}
					/>
				</Form.Group>
			</Stack>
		</FormGroup>
	);
};

PersonData.propTypes = {
	validation: PropTypes.object,
	formData: PropTypes.object,
	update: PropTypes.func,
};

export { PersonData };
