import '../scss/main.scss';
import Layout from '../components/layout/Layout';
import { createGlobalStyle } from 'styled-components';
import { config, dom } from '@fortawesome/fontawesome-svg-core';
import { Provider } from 'react-redux';
import { wrapper } from 'store/store';
import { RouteGuard } from '../components/RouteGuard';
import { Toaster } from 'react-hot-toast';
import { ThemeProvider } from 'styled-components';
import { GridThemeProvider } from 'styled-bootstrap-grid';
import { bootstrapLayoutConfig } from 'utils/bootstrapConfig';
// @ts-ignore
import appTheme from 'scss/provideVariables.module.scss';
import PropTypes from 'prop-types';
import i18n from '../i18n'; // eslint-disable-line

import Head from 'next/head';
import ErrorBoundary, { ErrorFallback } from 'components/ErrorBoundary.js';
import Matomo from 'components/layout/Matomo.js';

import { useEffect } from 'react';
import useUpdateCheck from 'hooks/useUpdateCheck.js';

config.autoAddCss = false;
const GlobalStyles = createGlobalStyle`
     ${dom.css()}
       // DON'T Insert any other global styles you want here
			 // Use therefore styles/global.css
`;

function MyApp({ Component, ...rest }) {
	const { store, props } = wrapper.useWrappedStore(rest);
	const { pageTheme, useBreadcrumb, customBreadcrumb, customNav } = Component;
	const theme = {
		...appTheme,
		...pageTheme,
	};

	const { checkUpdateLocalStorage } = useUpdateCheck();

	useEffect(() => {
		checkUpdateLocalStorage();
	}, []);

	return (
		<>
			<Head>
				<meta name='viewport' content='width=device-width, initial-scale=1.0' />
				<link
					rel='icon'
					type='image/x-icon'
					href='/img/app_icon/favicon.ico'
				></link>
				<meta
					name='description'
					// eslint-disable-next-line max-len
					content='"Hier finden geblockte Volk-Stimmen Gehör! Längst existieren unzählige wissenschaftliche Studien, die die Schädigung durch Impfungen, Mobilfunk usw. als definitiv gefährlich und gesundheitsschädigend nachgewiesen haben. Diese Studien werden aber von Kartellmedien, Politik, Justiz usw. konsequent verschwiegen oder sogar aktiv unterdrückt. Jetzt kommt das Volk zu Wort!"'
				/>
				<link rel='canonical' href='https://www.vetopedia.org/' />
				<meta property='og:locale' content='de_DE' />
				<meta property='og:type' content='website' />
				<meta
					property='og:title'
					content='Vetopedia - Die freie Enzyklopädie der Gegenstimmen'
				/>
				<meta property='og:description' content='' />
				<meta property='og:url' content='https://www.vetopedia.org/' />
				<meta property='og:site_name' content='Vetopedia' />
				<link rel='manifest' href='/manifest.json' />
			</Head>
			<GlobalStyles />
			<Provider store={store}>
				<RouteGuard>
					<ErrorBoundary ErrorFallback={ErrorFallback}>
						<ThemeProvider theme={theme}>
							<GridThemeProvider gridTheme={bootstrapLayoutConfig}>
								<Layout theme={theme}>
									<Component
										{...{
											...props.pageProps,
											useBreadcrumb,
											customBreadcrumb,
											customNav,
										}}
									/>
								</Layout>
							</GridThemeProvider>
						</ThemeProvider>
					</ErrorBoundary>
				</RouteGuard>
			</Provider>
			<Toaster />
			<Matomo />
		</>
	);
}

MyApp.propTypes = {
	pageProps: PropTypes.object,
	Component: PropTypes.any,
};

export default MyApp;
