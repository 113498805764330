import { Button } from 'components/ui/Button.tsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const ErrorPage = (props) => {
	const [t] = useTranslation();
	return (
		<div className='m-5'>
			<h2>{t('common.fehler_vetopedia_text')}</h2>
			<Button type='button' onClick={() => this.setState({ hasError: false })}>
				{t('common.neu_laden')}
			</Button>
			<br />
			<h6 className='mt-3'>{t('common.brower_daten_loeschen_text')}</h6>
			{props.success && (
				<h6 className='mt-3' style={{ color: 'green' }}>
					Der Fehler wurde an die Entwickler gesendet und möglichst bald
					behoben.
				</h6>
			)}
		</div>
	);
};

ErrorPage.propTypes = {
	success: PropTypes.bool,
};

export { ErrorPage };
