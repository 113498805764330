import { FormHelperText } from 'components/ui';
import PropTypes from 'prop-types';
import { FormSelect, FormLabel } from 'react-bootstrap';
import styled from 'styled-components';

const Form = styled(FormSelect)`
	height: 40px;
`;

const DropdownSelect = (props) => {
	const ariaLabel = props.label || '';
	return (
		<div>
			{props.label && <FormLabel>{props.label}</FormLabel>}
			<Form
				disabled={props.disabled}
				className={props.className}
				style={props.style}
				aria-label={ariaLabel}
				onChange={props.onChange}
				value={props.value || ''}
				isInvalid={props.isInvalid}
			>
				{props.options &&
					props.options.map((option) => (
						<option key={option.value} value={option.value}>
							{option.text}
						</option>
					))}
			</Form>
			{props.isInvalid && (
				<FormHelperText style={{ color: '#dc3545' }}>
					{props.isInvalid}
				</FormHelperText>
			)}
		</div>
	);
};

DropdownSelect.propTypes = {
	disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	label: PropTypes.string,
	className: PropTypes.string,
	onChange: PropTypes.func,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	isInvalid: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	options: PropTypes.arrayOf(PropTypes.object),
};

export { DropdownSelect };
