import { useEntriesLazyQuery } from 'api/internal.api.ts';
import useLocalStoredState from 'hooks/useLocalStoredState.js';
import useUpdateCheck from 'hooks/useUpdateCheck.js';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { settingsActions } from 'store/settingsSlice.ts';

const useFilterState = (initialValue, storageName) => {
	const [fetchEntries] = useEntriesLazyQuery();
	const dispatch = useDispatch();
	const moderatorFilter = useSelector(
		(state) => state.settings.moderatorFilter
	);
	const { state, setState, storeState } = useLocalStoredState(
		initialValue,
		'adminView_' + storageName
	);

	useEffect(() => {
		const { hasUpdate } = useUpdateCheck();
		if (hasUpdate()) {
			return;
		}
		const storedValue = JSON.parse(
			localStorage.getItem('adminView_' + storageName)
		)?.item;
		setFilter(storedValue, true);
	}, []);

	useEffect(() => {
		if (moderatorFilter[storageName] != state) {
			storeState(moderatorFilter[storageName]);
		}
	}, [moderatorFilter]);

	const setModFilter = (newValue) => {
		let change = {};
		change[storageName] = newValue;
		const updatedFilter = { ...moderatorFilter, ...change };
		return [
			updatedFilter,
			dispatch(settingsActions.setModeratorFilter(change)),
		];
	};

	const setFilter = (newValue, noFetch) => {
		storeState(newValue);
		const [updatedFilter, response] = setModFilter(newValue);
		if (!noFetch) {
			fetchEntries(updatedFilter);
		}
		return response;
	};
	return { state, setState, storeState, setFilter };
};

export default useFilterState;
