export type OriginIdProps = {
	id: string;
	label: string;
};

const OriginId = ({ id, label }) => {
	return (
		<div style={{ fontSize: '14px' }}>
			{label} {id}
		</div>
	);
};
export { OriginId };
