import { FilterHeadline } from 'components/impfschaeden/uebersicht/components/FilterHeadline.js';
import { FilterOption } from 'components/impfschaeden/uebersicht/components/FilterOption.js';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { initialFilter } from 'components/impfschaeden/uebersicht/VaxOverview.js';
import useAvailableFilters from 'components/impfschaeden/uebersicht/hooks/useAvailableFilters.js';
import isEqual from 'lodash/isEqual';

const FilterSelection = ({
	onBack,
	section,
	setSection,
	filter,
	resetFilter,
}) => {
	const [t] = useTranslation();
	const { availableFilters } = useAvailableFilters();
	return (
		<>
			<FilterHeadline
				title={t('common.filter_auswaehlen')}
				onReset={() => {
					resetFilter();
				}}
				onBack={onBack}
				resetEnabled={!isEqual(filter, initialFilter)}
				section={section}
			/>
			{availableFilters.map((filter, index) => {
				return (
					<FilterOption
						selected={false}
						isDefault={false}
						onClick={() => setSection(filter.key)}
						key={'filter-' + index}
						option={t('common.' + filter.name)}
						display={filter.display}
					/>
				);
			})}
		</>
	);
};

FilterSelection.propTypes = {
	onBack: PropTypes.func,
	section: PropTypes.string,
	setSection: PropTypes.func,
	filter: PropTypes.object,
	updateFilter: PropTypes.func,
};

export { FilterSelection };
