import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import {
	Button,
	Container,
	Row,
	Col,
	TagInputNoNew,
	FormHelperText,
	CheckBox,
} from 'components/ui';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import {
	Close,
	Description,
	Download,
	GridView,
	MenuBook,
} from '@mui/icons-material';
import { LexiconGridView } from 'components/schlagworte/components/LexiconGridView.js';
import { API_URL } from 'env.js';
import uniqBy from 'lodash/uniqBy';
import { DownloadLexiconMenu } from 'components/schlagworte/components/DownloadLexiconMenu.js';

const LexiconButton = (props) => {
	const [show, setShow] = useState(false);
	const [t] = useTranslation();

	const [showText, setShowText] = useState(false);
	const [showInput, setShowInput] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	return (
		<div>
			<Button className='p-2' onClick={handleShow}>
				<MenuBook />
			</Button>
			<Modal show={show} onHide={handleClose} size={showText ? 'xl' : 'lg'}>
				<Modal.Header closeButton>
					<Modal.Title className='w-100'>
						<div className='d-flex justify-content-between w-100 align-items-center'>
							{t('common.schlagwort_lexikon')}
							{!props.filter && (
								<div className='me-4'>
									<DownloadLexiconMenu />
								</div>
							)}
							{props.filter && (
								<div style={{ fontSize: '16px' }}>
									<CheckBox
										label={t('common.untergeordnete_schlagworte_selektieren')}
										onChange={props.setSelectChilds}
										checked={props.selectChilds}
									/>
								</div>
							)}
						</div>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container>
						<Row>
							{showText && (
								<Col
									xs={12}
									lg={3}
									className='border-end'
									style={{ zIndex: 2000, position: 'relative' }}
								>
									<div>
										<Button
											className='p-2 float-end border border-white'
											onClick={() => setShowText(!showText)}
										>
											<Description />
										</Button>
										{props.formData?.description}
									</div>
								</Col>
							)}
							<Col className='p-0'>
								<div className='position-relative'>
									{!showText && props.formData?.description && (
										<div
											className='position-absolute top-0'
											style={{
												left: '-37px',
											}}
										>
											<Button
												className='p-2 border border-white'
												onClick={() => setShowText(!showText)}
											>
												<Description />
											</Button>
										</div>
									)}

									<LexiconGridView
										onSelect={props.onSelect}
										selected={props.selected}
									/>
									{!showInput &&
										props.formData &&
										props.formData[props.countProperty]?.length > 0 && (
											<div className='sticky-bottom'>
												<Button
													className='p-2 border border-white '
													style={{
														fontWeight: 'bolder',
														minWidth: '42px',
														marginLeft: 'calc(100% - 42px)',
													}}
													onClick={() => setShowInput(!showInput)}
												>
													{props.formData[props.countProperty]?.length || 0}
												</Button>
											</div>
										)}
									{!showInput &&
										false &&
										props.formData &&
										props.formData[props.countProperty]?.length > 0 && (
											<Button
												className='p-2 border border-white position-absolute'
												style={{
													fontWeight: 'bolder',
													minWidth: '42px',
													marginLeft: 'calc(100% - 42px)',
													marginBottom: '-12px',
												}}
												onClick={() => setShowInput(!showInput)}
											>
												{props.formData[props.countProperty]?.length || 0}
											</Button>
										)}
									{!showInput &&
										false &&
										props.formData &&
										props.formData[props.countProperty]?.length > 0 && (
											// <div>
											<div
												className=' end-0 sticky-bottom'
												style={{
													bottom: '0',
													zIndex: 2000,
													height: '1px',
													marginTop: '-40px',
													paddingBottom: '40px',
												}}
											>
												{/* <div className='position-absolute'> */}
												<Button
													className='p-2 border border-white'
													style={{
														fontWeight: 'bolder',
														minWidth: '42px',
														marginLeft: 'calc(100% - 42px)',
														marginBottom: '-12px',
													}}
													onClick={() => setShowInput(!showInput)}
												>
													{props.formData[props.countProperty]?.length}
												</Button>
												{/* </div> */}
											</div>
											// </div>
										)}
								</div>
							</Col>
						</Row>
						{showInput && (
							<Row className='sticky-bottom bg-white'>
								{showText && <Col xs={12} lg={3}></Col>}
								<Col className='border-top'>
									<div
										className='position-absolute end-0'
										style={{
											top: '-12px',
											zIndex: 2000,
										}}
									>
										<Button
											className='p-2 border border-white'
											style={{ fontWeight: 'bolder', minWidth: '42px' }}
											onClick={() => setShowInput(!showInput)}
										>
											<Close />
										</Button>
									</div>
									<div className='flex-grow-1 mt-3'>
										<TagInputNoNew
											disabled={
												props.formData?.healthy_before_vax === 'no_unknown'
											}
											options={props.tag_options}
											value={
												props.formData
													? props.formData[props.countProperty]
														? props.formData[props.countProperty]
														: []
													: []
											}
											onInputChange={(event, value) => {
												props.setSearch(value);
											}}
											onChange={(e, newValues) => {
												newValues = newValues.map((item) => {
													let newItem;
													if (typeof item == 'string') {
														newItem = { name: item };
													} else {
														newItem = { ...item };
													}
													newItem.name = newItem.name.replace(
														t(
															'impfschaeden.erfassen.hinzufuegen_text',
															'Hinzufügen: '
														),
														''
													);
													return newItem;
												});
												let updateObj = {};
												updateObj[props.countProperty] = uniqBy(
													newValues,
													'name'
												);
												props.update(updateObj);
											}}
											label={props.label}
											isInvalid={props.validation[props.countProperty]}
										/>
										<FormHelperText>
											{t('impfschaeden.erfassen.hinweis_schlagworte')}
										</FormHelperText>
									</div>
								</Col>
							</Row>
						)}
					</Container>
				</Modal.Body>
			</Modal>
		</div>
	);
};

LexiconButton.propTypes = {
	tag_options: PropTypes.array,
	setSearch: PropTypes.func,
	onSelect: PropTypes.func,
	update: PropTypes.func,
	selected: PropTypes.array,
	formData: PropTypes.object,
	countProperty: PropTypes.string,
	label: PropTypes.string,
	validation: PropTypes.array,
	filter: PropTypes.bool,
	setSelectChilds: PropTypes.func,
	selectChilds: PropTypes.bool,
};

export { LexiconButton };
