import PropTypes from 'prop-types';
import {
	PublishedChip,
	DeletedChip,
	NotValidatedChip,
} from 'components/nutzerbereich/UserArea/components/Chips';
import { useTranslation } from 'react-i18next';

const StatusBadge = (props) => {
	const [t] = useTranslation();
	switch (props.status) {
		case 1:
			return <NotValidatedChip label={t('nutzerbereich.in_pruefung')} />;
		case 2:
			return <PublishedChip label={t('nutzerbereich.veroeffentlicht')} />;
		case 3:
			return <DeletedChip label={t('nutzerbereich.geloescht')} />;
	}
};

StatusBadge.propTypes = {
	status: PropTypes.number,
};

export { StatusBadge };
