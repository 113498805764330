import useValidation from 'components/impfschaeden/assistent/hooks/useValidation.js';
import { useDispatch } from 'react-redux';
import { formActions } from 'store/vaxFormDataSlice';

const useFormUpdate = (step) => {
	const dispatch = useDispatch();
	const {
		removeUserValidation,
		removeValidation,
		removeVaccinationValidation,
	} = useValidation();

	const update = (payload) => {
		dispatch(formActions.update(payload));
		if (step) {
			removeValidation({ changedData: payload, step: step });
		}
	};
	const updateUser = (payload) => {
		dispatch(formActions.updateUser(payload));
		removeUserValidation(payload);
	};
	const updateVaccination = (payload) => {
		dispatch(formActions.updateVaccination(payload.vaccinations));
		removeVaccinationValidation(payload);
	};
	const updateFile = (payload) => {
		dispatch(formActions.updateFile(payload));
	};
	return { update, updateUser, updateVaccination, updateFile };
};

export default useFormUpdate;
