import styled from 'styled-components';
import { ActiveLink } from 'components/ui';
import { useTranslation } from 'react-i18next';

const Footer = () => {
	const [t] = useTranslation();
	return (
		<div
			className='
				container d-flex flex-wrap flex-md-nowrap
				flex-column flex-md-row align-items-center justify-content-end'
		>
			<FooterElement>&copy; 2023 Vetopedia</FooterElement>
			<ActiveLink href='/datenschutz'>
				<FooterElement>{t('common.datenschutz')}</FooterElement>
			</ActiveLink>
			<ActiveLink href='https://v1.vetopedia.org/index.php?title=Impressum'>
				<FooterElement>{t('common.impressum')}</FooterElement>
			</ActiveLink>
			<ActiveLink href='/ueber-vetopedia'>
				<FooterElement>{t('common.ueber_vetopedia')}</FooterElement>
			</ActiveLink>
		</div>
	);
};

export default Footer;

const FooterElement = styled.div`
	padding: 5px;
	margin: 5px 10px;
	letter-spacing: 0.01em;
	&:hover {
		cursor: pointer;
		color: black;
	}
`;
