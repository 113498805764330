import { TileSelectFilter } from 'components/impfschaeden/uebersicht/components/TileSelectFilter.js';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const DamageOccurredFilter = ({
	filter,
	updateFilter,
	onBack,
	handleClose,
}) => {
	const [t] = useTranslation();
	const [options, setOptions] = useState([]);
	const globDamageOccurred = useSelector(
		(state) => state.masterData.globDamageOccurred
	);
	useEffect(() => {
		if (globDamageOccurred?.length > 0) {
			setOptions(
				globDamageOccurred.map((damageOccurred) => {
					return {
						name: t(
							'master_data.damage_occurred.' + damageOccurred.occurred_i18n_key
						),
						value: damageOccurred.glob_damage_occurred_id,
					};
				})
			);
		}
	}, [globDamageOccurred]);
	return (
		<TileSelectFilter
			options={options}
			updateFilter={updateFilter}
			filter={filter}
			onBack={onBack}
			filterKey='damage_occurred'
			title={t('common.zeitspanne_impfungen_symptome')}
			handleClose={handleClose}
			noTranslation={true}
		/>
	);
};

DamageOccurredFilter.propTypes = {
	filter: PropTypes.object,
	updateFilter: PropTypes.func,
	onBack: PropTypes.func,
	handleClose: PropTypes.func,
};

export { DamageOccurredFilter };
