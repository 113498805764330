const useUpdateCheck = () => {
	const hasUpdate = () => {
		const currentVersion = process.env.VERSION;
		const lastVersion = localStorage.getItem('version');
		return lastVersion !== currentVersion;
	};
	const checkUpdateLocalStorage = () => {
		const currentVersion = process.env.VERSION;
		const lastVersion = localStorage.getItem('version');
		if (lastVersion !== currentVersion) {
			localStorage.clear();
		}
		localStorage.setItem('version', currentVersion);
	};
	return {
		hasUpdate,
		checkUpdateLocalStorage,
	};
};

export default useUpdateCheck;
