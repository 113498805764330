import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { VaxEntry } from 'store/vaxFormDataSlice.ts';
import dayjs from 'dayjs';

const useOriginallyPostedDate = (props: VaxEntry) => {
	const [t] = useTranslation();
	let date = props.originally_posted_at
		? t('common.datum_doppelpunkt') +
		  ' ' +
		  dayjs(props.originally_posted_at).format('L')
		: '';

	if (props.glob_referrer_id != '1') {
		date = '';
	}

	return { date };
};

export default useOriginallyPostedDate;
