import { FilterHeadline } from 'components/impfschaeden/uebersicht/components/FilterHeadline.js';
import { FilterOption } from 'components/impfschaeden/uebersicht/components/FilterOption.js';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const SingleSelectFilter = ({
	filter,
	updateFilter,
	onBack,
	options,
	defaultOption,
	filterKey,
	title,
}) => {
	const [t] = useTranslation();
	const onReset = () => {
		let update = {};
		update[filterKey] = defaultOption;
		updateFilter(update);
	};
	const onSelect = (option) => {
		let update = {};
		if (option == filter[filterKey]) {
			update[filterKey] = null;
		} else {
			update[filterKey] = option;
		}
		updateFilter(update);
	};
	if (!filterKey) {
		return null;
	}
	return (
		<>
			<FilterHeadline
				title={title}
				onReset={onReset}
				onBack={onBack}
				resetEnabled={filter[filterKey] != null}
			/>
			{options.map((option, index) => {
				const selected = filter[filterKey] == option;
				const isDefault = option == defaultOption && filter[filterKey] == null;

				return (
					<FilterOption
						selected={selected}
						isDefault={isDefault}
						onClick={() => {
							onSelect(option);
						}}
						key={'option' + index}
						option={t('common.' + option)}
					/>
				);
			})}
		</>
	);
};

SingleSelectFilter.propTypes = {
	filter: PropTypes.object,
	updateFilter: PropTypes.func,
	onBack: PropTypes.func,
	options: PropTypes.arrayOf(PropTypes.object),
	defaultOption: PropTypes.object,
	filterKey: PropTypes.string,
	title: PropTypes.string,
};

export { SingleSelectFilter };
