import { createSlice } from '@reduxjs/toolkit';

const loginSlice = createSlice({
	name: 'vaxForm',
	initialState: {
		globAgeGroups: [],
		globDamageOccurred: [],
		globHealthResults: [],
		globReferrers: [],
		globHealthDamages: [],
		vaxRelationships: [],
		vaxManufacturers: [],
		vaxTypes: [],
		countries: [],
	},
	reducers: {
		getCurrentUserData() {},
		login(state, action) {
			state.user = {
				...state.user,
				...action.payload,
			};
		},
	},
});

export default loginSlice.reducer;

// export const userLogin = (user_data) => async (dispatch, getState) => {
// 	try {
// 		const res = await axios.post(
// 			getState().login.axios_config.api_url + `v1/users/login`,
// 			user_data
// 		);
// 		const payload = res.data;
// 		return payload;
// 	} catch (e) {
// 		return { success: false };
// 	}
// };

// export const getCurrentUserData = () => async (dispatch) => {
// 	// todo with cookie jwt load
// 	dispatch({
// 		type: actionType.user_payload,
// 		payload: {
// 			iat: 1234,
// 			username: 'maxmuster123',
// 		},
// 	});
// };

// export const registerUser = (user_data) => async (dispatch, getState) => {
// 	const res = await axios.post(
// 		getState().login.axios_config.api_url + `v1/users/register`,
// 		user_data
// 	);
// 	return res;
// };

// export const validateUser = (user_data) => async (dispatch, getState) => {
// 	console.log(user_data);
// 	try {
// 		const res = await axios.post(
// 			getState().login.axios_config.api_url + `v1/users/validation`,
// 			user_data
// 		);
// 		const payload = res.data;
// 		return payload;
// 	} catch (e) {
// 		console.log(e);
// 		return { success: false };
// 	}
// };

// export const pwResetTokenValidation = (token) => async (dispatch, getState) => {
// 	return axios.post(
// 		getState().login.axios_config.api_url + `v1/users/pwreset/validate`,
// 		{ token: token }
// 	);
// };

// import {getBearer} from '../../service/user.service';

// const initialState = {
// 	users: [],
// 	axios_config: {
// 		api_url: process.env.API_URL,
// 		api_config: {
// 			headers: {
// 				Authorization: 'Bearer ' + getBearer(),
// 				'Content-Type': 'application/json',
// 			},
// 		},
// 	},
// };
