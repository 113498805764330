import { useState } from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import SocialIcons from './components/SocialIcons';
import {
	BarsIconToggler,
	PersonCircle,
	Menu,
	MenuItem,
	Divider,
} from 'components/ui/';
import { PropTypes } from 'prop-types';
import Switcher from './components/Switcher';
import { useSelector } from 'react-redux';
import useLogout from 'hooks/useLogout';
import { useTranslation } from 'react-i18next';
import useLogin from 'components/nutzerbereich/Login/hooks/useLogin.js';
import { useRouter } from 'next/router.js';

const Container = styled.div`
	border-color: ${(props) => props.theme.themePrimary} !important;
	transform: rotate(0rad);
	z-index: 1200;
`;

const MobileArea = styled.div`
	${media.xxl`
		display: none;
	`}
`;

const MobileLogoArea = styled.div`
	height: 40px;
`;

const ProfilBild = styled.img`
	max-width: 38px;
`;

const Header = ({ menuOpen }) => {
	const [expanded, setExpanded] = useState(false);
	const user = useSelector((state) => state.settings.currentUser);
	const [anchorEl, setAnchorEl] = useState(null);
	const logout = useLogout();
	const [t] = useTranslation();
	const login = useLogin();
	const router = useRouter();

	const handleClick = (event) => {
		if (!user) {
			router.push('/nutzerbereich/anmelden');
		} else {
			setAnchorEl(event.currentTarget);
		}
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	return (
		<div className='bg-white'>
			<Container className='py-1 border-bottom border-4 d-flex flex-row'>
				<MobileArea>
					<div className='d-flex'>
						<span
							onClick={() => {
								setExpanded(!expanded);
								menuOpen(!expanded);
							}}
						>
							<BarsIconToggler expanded={expanded} />
						</span>
						<MobileLogoArea>
							<img src='/img/logo_veto_mobile.png' className='h-100' />
						</MobileLogoArea>
					</div>
				</MobileArea>
				<div className='d-none d-xxl-block ps-1'>
					<SocialIcons />
				</div>
				<div
					className='d-flex flex-row d-print-none'
					style={{ marginLeft: 'auto' }}
				>
					<div className='me-1'>
						<Switcher />
					</div>
					<div className='ms-auto pe-2 cursor-pointer' onClick={handleClick}>
						{user && user.avatar_glob_file_id && (
							<ProfilBild
								src={`${process.env.API_URL}v1/glob/files/${user.avatar_glob_file_id}/avatar.png`}
								alt='avatar'
								style={{ borderRadius: '50%' }}
							/>
						)}
						{!(user && user.avatar_glob_file_id) && <PersonCircle size={38} />}
					</div>
					<Menu
						id='basic-menu'
						anchorEl={anchorEl}
						open={open}
						onClose={handleClose}
						MenuListProps={{
							'aria-labelledby': 'basic-button',
						}}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
					>
						{user && (
							<div>
								<MenuItem
									onClick={() => {
										handleClose();
										router.push('/nutzerbereich');
									}}
								>
									{user &&
										!user.is_moderator &&
										!user.is_admin &&
										t('navigation.meine_eintraege')}
									{user && user.is_moderator && t('common.eintraege')}
								</MenuItem>
								<MenuItem
									onClick={() => {
										handleClose();
										router.push('/nutzerbereich/profil');
									}}
								>
									{t('navigation.mein_profile')}
								</MenuItem>
								<Divider />
								<a href='/' className='text-dark text-decoration-none'>
									<MenuItem
										onClick={() => {
											handleClose();
											logout();
										}}
									>
										{t('navigation.abmelden')}
									</MenuItem>
								</a>
							</div>
						)}
						{!user && (
							<MenuItem
								onClick={() => {
									handleClose();
									login();
								}}
							>
								{t('navigation.login')}
							</MenuItem>
						)}
					</Menu>
				</div>
			</Container>
		</div>
	);
};

Header.propTypes = {
	menuOpen: PropTypes.func,
};

export default Header;
