import { ProgressBar as Progress } from 'react-bootstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const UploadBarSmall = styled(Progress)`
	position: absolute;
	border-radius: 0;
	background: rgba(40, 180, 40, 0.5);
	bottom: 0px;
	width: 100%;
	left: 0;
	opacity: 0.8;
`;

const UploadBar = (props) => {
	return (
		<UploadBarSmall
			now={props.progress}
			striped
			variant={props.error ? 'danger' : 'success'}
			label={props.error ? '' : `${props.progress}%`}
		/>
	);
};

UploadBar.propTypes = {
	progress: PropTypes.number,
};

export { UploadBar };
