import PropTypes from 'prop-types';
import { FormCheck } from 'react-bootstrap';
import { FormHelperText } from 'components/ui';

const CheckBox = (props) => {
	return (
		<div className={props.className}>
			<FormCheck
				type='checkbox'
				label={props.label}
				onChange={props.onChange}
				isInvalid={props.isInvalid}
				disabled={props.disabled}
				value={props.value}
				inline
				checked={props.checked}
			/>
			{props.isInvalid && (
				<FormHelperText style={{ color: '#dc3545' }}>
					{props.isInvalid}
				</FormHelperText>
			)}
		</div>
	);
};

CheckBox.propTypes = {
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	isInvalid: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	value: PropTypes.bool,
	checked: PropTypes.bool,
	className: PropTypes.string,
};

export { CheckBox };
