import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { masterDataActions } from 'store/masterDataSlice';
import {
	useReferrersLazyQuery,
	useAgeGroupsLazyQuery,
	useDamageOccurrencesLazyQuery,
	useHealthDamagesLazyQuery,
	useHealthResultsQuery,
} from 'api/glob.api';
import {
	useTypesLazyQuery,
	useManufacturersLazyQuery,
	useRelationshipsLazyQuery,
} from 'api/vax.api';
import { useSalutationsLazyQuery } from 'api/user.api';
import { useTitlesLazyQuery } from 'api/user.api';
import { useCountriesLazyQuery, useLanguagesLazyQuery } from 'api/kosmos.api';
import { useTranslation } from 'react-i18next';
import orderBy from 'lodash/orderBy';

const useVaxFormMasterData = () => {
	const [fetchReferrers] = useReferrersLazyQuery();
	const [fetchAgeGroups] = useAgeGroupsLazyQuery();
	const [fetchDamageOccurred] = useDamageOccurrencesLazyQuery();
	const [fetchHealthDamages] = useHealthDamagesLazyQuery();
	const [fetchHealthResults] = useHealthResultsQuery();
	const [fetchManufacturers] = useManufacturersLazyQuery();
	const [fetchRelationships] = useRelationshipsLazyQuery();
	const [fetchTypes] = useTypesLazyQuery();
	const [fetchCountries] = useCountriesLazyQuery();
	const [fetchLanguages] = useLanguagesLazyQuery();
	const [fetchSalutations] = useSalutationsLazyQuery();
	const [fetchTitles] = useTitlesLazyQuery();
	const dispatch = useDispatch();
	const language = useSelector((state) => state.settings.language);
	const [t] = useTranslation();

	const getAgeGroups = async () => {
		const ageGroups = await fetchAgeGroups().unwrap();
		dispatch(masterDataActions.update({ globAgeGroups: ageGroups }));
	};
	const getReferrers = async () => {
		const referrers = await fetchReferrers().unwrap();
		dispatch(masterDataActions.update({ globReferrers: referrers }));
	};
	const getDamageOccurred = async () => {
		const damagesOccurred = await fetchDamageOccurred().unwrap();
		dispatch(masterDataActions.update({ globDamageOccurred: damagesOccurred }));
	};
	const getHealthResults = async () => {
		const healthResults = await fetchHealthResults().unwrap();
		dispatch(masterDataActions.update({ globHealthResults: healthResults }));
	};
	const getHealthDamages = async () => {
		const healthDamages = await fetchHealthDamages().unwrap();
		dispatch(masterDataActions.update({ globHealthDamages: healthDamages }));
	};
	const getManufacturers = async () => {
		let manufacturers = await fetchManufacturers().unwrap();
		if (manufacturers.length > 0) {
			manufacturers = manufacturers.map((manufacturer) => {
				const manu = { ...manufacturer };
				if (manu.lang_iso_code == 'translation') {
					manu.manufacturer_i18n_key = t(
						'master_data.vax_manufacturer.' + manu.manufacturer_i18n_key
					);
				}
				return manu;
			});
		}

		dispatch(masterDataActions.update({ vaxManufacturers: manufacturers }));
	};
	const getRelationships = async () => {
		const relationships = await fetchRelationships().unwrap();
		dispatch(masterDataActions.update({ vaxRelationships: relationships }));
	};
	const getTypes = async () => {
		let types = await fetchTypes().unwrap();
		if (types.length > 0) {
			types = types.map((vax_type) => {
				const type = { ...vax_type };
				if (type.lang_iso_code == 'translation') {
					type.vax_type_i18n_key = t(
						'master_data.vax_type.' + type.vax_type_i18n_key
					);
				}
				return type;
			});
		}
		dispatch(
			masterDataActions.update({
				vaxTypes: orderBy(types, ['ordnr', 'vax_type_i18n_key']),
			})
		);
	};
	const getCountries = async () => {
		const countries = await fetchCountries(language).unwrap();
		dispatch(masterDataActions.update({ countries }));
	};
	const getLanguages = async () => {
		const languages = await fetchLanguages(language).unwrap();
		dispatch(masterDataActions.update({ languages }));
	};
	const getSalutations = async () => {
		const salutations = await fetchSalutations().unwrap();
		dispatch(masterDataActions.update({ salutations }));
	};
	const getTitles = async () => {
		const titles = await fetchTitles().unwrap();
		dispatch(masterDataActions.update({ titles }));
	};

	useEffect(() => {
		getAgeGroups();
		getReferrers();
		getDamageOccurred();
		getHealthResults();
		getHealthDamages();
		getRelationships();
		getManufacturers();
		getTypes();
		getCountries();
		getLanguages();
		getSalutations();
		getTitles();
	}, [language]);
	return {
		getAgeGroups,
		getReferrers,
		getDamageOccurred,
		getHealthResults,
		getHealthDamages,
		getRelationships,
		getManufacturers,
		getTypes,
		getCountries,
		getLanguages,
		getSalutations,
		getTitles,
	};
};

export default useVaxFormMasterData;
