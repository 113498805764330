import { FilterHeadline } from 'components/impfschaeden/uebersicht/components/FilterHeadline.js';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import cloneDeep from 'lodash/cloneDeep';
import indexOf from 'lodash/indexOf';
import without from 'lodash/without';
import styled from 'styled-components';
import { FilterOptionTile } from 'components/impfschaeden/uebersicht/components/FilterOptionTile.js';

const TileBox = styled.div`
	display: flex;
	flex-wrap: wrap;
	> div {
		width: 30%;
	}
`;

const TileSelectFilter = ({
	filter,
	updateFilter,
	onBack,
	options,
	defaultOption,
	filterKey,
	title,
	noTranslation,
}) => {
	const [t] = useTranslation();

	const onReset = () => {
		let update = {};
		update[filterKey] = [];
		updateFilter(update);
	};

	const onSelect = (option) => {
		let update = {};
		if (filter[filterKey]) {
			update[filterKey] = cloneDeep(filter[filterKey]);
		} else {
			update[filterKey] = [];
		}
		const index = indexOf(update[filterKey], option.value);
		if (index > -1) {
			update[filterKey] = without(update[filterKey], option.value);
		} else {
			update[filterKey].push(option.value);
		}
		updateFilter(update);
	};
	if (!filterKey) {
		return null;
	}

	return (
		<>
			<FilterHeadline
				title={title}
				onReset={onReset}
				onBack={onBack}
				resetEnabled={filter[filterKey]?.length > 0}
			/>
			<TileBox>
				{options.map((option, index) => {
					const selected =
						indexOf(filter[filterKey], option.value) > -1 ||
						indexOf(filter[filterKey], option.value.toString()) > -1;
					const isDefault =
						option == defaultOption && filter[filterKey] == null;

					return (
						<FilterOptionTile
							selected={selected}
							isDefault={isDefault}
							onClick={() => {
								onSelect(option);
							}}
							key={'option' + index}
							option={noTranslation ? option.name : t('common.' + option.name)}
						/>
					);
				})}
			</TileBox>
			{/* <SaveButton className='d-flex justify-content-center p-3 position-sticky bottom-0 border-top'>
				<Button disabled={filter[filterKey].length === 0} onClick={handleClose}>
					{t('common.speichern')}
				</Button>
			</SaveButton> */}
		</>
	);
};

TileSelectFilter.propTypes = {
	filter: PropTypes.object,
	updateFilter: PropTypes.func,
	handleClose: PropTypes.func,
	onBack: PropTypes.func,
	options: PropTypes.arrayOf(PropTypes.object),
	defaultOption: PropTypes.object,
	filterKey: PropTypes.string,
	title: PropTypes.string,
	placeholder: PropTypes.string,
	noTranslation: PropTypes.bool,
};

export { TileSelectFilter };
