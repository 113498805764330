export const regexMail = new RegExp('.+@.+[.].{2,}');
export const regexSkyfax = new RegExp(
	'^[a-z0-9_.-]{1,}@[a-z]{1,3}\\.[a-z0-9]{1}$'
);
export const containsUpperCaseLetter = new RegExp('[A-Z]+');
export const containsLowerCaseLetter = new RegExp('[a-z]+');
export const containsNumber = new RegExp('[0-9]+');
export const containsSpecialCharacter = new RegExp('[^A-Za-z0-9]+');
export const hashtagId = new RegExp('^#\\d+$');

export const telegram_group_id = new RegExp(
	't.me\\/(?<telegram_group>\\S*)\\/(?<id>\\d*)$',
	'g'
);
