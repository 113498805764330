import { SingleSelectFilter } from 'components/impfschaeden/uebersicht/components/SingleSelectFilter.js';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const SortFilter = ({ filter, updateFilter, onBack }) => {
	const [t] = useTranslation();
	const sortOptions = ['relevanz', 'neueste', 'aelteste'];
	const defaultOption = 'relevanz';
	return (
		<SingleSelectFilter
			options={sortOptions}
			defaultOption={defaultOption}
			updateFilter={updateFilter}
			filter={filter}
			onBack={onBack}
			filterKey='sort'
			title={t('common.sortieren_nach')}
		/>
	);
};

SortFilter.propTypes = {
	filter: PropTypes.object,
	updateFilter: PropTypes.func,
	onBack: PropTypes.func,
};

export { SortFilter };
