import { ThemeTitle } from 'components/ui/ThemeTitle.js';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import parse from 'html-react-parser';
import { highlight } from 'lib/utils.js';
import dayjs from 'dayjs';

const ThemeColor = styled.span`
	color: ${(props) => props.theme.themePrimary};
`;

const EntryDisplaySmall = ({ entry, search }) => {
	const types = useSelector((state) => state.masterData.vaxTypes);
	const user = useSelector((state) => state.settings.currentUser);
	const [t] = useTranslation();
	if (!entry?.vax_entry_vaccinations?.length > 0) {
		return null;
	}
	let firstVac = entry.vax_entry_vaccinations[0];
	let vaxTypeName = types?.find((type) => {
		if (!entry?.vax_entry_vaccinations?.length > 0) {
			return false;
		}
		return firstVac.vax_type_id == type.vax_type_id;
	})?.vax_type_i18n_key;
	if (!vaxTypeName) {
		vaxTypeName = firstVac?.vax_type?.vax_type_i18n_key;
	}
	if (search) {
		vaxTypeName = highlight(search, vaxTypeName);
	}
	if (!entry || !entry.user) {
		return null;
	}
	return (
		<div className='d-flex flex-column'>
			<div>
				<ThemeTitle h={5} className='mb-0'>
					{vaxTypeName && parse(vaxTypeName)}
				</ThemeTitle>
			</div>
			<div>
				<span style={{ color: 'var(--deletedGrey)' }}>
					<strong>
						{t('common.impfschaden')} {'#' + entry.vax_entry_id}
					</strong>{' '}
					{t('common.von').toLowerCase()}
				</span>{' '}
				{(entry.last_name || entry.first_name) && (
					<ThemeColor>
						{entry.first_name && parse(highlight(search, entry.first_name))}{' '}
						{entry.last_name && entry.last_name.substr(0, 4)}.
					</ThemeColor>
				)}
			</div>
			<div>
				<span style={{ color: 'var(--deletedGrey)' }}>
					{t('nutzerbereich.eintraege.eingetragen_von').toLowerCase()}
				</span>{' '}
				<ThemeColor>{parse(highlight(search, entry?.user?.email))}</ThemeColor>
			</div>
			{user && user.is_moderator == 1 && entry?.published_by && (
				<div>
					<span style={{ color: 'var(--deletedGrey)' }}>
						{t('nutzerbereich.eintraege.veroeffentlicht_von').toLowerCase()}
					</span>{' '}
					<ThemeColor>
						{entry?.published_by &&
							parse(highlight(search, entry?.published_by))}
						{!entry?.published_by &&
							entry?.glob_entry_status_id == 2 &&
							t('common.unbekannt')}
					</ThemeColor>
				</div>
			)}
			{user && user.is_moderator == 1 && entry?.deleted_by && (
				<div>
					<span style={{ color: 'var(--deletedGrey)' }}>
						{t('common.geloescht_von').toLowerCase()}
					</span>{' '}
					<ThemeColor>
						{entry?.deleted_by && parse(highlight(search, entry?.deleted_by))}
						{!entry?.deleted_by &&
							entry?.glob_entry_status_id == 3 &&
							t('common.unbekannt')}
					</ThemeColor>
				</div>
			)}
			<div className='d-block d-xl-none'>
				<span style={{ color: 'var(--deletedGrey)' }}>
					{t('nutzerbereich.zuletzt_geaendert')}
				</span>{' '}
				{dayjs(entry.updated_at).format('lll')}
			</div>
		</div>
	);
};

EntryDisplaySmall.propTypes = {
	entry: PropTypes.object,
	search: PropTypes.string,
};

export { EntryDisplaySmall };
