import { useTranslation } from 'next-i18next';
import { PhotoVideoIcon } from 'components/ui';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Zone = styled.div`
	min-height: 192px;
	border: 2px dashed lightgrey;
	border-radius: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const ZoneText = styled.div`
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	min-height: 192px;
	> p {
		margin: 0;
	}
	> i {
		font-style: normal;
	}
	// Heading with user instructions
	> p.h {
		/* line-height: 48px; */
		font-family: Helvetica, Arial, Verdana, sans-serif;
		font-size: 22px;
		font-weight: bold;
	}
	// Adjustable space between adjacent <p> elements
	> p.space {
		line-height: 12px;
	}
	// Supported file formats
	> p.ff {
		font-size: 14px;
		font-style: normal;
		color: gray;
	}
`;

const ZoneIcon = styled.div`
	font-size: 48px;
`;

// Legacy example to display text files with either randomized or fixed upload percentage values
// const testfiles = [
// 	{ name: 'testfile1.png', progress: Math.round(Math.random() * 100) },
// 	{ name: 'testfile2.png', progress: 100 },
// ];

const Dropzone = (props) => {
	const [t] = useTranslation(['translation']);
	const { getRootProps, getInputProps } = useDropzone({
		onDrop: props.onDrop,
	});
	return (
		<div>
			<Zone
				{...getRootProps()}
				style={{ minHeight: props.hasFiles ? '192px' : '392px' }}
			>
				<input {...getInputProps()} />
				<ZoneText>
					<ZoneIcon>
						<PhotoVideoIcon />
					</ZoneIcon>
					<p className='h'>{t('components.dropzone.anweisung')}</p>
					<p className='space'>&nbsp;</p>
					<p className='ff'>
						<i>{t('components.dropzone.dateiformate')} .jpg, .png, .mp4, .mov, .pdf</i>
					</p>
				</ZoneText>
			</Zone>
		</div>
	);
};

Dropzone.propTypes = {
	onDrop: PropTypes.func,
};

export { Dropzone };
