import PropTypes from 'prop-types';

const VaxDashboard = () => {
	return null;
};

VaxDashboard.propTypes = {
	test: PropTypes.string,
};

export { VaxDashboard };
