import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/store.ts';
import { masterDataActions } from 'store/masterDataSlice';
import { cloneDeep, orderBy } from 'lodash';
import { useVaxPreIllnessesLazyQuery } from 'api/glob.api.js';

const useVaxPreIllnessCounts = () => {
	const dispatch = useDispatch();
	const language = useSelector((state: AppState) => state.settings.language);
	const [fetchVaxPreIllnesses] = useVaxPreIllnessesLazyQuery();

	const getVaxPreIllnessCounts = async () => {
		const vaxPreIllnessCounts = await fetchVaxPreIllnesses(null).unwrap();
		dispatch(
			masterDataActions.update({
				vaxPreIllnessCounts: orderBy(
					cloneDeep(vaxPreIllnessCounts.data),
					['vax_pre_illnesses_count'],
					['desc']
				),
			})
		);
	};

	useEffect(() => {
		getVaxPreIllnessCounts();
	}, [language]);

	return [getVaxPreIllnessCounts];
};

export default useVaxPreIllnessCounts;
