import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faFacebook,
	faTelegram,
	faTwitterSquare,
} from '@fortawesome/free-brands-svg-icons';
import {
	faInfoCircle,
	faBars,
	faUser,
	faSyringe,
	faNewspaper,
	faHandPointRight,
	faHome,
	faAngleRight,
	faAngleDown,
	faCloudUploadAlt,
	faTrash,
	faTimes,
	faPhotoVideo,
	faPencilAlt,
	faSignInAlt,
	faTv,
	faComment,
	faCross,
	faStar,
	faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { ButtonNoneStyle } from 'components/ui/Button';
import PropTypes from 'prop-types';
export {
	PersonCircle,
	PhoneVibrateFill,
	PersonLinesFill,
	PencilSquare,
	ChatDotsFill,
	ArrowRight,
	CapsulePill,
} from 'react-bootstrap-icons';

const StyledIcon = styled.span`
	display: flex;
	font-size: 1.7rem;
`;

const RoundedUserIcon = styled.div`
	padding: 4px 3px 0 3px;
	color: ${(props) => props.theme.fontMenuMain};
	border-color: ${(props) => props.theme.fontMenuMain} !important;
	border: solid;
`;

const RoundedUserIconSmall = styled.div`
	padding: 4px 3px 0 3px;
	color: ${(props) => props.theme.fontMenuMain};
	border-color: ${(props) => props.theme.fontMenuMain} !important;
	border: thin solid;
	width: 5.8mm;
	height: 5.8mm;
`;

const MenuBarsToggler = styled(ButtonNoneStyle)`
	> div {
		width: 24px;
		height: 24px;
		position: relative;
		background-color: transparent;
		border: 1px solid transparent;

		span {
			display: block;
			position: absolute;
			height: 2px;
			width: 100%;
			background: ${(props) => props.theme.fontMenuMain};
			opacity: 1;
			left: 0;
			-webkit-transform: rotate(0deg);
			-moz-transform: rotate(0deg);
			-o-transform: rotate(0deg);
			transform: rotate(0deg);
			transition: 0.25s ease-in-out;

			&:nth-of-type(1) {
				top: 0px;
			}

			&:nth-of-type(2),
			&:nth-of-type(3) {
				top: 8px;
			}

			&:nth-of-type(4) {
				top: 16px;
			}
		}
	}

	&[aria-expanded='true'] {
		> div {
			span {
				&:nth-of-type(1) {
					top: 8px;
					width: 0%;
					left: 50%;
				}

				&:nth-of-type(2) {
					-webkit-transform: rotate(45deg);
					-moz-transform: rotate(45deg);
					-o-transform: rotate(45deg);
					transform: rotate(45deg);
				}

				&:nth-of-type(3) {
					-webkit-transform: rotate(-45deg);
					-moz-transform: rotate(-45deg);
					-o-transform: rotate(-45deg);
					transform: rotate(-45deg);
				}

				&:nth-of-type(4) {
					top: 8px;
					width: 0%;
					left: 50%;
				}
			}
		}
	}
`;

export const HomeIcon = () => {
	return (
		<>
			<FontAwesomeIcon icon={faHome} size={'1x'} />
		</>
	);
};

export const TrashIcon = (props) => (
	<FontAwesomeIcon icon={faTrash} size={props.size || '1x'} />
);
export const SignInIcon = (props) => {
	const size = props.size || '1x';
	return <FontAwesomeIcon icon={faSignInAlt} size={size} />;
};

SignInIcon.propTypes = {
	size: PropTypes.string,
};

export const TimesIcon = () => <FontAwesomeIcon icon={faTimes} size={'1x'} />;
export const TvIcon = () => <FontAwesomeIcon icon={faTv} size={'1x'} />;

export const PhotoVideoIcon = () => {
	return <FontAwesomeIcon icon={faPhotoVideo} size={'1x'} />;
};

HomeIcon.propTypes = {
	size: PropTypes.string,
};

TrashIcon.propTypes = {
	size: PropTypes.string,
};

export const InfoIcon = () => (
	<StyledIcon>
		<FontAwesomeIcon icon={faInfoCircle} />
	</StyledIcon>
);

export const UploadIcon = () => (
	<StyledIcon>
		<FontAwesomeIcon icon={faCloudUploadAlt} />
	</StyledIcon>
);

export const BarsIcon = () => <FontAwesomeIcon icon={faBars} />;

export const BarsIconToggler = ({ expanded = 'false' }) => (
	<MenuBarsToggler aria-expanded={expanded}>
		<div>
			<span></span>
			<span></span>
			<span></span>
			<span></span>
		</div>
	</MenuBarsToggler>
);

BarsIconToggler.propTypes = {
	expanded: PropTypes.bool,
};

export const UserIcon = () => <FontAwesomeIcon icon={faUser} />;

export const VaxIcon = () => <FontAwesomeIcon icon={faSyringe} size={'lg'} />;

export const FacebookIcon = ({ size = '1x' }) => (
	<FontAwesomeIcon icon={faFacebook} size={size} />
);

FacebookIcon.propTypes = {
	size: PropTypes.string,
};

export const TelegramIcon = ({ size = '1x' }) => (
	<FontAwesomeIcon icon={faTelegram} size={size} />
);

TelegramIcon.propTypes = {
	size: PropTypes.string,
};

export const TwitterSquareIcon = ({ size = '1x' }) => (
	<FontAwesomeIcon icon={faTwitterSquare} size={size} />
);

TwitterSquareIcon.propTypes = {
	size: PropTypes.string,
};

export const UserIconCircle = ({ size = '1x' }) => (
	<RoundedUserIcon className='rounded-circle border border-dark overflow-hidden'>
		<FontAwesomeIcon icon={faUser} className='d-block' size={size} />
	</RoundedUserIcon>
);
UserIconCircle.propTypes = {
	size: PropTypes.string,
};

export const UserIconCircleSmall = ({ size = '1x' }) => (
	<RoundedUserIconSmall className='rounded-circle border border-dark overflow-hidden border-thin'>
		<FontAwesomeIcon icon={faUser} className='d-block' size={size} />
	</RoundedUserIconSmall>
);
UserIconCircleSmall.propTypes = {
	size: PropTypes.string,
};

export const SyringeIcon = ({ size = '1x' }) => (
	<FontAwesomeIcon icon={faSyringe} size={size} />
);

SyringeIcon.propTypes = {
	size: PropTypes.string,
};

export const NewspaperIcon = ({ size = '1x' }) => (
	<FontAwesomeIcon icon={faNewspaper} size={size} />
);

NewspaperIcon.propTypes = {
	size: PropTypes.string,
};

export const SpinnerIcon = ({ size = '1x' }) => (
	<FontAwesomeIcon icon={faSpinner} size={size} className='fa-spin' />
);

SpinnerIcon.propTypes = {
	size: PropTypes.string,
};

export const HandPointRightIcon = ({ size = '1x' }) => (
	<FontAwesomeIcon icon={faHandPointRight} size={size} />
);

HandPointRightIcon.propTypes = {
	size: PropTypes.string,
};

export const PencilIcon = ({ size = '1x' }) => (
	<FontAwesomeIcon icon={faPencilAlt} size={size} />
);

PencilIcon.propTypes = {
	size: PropTypes.string,
};

export const AngleRight = ({ size = '1x' }) => (
	<FontAwesomeIcon icon={faAngleRight} size={size} />
);

AngleRight.propTypes = {
	size: PropTypes.string,
};

export const AngleDown = ({ size = '1x' }) => (
	<FontAwesomeIcon icon={faAngleDown} size={size} />
);

AngleDown.propTypes = {
	size: PropTypes.string,
};
export const CommentIcon = () => (
	<StyledIcon>
		<FontAwesomeIcon icon={faComment} />
	</StyledIcon>
);

export const CrossIcon = (props) => (
	<StyledIcon style={{ color: props.color }}>
		<FontAwesomeIcon icon={faCross} />
	</StyledIcon>
);

CrossIcon.propTypes = {
	color: PropTypes.string,
};

export const StarIcon = (props) => (
	<StyledIcon style={{ color: props.color, fontSize: 'inherit' }}>
		<FontAwesomeIcon icon={faStar} />
	</StyledIcon>
);

StarIcon.propTypes = {
	color: PropTypes.string,
};
