import PropTypes from 'prop-types';
import { Chip } from 'components/ui';

const PublishedChip = (props) => {
	return (
		<Chip
			// size='small'
			onClick={props.onClick}
			label={props.label}
			style={{
				backgroundColor: 'var(--publishedGreen)',
				color: 'white',
				fontWeight: '600',
				opacity: props.inactive ? 0.5 : 1,
			}}
		/>
	);
};

PublishedChip.propTypes = {
	label: PropTypes.string,
	onClick: PropTypes.func,
	inactive: PropTypes.bool,
};

const DeletedChip = (props) => {
	return (
		<Chip
			// size='small'
			onClick={props.onClick}
			label={props.label}
			style={{
				backgroundColor: 'var(--deletedGrey)',
				color: 'white',
				fontWeight: '600',
				opacity: props.inactive ? 0.5 : 1,
			}}
		/>
	);
};

DeletedChip.propTypes = {
	label: PropTypes.string,
	onClick: PropTypes.func,
	inactive: PropTypes.bool,
};

const NotValidatedChip = (props) => {
	return (
		<Chip
			// size='small'
			label={props.label}
			onClick={props.onClick}
			style={{
				backgroundColor: 'var(--notValidatedYellow)',
				color: 'white',
				fontWeight: '600',
				opacity: props.inactive ? 0.5 : 1,
			}}
		/>
	);
};

NotValidatedChip.propTypes = {
	label: PropTypes.string,
	onClick: PropTypes.func,
	inactive: PropTypes.bool,
};

const NewTagChip = (props) => {
	return (
		<Chip
			// size='small'
			variant='outlined'
			color='warning'
			label={props.label}
			onClick={props.onClick}
			style={{
				fontWeight: '600',
				opacity: props.inactive ? 0.5 : 1,
			}}
		/>
	);
};

NewTagChip.propTypes = {
	label: PropTypes.string,
	onClick: PropTypes.func,
	inactive: PropTypes.bool,
};

const NoNewTagChip = (props) => {
	return (
		<Chip
			// size='small'
			variant='outlined'
			color='default'
			label={props.label}
			onClick={props.onClick}
			style={{
				fontWeight: '600',
				opacity: props.inactive ? 0.5 : 1,
			}}
		/>
	);
};

NoNewTagChip.propTypes = {
	label: PropTypes.string,
	onClick: PropTypes.func,
	inactive: PropTypes.bool,
};

export {
	PublishedChip,
	DeletedChip,
	NotValidatedChip,
	NewTagChip,
	NoNewTagChip,
};
