import { SearchableFilter } from 'components/impfschaeden/uebersicht/components/SearchableFilter.js';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const ReferrerFilter = ({ filter, updateFilter, onBack, handleClose }) => {
	const [t] = useTranslation();
	const referrers = useSelector((state) => state.masterData.globReferrers);

	const defaultOption = null;

	if (!referrers || !referrers.length > 0) {
		return null;
	}

	// console.log('referrer filter', options, filter);
	let options = referrers?.map((referrer) => {
		return {
			name: referrer.referrer_i18n_key,
			id: referrer.glob_referrer_id,
			key: referrer.referrer_i18n_key.toString(),
		};
	});
	options.unshift({
		name: 'eigener_eintrag',
		id: 0,
		key: 'eigener_eintrag',
	});

	return (
		<SearchableFilter
			options={options}
			defaultOption={defaultOption}
			updateFilter={updateFilter}
			filter={filter}
			onBack={onBack}
			filterKey='referrer'
			title={t('common.herkunft_der_information')}
			placeholder={t('common.suchen')}
			handleClose={handleClose}
			// keyInsteadId={true}
			i18nPrefix='master_data.referrer.'
		/>
	);
};

ReferrerFilter.propTypes = {
	filter: PropTypes.object,
	updateFilter: PropTypes.func,
	onBack: PropTypes.func,
	handleClose: PropTypes.func,
};

export { ReferrerFilter };
