import { regexMail, regexSkyfax } from 'lib/regex.js';

export const validateMailOrSkyfax = (email) => {
	return !email.match(regexMail) && !email.match(regexSkyfax);
};

export const validatePassword = (pw) => {
	return (
		!pw.match(/[A-Z]+/) ||
		!pw.match(/[a-z]+/) ||
		!pw.match(/\d+/) ||
		!pw.match(/[^A-Za-z0-9]+/) ||
		pw.length < 8
	);
};

export const validateRequiredFields = (
	data,
	requiredFields,
	i18nKey = 'required'
) => {
	const result = {};
	if (!requiredFields) {
		return {};
	}
	for (const field of requiredFields) {
		if (!data[field] || data[field].length == 0) {
			result[field] = i18nKey;
		}
	}
	return result;
};
