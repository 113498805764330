import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ErrorPage } from 'components/ErrorPage.js';
import postError from 'utils/postError';
import { connect } from 'react-redux';

export const ErrorFallback = () => {
	return <div>Error Fallback</div>;
};

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);

		// Define a state variable to track whether is an error or not
		this.state = {
			hasError: false,
			success: false,
		};
	}
	static getDerivedStateFromError() {
		// Update state so the next render will show the fallback UI
		return { hasError: true };
	}
	componentDidCatch(error, errorInfo) {
		// You can use your own error logging service here
		console.error('Error caught by Error Boundary:', error, errorInfo);
		postError({
			error: JSON.stringify(error, Object.getOwnPropertyNames(error)),
			errorInfo: JSON.stringify(errorInfo),
			state: JSON.stringify(this.props),
		});
	}
	render() {
		// Check if the error is thrown
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return <ErrorPage success={this.state.success} />;
		}

		// Return children components in case of no error

		return this.props.children;
	}
}

const mapStateToProps = (state) => ({
	myState: state,
});

ErrorBoundary.propTypes = {
	children: PropTypes.object,
};

export default connect(mapStateToProps)(ErrorBoundary);
