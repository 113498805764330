import PropTypes from 'prop-types';
import styled from 'styled-components';

const TitleH1 = styled.h1`
	color: ${(props) => props.theme.themePrimary};
`;

const TitleH2 = styled.h2`
	color: ${(props) => props.theme.themePrimary};
`;

const TitleH3 = styled.h3`
	color: ${(props) => props.theme.themePrimary};
	font-weight: 600;
`;

const TitleH4 = styled.h4`
	color: ${(props) => props.theme.themePrimary};
	font-weight: 600;
`;

const TitleH5 = styled.h5`
	color: ${(props) => props.theme.themePrimary};
	font-weight: 600;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	width: calc(100%);
`;

const TitleH6 = styled.h6`
	color: ${(props) => props.theme.themePrimary};
	font-weight: 600;
`;

const ThemeTitle = (props) => {
	const children = props.text || props.children;
	switch (props.h) {
		case 2:
			return <TitleH2>{children}</TitleH2>;
		case 3:
			return <TitleH3>{children}</TitleH3>;
		case 4:
			return <TitleH4>{children}</TitleH4>;
		case 5:
			return <TitleH5>{children}</TitleH5>;
		case 6:
			return <TitleH6>{children}</TitleH6>;
		default:
			return <TitleH1>{children}</TitleH1>;
	}
};

ThemeTitle.propTypes = {
	text: PropTypes.string,
	h: PropTypes.number,
	children: PropTypes.any,
};

export { ThemeTitle };
