import { FilterHeadline } from 'components/impfschaeden/uebersicht/components/FilterHeadline.js';
import { FilterOption } from 'components/impfschaeden/uebersicht/components/FilterOption.js';
import { SearchInput, SpinnerIcon } from 'components/ui/index.js';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cloneDeep from 'lodash/cloneDeep';
import indexOf from 'lodash/indexOf';
import without from 'lodash/without';
import { TagLexiconFilter } from 'components/impfschaeden/uebersicht/components/TagLexiconFilter.js';

const SearchableFilter = (props) => {
	const {
		filter,
		updateFilter,
		onBack,
		options,
		defaultOption,
		filterKey,
		title,
		placeholder,
		keyInsteadId,
		skipTranslation,
		display,
		selected,
		onSelect,
		i18nPrefix,
	} = props;
	const [t] = useTranslation();
	const [search, setSearch] = useState('');
	const [filteredOptions, setFilteredOptions] = useState(options);

	const onReset = () => {
		let update = {};
		update[filterKey] = [];
		updateFilter(update);
	};

	const select = (option) => {
		let update = {};
		let key = option?.id?.toString();
		if (keyInsteadId) {
			key = option?.key?.toString();
		}
		if (filter[filterKey]) {
			update[filterKey] = cloneDeep(filter[filterKey]);
		} else {
			update[filterKey] = [];
		}

		const index = indexOf(update[filterKey], key);
		if (index > -1) {
			update[filterKey] = without(update[filterKey], key);
		} else {
			update[filterKey].push(key);
		}
		updateFilter(update);
	};

	const updateSearch = (e) => {
		setSearch(e.target.value);
	};

	useEffect(() => {
		if (options?.length > 0 && search != null) {
			setFilteredOptions(
				options.filter((option) => {
					return (
						option.key?.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
						option.name?.toLowerCase().indexOf(search.toLowerCase()) > -1
					);
				})
			);
		} else if (options?.length > 0) {
			setFilteredOptions(options);
		}
	}, [search, options]);

	if (!filterKey) {
		return null;
	}

	return (
		<>
			<FilterHeadline
				title={title}
				onReset={onReset}
				onBack={onBack}
				resetEnabled={filter[filterKey] != null}
			/>
			<div className='p-2 border-bottom d-flex justify-content-between'>
				<div className='flex-grow-1 me-2'>
					<SearchInput
						onChange={updateSearch}
						value={search}
						label={placeholder}
					/>
				</div>

				{onSelect && <TagLexiconFilter {...props} />}
			</div>
			{filteredOptions?.length == 0 && (
				<>
					<br />
					<SpinnerIcon />
				</>
			)}
			{filteredOptions.map((option, index) => {
				let key = option?.id?.toString();
				if (keyInsteadId) {
					key = option?.key?.toString();
				}
				const selected =
					indexOf(filter[filterKey], key) > -1 ||
					indexOf(filter[filterKey], key.toString()) > -1;
				const isDefault = option == defaultOption && filter[filterKey] == null;

				return (
					<FilterOption
						selected={selected}
						isDefault={isDefault}
						onClick={() => {
							select(option);
						}}
						key={'option' + index}
						option={
							skipTranslation
								? option.name
								: t((i18nPrefix ? i18nPrefix : 'common.') + option.name)
						}
						rawOption={option}
						display={display}
					/>
				);
			})}
			{/* <SaveButton className='d-flex justify-content-center p-3 position-sticky bottom-0 border-top'>
				<Button disabled={filter[filterKey].length === 0} onClick={handleClose}>
					{t('common.speichern')}
				</Button>
			</SaveButton> */}
		</>
	);
};

SearchableFilter.propTypes = {
	filter: PropTypes.object,
	updateFilter: PropTypes.func,
	handleClose: PropTypes.func,
	display: PropTypes.func,
	onBack: PropTypes.func,
	options: PropTypes.arrayOf(PropTypes.object),
	defaultOption: PropTypes.object || PropTypes.null,
	filterKey: PropTypes.string,
	title: PropTypes.string,
	placeholder: PropTypes.string,
	keyInsteadId: PropTypes.bool,
	skipTranslation: PropTypes.bool,
	selected: PropTypes.array,
	onSelect: PropTypes.func,
	i18nPrefix: PropTypes.string,
};

export { SearchableFilter };
