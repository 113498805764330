import styled from 'styled-components';
import SocialIcons from '../Header/components/SocialIcons';
import i18n from '../../../i18n'; // eslint-disable-line
import { useEffect, useRef } from 'react';
// import { Logo } from 'components/layout/Logo';
import { useTheme } from 'styled-components';
import NavItems from 'components/layout/Navigation/components/NavItems.js';
import { ActiveLink } from 'components/ui';
import { Logo } from 'components/layout/Logo.tsx';

const SideNavContainer = styled.div``;

const Wrapper = styled.div`
	.list-group-item {
		border: 0;
		padding-top: 1rem;
		padding-bottom: 1rem;
		color: ${(props) => props.theme.fontMenuMain};

		&.active {
			/* background-color: ${(props) => props.theme.contentBg}; */
			color: ${(props) => props.theme.themePrimary};
			font-weight: 600;
			background-color: unset;
		}
	}
`;

const SideNav = (props) => {
	const svg = useRef(null);
	const theme = useTheme();

	useEffect(() => {
		if (svg.current) {
			const test = svg.current.querySelectorAll('path[fill="#878786"]');
			if (test && test.length) {
				for (let i = 0; i < test.length; i++) {
					if (test[i] && test[i]['setAttribute']) {
						let item = test[i];
						item.setAttribute('fill', theme.themePrimary);
					}
				}
			}
		}
	}, [theme]);

	console.log('SideNav', props);

	return (
		<Wrapper>
			<ActiveLink href='/'>
				<div className='position-relative'>
					<img src='/img/Logo_Frame.png' className='d-none d-xl-block mt-4' />
					<div className='position-absolute' style={{ top: 0, left: 0 }}>
						<Logo color={props.theme.themePrimary} />
					</div>
				</div>
			</ActiveLink>
			<SideNavContainer
				className='d-none d-lg-block d-xl-block mt-4'
				style={{
					maxWidth: '200px',
					marginLeft: '23px',
					marginTop: '30px',
					fontWeight: '600',
				}}
			>
				<NavItems />
			</SideNavContainer>
			<SideNavContainer
				className='d-block d-sm-block d-md-block d-lg-none mt-4'
				style={{
					marginLeft: '23px',
					marginTop: '30px',
					fontWeight: '600',
					paddingRight: '23px',
				}}
			>
				<NavItems />
			</SideNavContainer>
			<div className='d-block d-sm-block d-md-block d-lg-none mt-4'>
				<SocialIcons />
			</div>
		</Wrapper>
	);
};

export default SideNav;
