import { useDispatch } from 'react-redux';
import { settingsActions } from 'store/settingsSlice.ts';
import { setJWT } from 'service/user.service';
import { formActions } from 'store/vaxFormDataSlice';

const useLogout = () => {
	const dispatch = useDispatch();

	const logout = () => {
		dispatch(settingsActions.setUser(null));
		dispatch(formActions.update({ user: {} }));
		localStorage.setItem(
			'vaxFormData',
			JSON.stringify({
				...JSON.parse(localStorage.getItem('vaxFormData')),
				user: {},
			})
		);
		setJWT(null);
	};
	return logout;
};

export default useLogout;
