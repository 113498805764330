import { useVaxHealthDamagesLazyQuery } from 'api/glob.api.js';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/store.ts';
import { masterDataActions } from 'store/masterDataSlice';
import { cloneDeep, orderBy } from 'lodash';

const useVaxHealthDamages = () => {
	const dispatch = useDispatch();
	const language = useSelector((state: AppState) => state.settings.language);
	const [fetchVaxHealthDamages] = useVaxHealthDamagesLazyQuery();

	const getVaxHealthDamages = async () => {
		const vaxHealthDamages = await fetchVaxHealthDamages(null).unwrap();
		dispatch(
			masterDataActions.update({
				vaxHealthDamages: orderBy(
					cloneDeep(vaxHealthDamages.data),
					['vax_health_damages_count'],
					['desc']
				),
			})
		);
	};

	useEffect(() => {
		getVaxHealthDamages();
	}, [language]);

	return [getVaxHealthDamages];
};

export default useVaxHealthDamages;
