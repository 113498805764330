import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

const getInitialLanguage = () => {
	if (typeof window !== 'undefined') {
		const language = localStorage.getItem('i18nextLng');
		if (language) {
			return language;
		}
	}

	return 'de';
};

const languages = ['en', 'de', 'ru', 'fr', 'es', 'ja', 'pt', 'it'];

i18n
	/*
		 load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
		 learn more: https://github.com/i18next/i18next-http-backend
		*/
	.use(Backend)
	/*
		 detect user language
		 learn more: https://github.com/i18next/i18next-browser-languageDetector
		*/
	.use(LanguageDetector)
	/*
		 pass the i18n instance to react-i18next.
		*/
	.use(initReactI18next)
	/*
		 init i18next
		 for all options read: https://www.i18next.com/overview/configuration-options
		*/
	.init(
		{
			// lng: getInitialLanguage(),
			fallbackLng: 'en', // use et if detected lng is not available
			saveMissing: false, // send not translated keys to endpoint
			debug: false,
			whitelist: languages,
			missingKeyHandler: (
				lngs,
				ns,
				key,
				fallbackValue,
				updateMissing,
				options
			) => {
				// console.log('missingKeyHandler', lngs, ns, key, fallbackValue);
				return 'missingKey';
			},
			parseMissingKeyHandler: (key, defaultValue) => {
				if (key.indexOf('navigation') < -1) {
					return key.split('.').pop();
				} else {
					return key;
				}
			},

			// backend: {
			//     // for all available options read the backend's repository readme file
			//     loadPath: '/locales/{{lng}}/{{ns}}.json'
			// },
			backend: {
				loadPath: `${process.env.API_URL}v1/translation/{{lng}}`,
			},

			react: {
				// wait: true,
				// useSuspense: false,
			},
		},
		(err, t) => {
			try {
				if(window)
				window.translationLoaded = true;
			} catch (e) {
				
			}
			
		}
	);

export default i18n;
