import { ArrowBack } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const TextButton = styled.div`
	color: var(--bs-blue);
	:hover {
		text-decoration: underline;
	}
`;

const FilterHeadline = ({ onBack, title, onReset, resetEnabled, section }) => {
	const [t] = useTranslation();
	return (
		<div className='d-flex justify-content-between align-items-center p-3 border-bottom'>
			<div className='d-flex justify-content-between align-items-center'>
				<div onClick={onBack} className='me-3 cursor-pointer'>
					<ArrowBack fontSize={'large'} />
				</div>
				<div>
					<strong>{title}</strong>
				</div>
			</div>
			{resetEnabled && (
				<TextButton onClick={onReset} className='cursor-pointer'>
					<a>
						{section === null && (
							<strong>{t('common.alle_filter_zuruecksetzen')}</strong>
						)}
						{section !== null && <strong>{t('common.zuruecksetzen')}</strong>}
					</a>
				</TextButton>
			)}
		</div>
	);
};

FilterHeadline.propTypes = {
	onBack: PropTypes.func,
	title: PropTypes.string,
	onReset: PropTypes.func,
	resetEnabled: PropTypes.bool,
	section: PropTypes.string,
};

export { FilterHeadline };
