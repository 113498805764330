import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { FormHelperText } from 'components/ui';
import { useTranslation } from 'react-i18next';
import { InputAdornment } from '@mui/material';
import { Search } from '@mui/icons-material';

const filter = createFilterOptions();

export default function SearchSelect(props) {
	const [t] = useTranslation();

	const displayProperty = props.displayProperty || 'name';
	const options = props.options || [
		{ name: 'test option 1' },
		{ name: 'test option 2' },
	];
	const freeSolo = props.disableAdd ? false : true;
	return (
		<div>
			<Autocomplete
				disabled={props.disabled}
				noOptionsText={t('common.no_result')}
				value={props.value}
				onChange={(_event, newValue) => {
					if (typeof newValue === 'string') {
						props.onChange(newValue);
					} else if (newValue && newValue.inputValue) {
						props.onChange(newValue.inputValue);
					} else if (newValue) {
						if (props.valueProperty) {
							props.onChange(newValue[props.valueProperty]);
						} else {
							props.onChange(newValue[displayProperty]);
						}
					} else {
						props.onChange('');
					}
				}}
				filterOptions={(options, params) => {
					const { inputValue } = params;
					let preFiltered = options;
					if (inputValue != '') {
						preFiltered = options.filter((option) => {
							return option.type !== 'separator';
						});
					}
					const filtered = filter(preFiltered, params);

					// Suggest the creation of a new value
					const isExisting = options.some(
						(option) => inputValue === option[displayProperty]
					);
					if (inputValue !== '' && !isExisting && !props.disableAdd) {
						const valueObj = { inputValue };
						valueObj[displayProperty] = `${t(
							'impfschaeden.erfassen.hinzufuegen_text'
						)} "${inputValue}"`;
						filtered.push(valueObj);
					}

					return filtered;
				}}
				selectOnFocus
				clearOnBlur
				handleHomeEndKeys
				id='free-solo-with-text-demo'
				options={options}
				getOptionLabel={(option) => {
					// Value selected with enter, right from the input
					if (options.type == 'separator') {
						return option.text;
					}
					if (typeof option === 'string') {
						return option;
					}
					// Add "xxx" option created dynamically
					if (option.inputValue) {
						return option.inputValue.toString();
					}
					// Regular option
					return option[displayProperty]?.toString() || '';
				}}
				renderOption={(renderProps, option) => {
					if (option.type == 'separator') {
						return (
							<li
								onClick={(e) => {
									e.stopPropagation();
									e.preventDefault();
								}}
								{...renderProps}
								key={option.text + ''}
								className={renderProps.className + ' py-0 my-0'}
								style={{ backgroundColor: 'transparent' }}
							>
								<FormHelperText
									onClick={(e) => {
										e.stopPropagation();
										e.preventDefault();
									}}
									style={{ color: 'var(--bs-primary)' }}
								>
									{option.text}
								</FormHelperText>
							</li>
						);
					} else {
						return (
							<li
								{...renderProps}
								key={option[displayProperty] + '' + option[props.valueProperty]}
							>
								{option[displayProperty]}
							</li>
						);
					}
				}}
				freeSolo={freeSolo}
				renderInput={(params) => (
					<TextField
						{...params}
						label={props.label}
						error={props.isInvalid}
						size={'small'}
						InputProps={{
							...params.InputProps,
							startAdornment: (
								<>
									<InputAdornment position='start'>
										<Search />
									</InputAdornment>
									{params.InputProps.startAdornment}
								</>
							),
						}}
					/>
				)}
			/>
			{props.isInvalid && (
				<FormHelperText style={{ color: '#dc3545' }}>
					{props.isInvalid}
				</FormHelperText>
			)}
		</div>
	);
}

SearchSelect.propTypes = {
	label: PropTypes.string,
	variant: PropTypes.string,
	freeSolo: PropTypes.bool,
	options: PropTypes.arrayOf(PropTypes.object),
	onChange: PropTypes.func,
	renderInput: PropTypes.func,
	getOptionLabel: PropTypes.func,
	isInvalid: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	displayProperty: PropTypes.string,
	valueProperty: PropTypes.string,
	disableAdd: PropTypes.bool,
	disabled: PropTypes.bool,
};

export { SearchSelect };
