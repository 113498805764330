import { FilterHeadline } from 'components/impfschaeden/uebersicht/components/FilterHeadline.js';
import { SearchInput } from 'components/ui/index.js';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

const ZipFilter = ({
	filter,
	updateFilter,
	onBack,
	filterKey,
	placeholder,
	setSection,
}) => {
	const [t] = useTranslation();
	const [search, setSearch] = useState(filter.zip || '');

	const onReset = () => {
		let update = {};
		update[filterKey] = '';
		updateFilter(update);
		setSearch('');
	};

	const updateSearch = (e) => {
		setSearch(e.target.value);
		updateFilter({ zip: e.target.value });
	};

	if (!filterKey) {
		return null;
	}
	return (
		<>
			<FilterHeadline
				title={t('common.ort')}
				onReset={onReset}
				onBack={onBack}
				resetEnabled={filter[filterKey] != null}
			/>
			{filter?.country?.length === 0 && (
				<div>
					<Trans i18nKey={'common.waehle_zuerst_land'}>
						Bitte wählen Sie zuerst ein
						<a href='#' onClick={() => setSection('country')}>
							Land
						</a>
						.
					</Trans>
				</div>
			)}
			{filter?.country?.length !== 0 && (
				<>
					<div className='p-2 border-bottom'>
						<div>{t('common.geben_sie_begin_plz_ein')}</div>
						<SearchInput
							onChange={updateSearch}
							value={search}
							label={placeholder}
						/>
					</div>
					{/* <SaveButton className='d-flex justify-content-center p-3 position-sticky bottom-0 border-top'>
						<Button disabled={filter[filterKey] === ''} onClick={handleClose}>
							{t('common.speichern')}
						</Button>
					</SaveButton> */}
				</>
			)}
		</>
	);
};

ZipFilter.propTypes = {
	filter: PropTypes.object,
	updateFilter: PropTypes.func,
	handleClose: PropTypes.func,
	onBack: PropTypes.func,
	options: PropTypes.arrayOf(PropTypes.object),
	defaultOption: PropTypes.object || PropTypes.null,
	filterKey: PropTypes.string,
	title: PropTypes.string,
	placeholder: PropTypes.string,
	setSection: PropTypes.func,
};

export { ZipFilter };
