import { FilterHeadline } from 'components/impfschaeden/uebersicht/components/FilterHeadline.js';
import { FilterOption } from 'components/impfschaeden/uebersicht/components/FilterOption.js';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { initialFilter } from 'components/impfschaeden/uebersicht/VaxOverview.js';
import isEqual from 'lodash/isEqual';
import useAvailableEntryFilters from 'components/nutzerbereich/UserArea/hooks/useAvailableEntryFilters.js';
import { useState } from 'react';
import { SearchInput, SearchSelect } from 'components/ui/index.js';
import useFilterState from 'components/nutzerbereich/UserArea/hooks/useFilterState.js';
import { useGetUsersByRoleQuery } from 'api/internal.api.ts';
import orderBy from 'lodash/orderBy';
import { useDispatch } from 'react-redux';
import { settingsActions } from 'store/settingsSlice.ts';
import { initialModeratorFilter } from 'components/nutzerbereich/UserArea/UserArea.js';

const EntryFilterSelection = ({
	onBack,
	section,
	setSection,
	filter,
	updateFilter,
}) => {
	const [t] = useTranslation();
	const { availableFilters } = useAvailableEntryFilters();
	const dispatch = useDispatch();

	const { state: entryBy, setFilter: setEntryBy } = useFilterState(
		undefined,
		'entryBy'
	);
	const { state: publishedBy, setFilter: setPublishedBy } = useFilterState(
		undefined,
		'publishedBy'
	);
	const { state: deletedBy, setFilter: setDeletedBy } = useFilterState(
		undefined,
		'deletedBy'
	);

	const { data: allUsers } = useGetUsersByRoleQuery({ role: 'all' });

	// console.log(
	// 	'EntryFilterSelection',
	// 	entryBy,
	// 	publishedBy,
	// 	deletedBy,
	// 	orderBy(allUsers?.data, [(user) => user.email.toLowerCase()])
	// );

	return (
		<>
			<FilterHeadline
				title={t('common.filter_auswaehlen')}
				onReset={() => {
					for (let modFilter in initialModeratorFilter) {
						localStorage.removeItem('adminView_' + modFilter);
					}
					dispatch(settingsActions.setModeratorFilter(initialModeratorFilter));
				}}
				onBack={onBack}
				resetEnabled={!isEqual(filter, initialFilter)}
				section={section}
			/>
			<div className='p-2 border-bottom d-flex justify-content-between'>
				<div className='flex-grow-1 me-2'>
					<SearchSelect
						label={t('common.eingetragen_von')}
						displayProperty='email'
						valueProperty='email'
						onChange={(newValue) => {
							// console.log('onChange', newValue);
							setEntryBy(newValue);
						}}
						value={{ email: entryBy }}
						options={orderBy(allUsers?.data, [
							(user) => user.email.toLowerCase(),
						])}
						disableAdd={true}
					/>
				</div>
			</div>

			<div className='p-2 border-bottom d-flex justify-content-between'>
				<div className='flex-grow-1 me-2'>
					<SearchSelect
						label={t('common.veroeffentlicht_von')}
						displayProperty='email'
						valueProperty='email'
						onChange={(newValue) => {
							setPublishedBy(newValue);
						}}
						value={{ email: publishedBy }}
						options={orderBy(allUsers?.data, [
							(user) => user.email.toLowerCase(),
						])}
						disableAdd={true}
					/>
				</div>
			</div>

			<div className='p-2 border-bottom d-flex justify-content-between'>
				<div className='flex-grow-1 me-2'>
					<SearchSelect
						label={t('common.geloescht_von')}
						displayProperty='email'
						valueProperty='email'
						onChange={(newValue) => {
							setDeletedBy(newValue);
						}}
						value={{ email: deletedBy }}
						options={orderBy(allUsers?.data, [
							(user) => user.email.toLowerCase(),
						])}
						disableAdd={true}
					/>
				</div>
			</div>
			{availableFilters.map((filter, index) => {
				return (
					<FilterOption
						selected={false}
						isDefault={false}
						onClick={() => setSection(filter.key)}
						key={'filter-' + index}
						option={t('common.' + filter.name)}
						display={filter.display}
					/>
				);
			})}
		</>
	);
};

EntryFilterSelection.propTypes = {
	onBack: PropTypes.func,
	section: PropTypes.string,
	setSection: PropTypes.func,
	filter: PropTypes.object,
	updateFilter: PropTypes.func,
};

export { EntryFilterSelection };
