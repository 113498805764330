import {
	Contrast,
	Done,
	Download,
	Palette,
	WaterDrop,
} from '@mui/icons-material';
import { Button, Modal } from 'components/ui';
import { API_URL } from 'env.js';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const DownloadLexiconMenu = (props) => {
	const [t] = useTranslation();
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const [successMessage, setSuccessMessage] = useState('');

	const showColorSuccess = (text) => {
		setSuccessMessage(text);
		setTimeout(() => {
			setSuccessMessage('');
			handleClose();
		}, 2000);
	};

	return (
		<div>
			<Button className='p-2' onClick={handleShow}>
				<Download />
			</Button>
			<Modal show={show} onHide={handleClose} size={'md'}>
				<Modal.Header closeButton>
					{/* <Modal.Title className='w-100'>
						<div className='d-flex justify-content-between w-100 align-items-center'>
							{t('common.schlagwort_lexikon')}
							<div className='ms-3 me-3'>
								<a
									href={API_URL + 'v1/vax/get_lexicon'}
									// target='_blank'
									rel='noreferrer'
								>
									<Button className='p-2'>
										<Download />
									</Button>
								</a>
							</div>
						</div>
					</Modal.Title> */}
				</Modal.Header>
				<Modal.Body>
					<div className='d-flex justify-content-around'>
						<div className='ms-3 me-3'>
							<a
								href={'/impfschaden/schlagworte/lexikon'}
								target='_blank'
								rel='noreferrer'
							>
								<Button
									onClick={() =>
										showColorSuccess(
											t('schlagworte.lexikon_wurde_in_neuem_tab_geoeffnet')
										)
									}
									className='p-2'
									size='lg'
									style={{ height: '120px', width: '120px' }}
								>
									<svg width={0} height={0}>
										<linearGradient
											id='linearColors'
											gradientTransform='rotate(45) translate(0.25, 0.1)'
										>
											<stop offset={'0%'} stopColor='rgba(255, 0, 0, 1)' />
											<stop offset={'10%'} stopColor='rgba(255, 0, 0, 1)' />
											<stop offset={'20%'} stopColor='rgba(255, 154, 0, 1)' />
											<stop offset={'30%'} stopColor='rgba(208, 222, 33, 1)' />
											<stop offset={'40%'} stopColor='rgba(79, 220, 74, 1)' />
											<stop offset={'50%'} stopColor='rgba(63, 218, 216, 1)' />
											<stop offset={'60%'} stopColor='rgba(47, 201, 226, 1)' />
											<stop offset={'70%'} stopColor='rgba(28, 127, 238, 1)' />
											<stop offset={'80%'} stopColor='rgba(95, 21, 242, 1)' />
											<stop offset={'90%'} stopColor='rgba(186, 12, 248, 1)' />
											<stop offset={'100%'} stopColor='rgb(251, 7, 217)' />
											{/* <stop offset={'100%'} stopColor=' rgba(255, 0, 0, 1)' /> */}
										</linearGradient>
									</svg>
									<WaterDrop
										sx={{
											fontSize: 40,
											fill: 'url(#linearColors)',
										}}
									/>
								</Button>
							</a>
						</div>
						<div className='ms-3 me-3'>
							<a
								href={API_URL + 'v1/vax/get_lexicon?v=' + Date.now()}
								// target='_blank'
								rel='noreferrer'
							>
								<Button
									onClick={() =>
										showColorSuccess(
											t('schlagworte.lexikon_wird_heruntergeladen')
										)
									}
									className='p-2'
									size='lg'
									style={{ height: '120px', width: '120px' }}
								>
									<WaterDrop sx={{ fontSize: 40, color: 'black' }} />
								</Button>
							</a>
						</div>
					</div>
					<div
						className='d-flex justify-content-around mt-3'
						style={{ height: '30px' }}
					>
						{successMessage && (
							<div>
								<Done color='success' /> {successMessage}
							</div>
						)}
						{!successMessage && <div> </div>}
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
};

DownloadLexiconMenu.propTypes = {
	test: PropTypes.string,
};

export { DownloadLexiconMenu };
