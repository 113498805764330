import { createSlice } from '@reduxjs/toolkit';
import { initialFilter } from 'components/impfschaeden/uebersicht/VaxOverview.js';
import { initialModeratorFilter } from 'components/nutzerbereich/UserArea/UserArea.js';
import { getCurrentUser } from 'service/user.service';
import useUpdateCheck from 'hooks/useUpdateCheck.js';
import { availableLanguages } from 'lib/utils.js';
import { DamageOccurred } from 'store/masterDataSlice.ts';

function getCookie(cname) {
	let name = cname + '=';
	let decodedCookie = decodeURIComponent(document.cookie);
	let ca = decodedCookie.split(';');
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) == ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
	return '';
}

const getInitialState = () => {
	const { hasUpdate } = useUpdateCheck();
	let initialState = {
		language: 'de',
		machineTranslation: true,
		token: '',
		currentUser: null,
		vaxFilter: initialFilter,
		moderatorFilter: initialModeratorFilter,
	};
	if (typeof window !== 'undefined' && !hasUpdate()) {
		let locale = window?.location?.pathname?.substring(1, 3);
		if (!locale) {
			getCookie('NEXT_LOCALE');
		}
		if (!locale) {
			const found = availableLanguages.find((item) => {
				return item.lang_iso_code == window.location.pathname.substring(1, 3);
			});
			if (found) {
				locale = found.lang_iso_code;
			} else {
				locale = 'en';
			}
		}
		localStorage.setItem('i18nextLng', locale);
		//|| 'en'; // Default to 'en' if no cookie is found
		initialState.language = locale;
	}
	if (typeof window !== 'undefined') {
		const token = getCookie('auth_token');
		if (token) {
			initialState.token = token;
		}
	}
	if (typeof window !== 'undefined') {
		const user = getCurrentUser();
		if (user) {
			initialState.currentUser = user;
		}
	}
	let entryBy;
	if (typeof window !== 'undefined' && !hasUpdate()) {
		entryBy = JSON.parse(localStorage.getItem('adminView_entryBy'));
		if (entryBy?.item) {
			initialState.moderatorFilter.entryBy = entryBy?.item;
		}
	}
	if (typeof window !== 'undefined' && !hasUpdate()) {
		const publishedBy = JSON.parse(
			localStorage.getItem('adminView_publishedBy')
		);
		if (publishedBy?.item) {
			initialState.moderatorFilter.publishedBy = publishedBy?.item;
		}
	}
	if (typeof window !== 'undefined' && !hasUpdate()) {
		const deletedBy = JSON.parse(localStorage.getItem('adminView_deletedBy'));
		if (deletedBy?.item) {
			initialState.moderatorFilter.deletedBy = deletedBy?.item;
		}
	}
	return initialState;
};

export type User = {
	user_id: number;
	email: string;
	user_team_id: number;
	is_moderator: boolean;
	is_expert: boolean;
	is_admin: boolean;
	is_translator: boolean;
	newsletter: boolean;
	title: string;
	first_name: string;
	last_name: string;
	avatar_glob_id: string;
	confirmed: number;
	user_salutation_id: number;
	unique_key: string;
	token: string;
	created_at: string;
	updated_at: string;
	lang_iso_code: string;
};
export type AdminDate = {
	start: string;
	end: string;
	unknown_day: boolean;
	unknown_month: boolean;
	unknown_date: boolean;
	include_unknown: boolean;
};

export type VaxFilter = {
	sort: 'relevanz';
	sex: null;
	hospitalized: string;
	vaccination: string[];
	manufacturer: string[];
	vax_damage: string[];
	health_result: string[];
	age: string[];
	zip: number;
	batch_number?: string;
	country: string[];
	filter_language: string[];
	damage_occurred: DamageOccurred[];
	admin_date: AdminDate;
	search: string;
	pre_health_damage: number[];
};

export type Settings = {
	language: string;
	machineTranslation: boolean;
	token: string;
	currentUser: User;
	vaxFilter: VaxFilter;
};

const settingsSlice = createSlice({
	name: 'settings',
	initialState: getInitialState(),
	reducers: {
		setMachineTranslation(state, { payload }) {
			state.machineTranslation = payload;
		},
		setLanguage(state, { payload }) {
			state.language = payload;
		},
		setToken(state, { payload }) {
			state.token = payload;
		},
		setUser(state, { payload }) {
			state.currentUser = payload;
		},
		updateUser(state, { payload }) {
			state.currentUser.avatar_glob_file_id = payload;
		},
		setVaxFilter(state, { payload }) {
			state.vaxFilter = payload;
		},
		setModeratorFilter(state, { payload }) {
			let newState = { ...state.moderatorFilter, ...payload };
			state.moderatorFilter = newState;
		},
	},
});

export const settingsActions = settingsSlice.actions;

export default settingsSlice.reducer;
