import PropTypes from 'prop-types';
import { FormCheck } from 'react-bootstrap';

const RadioButton = (props) => {
	return (
		<FormCheck
			disabled={props.disabled}
			name={props.name}
			type='radio'
			value={props.value}
			label={props.label}
			checked={props.checked}
			onChange={props.onChange}
			isInvalid={props.isInvalid}
		/>
	);
};

RadioButton.propTypes = {
	value: PropTypes.string,
	name: PropTypes.string,
	onChange: PropTypes.func,
	label: PropTypes.string,
	checked: PropTypes.bool,
	disabled: PropTypes.bool,
	isInvalid: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export { RadioButton };
