import { useVaccinationCountsLazyQuery } from 'api/vax.api.js';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/store.ts';
import { masterDataActions } from 'store/masterDataSlice';
import { cloneDeep, orderBy } from 'lodash';

const useVaxTypeCounts = () => {
	const dispatch = useDispatch();
	const language = useSelector((state: AppState) => state.settings.language);
	const [fetchVaxTypeCounts] = useVaccinationCountsLazyQuery();

	const getVaxTypeCounts = async () => {
		const vaxTypeCounts = await fetchVaxTypeCounts(null).unwrap();
		dispatch(
			masterDataActions.update({
				vaxTypeCounts: orderBy(
					cloneDeep(vaxTypeCounts.data),
					['vax_entry_vaccinations_count'],
					['desc']
				),
			})
		);
	};

	useEffect(() => {
		getVaxTypeCounts();
	}, [language]);

	return [getVaxTypeCounts];
};

export default useVaxTypeCounts;
