import Button from '@mui/material/Button';
import { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const BootstrapStyleButton = styled(Button)`
	background-color: var(--bs-blue) !important;
	border-radius: 0.375rem !important;
	font-size: 1rem !important;
	box-shadow: none !important;
	&:hover {
		background-color: #268fff !important;
	}
`;

const UploadButton = (props) => {
	const label = props.label || 'Upload';
	const inputRef = useRef();

	const onChange = () => {
		props.onChange(inputRef.current.files);
		inputRef.current.value = null;
	};

	return (
		<BootstrapStyleButton
			variant='contained'
			component='label'
			sx={{ textTransform: 'none' }}
		>
			{label}
			<input
				ref={inputRef}
				hidden
				accept='image/*'
				multiple
				type='file'
				onChange={onChange}
			/>
		</BootstrapStyleButton>
	);
};

UploadButton.propTypes = {
	onChange: PropTypes.func,
	label: PropTypes.string,
};

export { UploadButton };
