import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/store.ts';
import { masterDataActions } from 'store/masterDataSlice';
import { cloneDeep, orderBy } from 'lodash';
import { useVaxLanguageCountsLazyQuery } from 'api/glob.api.js';

const useLanguageCounts = () => {
	const dispatch = useDispatch();
	const language = useSelector((state: AppState) => state.settings.language);
	const [fetchLanguageCounts] = useVaxLanguageCountsLazyQuery();

	const getLanguageCounts = async () => {
		const languageCounts = await fetchLanguageCounts(null).unwrap();
		dispatch(
			masterDataActions.update({
				languageCounts: orderBy(
					cloneDeep(languageCounts.data),
					['total'],
					['desc']
				),
			})
		);
	};

	useEffect(() => {
		getLanguageCounts();
	}, [language]);

	return [getLanguageCounts];
};

export default useLanguageCounts;
