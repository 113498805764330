import { getInternetLinkParts } from 'lib/utils.js';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/store.ts';
import { VaxEntry } from 'store/vaxFormDataSlice.ts';
import dayjs from 'dayjs';

const useEntrySourceReference = (props: VaxEntry) => {
	const [t] = useTranslation();
	const {
		internet_link,
		case_id,
		glob_referrer_id,
		own_entry,
		originally_posted_at,
		created_at,
	} = props;
	const { complete_link, source_domain, source_href } = getInternetLinkParts(
		internet_link,
		case_id
	);
	let source = complete_link;
	let caseId = case_id;
	let href = complete_link;
	let date = originally_posted_at
		? t('common.datum_doppelpunkt') +
		  ' ' +
		  dayjs(originally_posted_at).format('L')
		: '';
	let sealDate = created_at
		? t('impfschaden.eingetragen_am') + ' ' + dayjs(created_at).format('L')
		: '';

	if (glob_referrer_id == '1') {
		sealDate = '';
	} else {
		date = '';
	}

	if (glob_referrer_id == '2') {
		source = t('master_data.referrer.aus_erster_hand');
		caseId = '';
		href = '';
	}

	if (own_entry == '1') {
		source = t('master_data.referrer.eigener_eintrag');
		caseId = '';
		href = '';
	}

	return {
		source,
		sourceLabel: t('common.quelle'),
		caseId: caseId,
		caseIdLabel: t('impfschaeden.uebersicht.id'),
		href,
		date,
		sealDate,
	};
};

export default useEntrySourceReference;
