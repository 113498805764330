import React from 'react';
//import {useState, useEffect} from 'react';
import { OpenInFull } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ModalBody } from 'react-bootstrap';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { Container, Row, Col, Modal, VaxIcon } from 'components/ui';
import { EntryProfile } from 'components/impfschaeden/uebersicht/EntryCard/components/EntryProfile.js';
import { Steckbrief } from 'components/impfschaeden/uebersicht/EntryCard/components/Steckbrief.js';
import { getAdminDate, getInternetLinkParts } from 'lib/utils.js';
import ReactPlayer from 'react-player';
import { EntryDescription } from 'components/impfschaeden/uebersicht/EntryCard/components/EntryDescription.js';
import orderBy from 'lodash/orderBy';
import { SourceReference } from 'components/impfschaeden/uebersicht/EntryCard/components/SourceReference';
import useEntrySourceReference from 'components/impfschaeden/uebersicht/hooks/useEntrySourceReference.ts';

export function DetailedViewModal(props) {
	const sourceReferenceData = useEntrySourceReference(props);
	const vaxTypes = useSelector((state) => state.masterData.vaxTypes);
	const [t] = useTranslation();
	const { vax_entry_vaccinations, vax_entry_files, case_id, internet_link } =
		props;
	let videos = [];
	let images = [];
	let documents = [];

	vax_entry_files.map(DetectDataFormat);
	function DetectDataFormat(data) {
		if (
			data?.file?.filename
				?.substring(data.file.filename.length - 4)
				.toLowerCase() == '.mp4' ||
			data?.file?.filename
				?.substring(data.file.filename.length - 4)
				.toLowerCase() == '.3gp'
		) {
			videos.push(data);
		} else if (
			data?.file?.filename
				?.substring(data.file.filename.length - 4)
				.toLowerCase() == '.jpeg' ||
			data?.file?.filename
				?.substring(data.file.filename.length - 4)
				.toLowerCase() == '.jpg' ||
			data?.file?.filename
				?.substring(data.file.filename.length - 4)
				.toLowerCase() == '.png'
		) {
			images.push(data);
		} else if (
			data?.file?.filename
				?.substring(data.file.filename.length - 4)
				.toLowerCase() == '.pdf'
		) {
			documents.push(data);
		}
	}

	const vaxType =
		vax_entry_vaccinations[vax_entry_vaccinations.length - 1].vax_type_id;
	const batchNumber = vax_entry_vaccinations[0].batch_number;

	let formateDate = (index) => {
		// console.log(index, vax_entry_vaccinations);
		let adate = '';
		adate += vax_entry_vaccinations[index].admin_year || '';
		adate += vax_entry_vaccinations[index].admin_month || '';
		adate += vax_entry_vaccinations[index].admin_day || '';

		let date = dayjs(
			adate,
			adate.length == 4
				? 'YYYY'
				: adate.length == 6
				? 'YYYYMM'
				: adate.length == 8
				? 'YYYYMMDD'
				: 'error'
		).format(
			adate.length == 4
				? 'YYYY'
				: adate.length == 6
				? 'MMMM YYYY'
				: adate.length == 8
				? 'L'
				: 'error'
		);

		if (date == 'Invalid Date') {
			date = t('common.unbekannt');
		}
		return date;
	};
	const Headline = styled.h5`
		color: #5e5e5e;
		font-size: 27.65px;
	`;

	const ListPoint = styled.div`
		/*margin: 7px 0;*/
	`;

	const ProfileHeader = styled.h5`
		color: #5e5e5e;
		font-size: 30.65px;
		display: 'flex';
	`;

	const ColoredLine = styled.hr`
		color: ${(props) => props.theme.themePrimary} !important;
		background-color: ${(props) => props.theme.themePrimary} !important;
		height: 3px;
		opacity: 1;
		margin-bottom: 20px;
	`;

	const VaxinationHeadline = styled.div`
		color: orange;
		font-weight: bold;
	`;

	const VaccinationBox = styled.div`
		border: 2px solid orange;
		background-color: white !important;
		max-width: 100%;
		overflow-wrap: break-word;
		white-space: normal;
		margin-right: 4px;
		margin-bottom: 4px;
		border-radius: 10px;
	`;

	const ThemeVaxIcon = styled.div`
		color: orange;
		text-align: end !important;
	`;

	const GreyLine = styled.hr`
		color: grey !important;
		background-color: grey !important;
		height: 3px;
		opacity: 1;
	`;

	const vaxManufacturer = vax_entry_vaccinations[0].vax_manufacturer_id;
	const vaxManufacturers = useSelector(
		(state) => state.masterData.vaxManufacturers
	);

	const { complete_link, source_domain, source_href } = getInternetLinkParts(
		internet_link,
		case_id
	);

	return (
		<>
			<Row>
				<Col className='justify-content-center d-flex'>
					<ProfileHeader>
						{vaxTypes.find((item) => {
							return item.vax_type_id == vaxType;
						})?.vax_type_i18n_key ||
							vax_entry_vaccinations[0].vax_type?.vax_type_i18n_key ||
							vax_entry_vaccinations[0].vax_type_id}
					</ProfileHeader>
				</Col>
				{/* <GreyLine /> */}

				{/* {vaxTypes.find ((vax_entry_vaccinations[vax_entry_vaccinations.length - 1]) => {
							return vax_entry_vaccinations[vax_entry_vaccinations.length - 1].vax_type_id == vaxType;
						})?.vax_type_i18n_key
						} */}
			</Row>
			<Row>
				<Col lg={6} md={12}>
					<Container className='mb-3 d-block d-lg-none'>
						<EntryProfile {...props} detail={true} />
					</Container>
					<div
						className='overflow-auto d-flex flex-column justify-content-between'
						// style={{ overflowX: 'scroll', height: '100%' }}
					>
						<div>
							<EntryDescription {...props} long={true} detail={true} />
						</div>
						<div>
							<div className='d-flex flex-column mt-2'>
								{vax_entry_vaccinations.map((vaccination, index) => {
									return (
										<VaccinationBox key={vaccination.vax_entry_vaccination_id}>
											<VaxinationHeadline
												className={
													'text-break ms-1 d-flex' +
													' justify-content-between me-1' +
													' align-items-center'
												}
											>
												{vaxTypes.find((item) => {
													return item.vax_type_id == vaccination.vax_type_id;
												})?.vax_type_i18n_key ||
													vaccination.vax_type?.vax_type_i18n_key ||
													vaccination.vax_type_id}
												{/* <ThemeVaxIcon className='justify-content-end'> */}
												<VaxIcon size={20} />
												{/* </ThemeVaxIcon> */}
											</VaxinationHeadline>
											{vaccination.vax_manufacturer_id != 1 && (
												<div className='text-break w-100 ms-1'>
													{t('common.hersteller')}
													{': '}
													{vaxManufacturers.find((item) => {
														return (
															item.vax_manufacturer_id ==
															vaccination.vax_manufacturer_id
														);
													})?.manufacturer_i18n_key ||
														vaccination.vax_manufacturer
															?.manufacturer_i18n_key ||
														vaccination.vax_manufacturer_id}
												</div>
											)}
											{vaccination.batch_number && (
												<div className='text-break w-100 ms-1'>
													{t('common.chargennummer')}:{' '}
													{vaccination.batch_number}
												</div>
											)}
											<div className='text-break w-100 ms-1'>
												{t('impfschaeden.erfassen.datum_der_impfung')}{' '}
												{getAdminDate(
													vaccination,
													t('impfschaeden.erfassen.unknown')
												)}
											</div>
										</VaccinationBox>
									);
								})}
							</div>
							<SourceReference {...sourceReferenceData} />
						</div>
					</div>
				</Col>
				{/* <Col
					lg={1}
					className='d-xl-block d-xs-none border-end p-0'
					style={{ width: '2px' }}
				></Col> */}
				<Col lg={6} md={12}>
					<Container className='mb-3 d-none d-lg-block'>
						<EntryProfile {...props} detail={true} />
					</Container>
					<div className='d-none d-lg-block'>
						<Steckbrief {...props} detail={true} />
					</div>
				</Col>
			</Row>
			{vax_entry_files.length > 0 && (
				<Row className='mt-5'>
					<Col>
						{/* <GreyLine color='#FF9B0D' /> */}
						{orderBy(vax_entry_files, 'lexical_order').map((data, index) => {
							const filename = data.filename || data.file?.filename;
							const glob_file_id = data.glob_file_id || data.file?.glob_file_id;
							// console.log(
							// 	'map file',
							// 	filename,
							// 	glob_file_id,
							// 	filename?.substring(filename.length - 4).toLowerCase()
							// );
							return (
								<div key={'media' + index} className='mb-2'>
									{(filename?.substring(filename.length - 4).toLowerCase() ==
										'.mp4' ||
										filename?.substring(filename.length - 4).toLowerCase() ==
											'.3gp') && (
										<ReactPlayer
											width='100%'
											// eslint-disable-next-line max-len
											url={`${process.env.API_URL}v1/glob/files/${glob_file_id}/video.mp4`}
											pip={true}
											controls={true}
											// playing={playing == index}
											// ref={ref}
											// onPlay={() => setPlaying(index)}
											// onPause={() => setPlaying(-1)}
										/>
									)}
									{/* prettier-ignore */}
									{(filename?.substring(filename.length - 4).toLowerCase() ==
										'.jpg' ||
										filename?.substring(filename.length - 4).toLowerCase() ==
											'.png') && (
										<div
											style={{
												display: 'flex',
												height: '100%',
												width: '100%',
												justifyContent: 'center',
												alignItems: 'center',
											}}
										>
											<img
												className='mw-100'
												// eslint-disable-next-line max-len
												src={`${process.env.API_URL}v1/glob/files/${glob_file_id}/image.jpg`}
												// eslint-disable-next-line max-len
												alt={`${process.env.API_URL}v1/glob/files/${filename}/image.jpg`}
											/>
										</div>
									)}
									{filename?.substring(filename.length - 4).toLowerCase() ==
										'.pdf' && (
										<>
											<a
												href={`${process.env.API_URL}v1/glob/files/${glob_file_id}/document.pdf`}
												target='_blank'
											>
												{filename}
											</a>
											<a
												href={`${process.env.API_URL}v1/glob/files/${glob_file_id}/${filename}.pdf`}
												target='_blank'
												style={{ width: '100%', height: '300px' }}
											>
												<iframe
													title={filename}
													src={`${process.env.API_URL}v1/glob/files/${glob_file_id}/${filename}.pdf`}
													style={{ width: '100%', height: '300px' }}
												></iframe>
											</a>
										</>
									)}
								</div>
							);
						})}
					</Col>
				</Row>
			)}
		</>
	);
}

DetailedViewModal.propTypes = {
	description: PropTypes.string,
	visible_first_name: PropTypes.string,
	visible_last_name: PropTypes.string,
	first_name: PropTypes.string,
	last_name: PropTypes.string,
	country_iso_code: PropTypes.string,
	visible_city: PropTypes.string,
	city: PropTypes.string,
	sex: PropTypes.string,
	avatar_glob_file_id: PropTypes.string,
	vax_damage_occurred: PropTypes.string,
	vax_health_damage_tags: PropTypes.arrayOf(
		PropTypes.string || PropTypes.object
	),
	vax_pre_health_damage_tags: PropTypes.arrayOf(
		PropTypes.string || PropTypes.object
	),
	vax_entry_vaccinations: PropTypes.arrayOf(
		PropTypes.string || PropTypes.object
	),
	theme: PropTypes.object,
	hospitalized: PropTypes.string,
	created_at: PropTypes.string,
	vax_entry_files: PropTypes.arrayOf(PropTypes.object),
	glob_referrer_id: PropTypes.string,
	case_id: PropTypes.string,
	internet_link: PropTypes.string,
	promi: PropTypes.bool,
};

function EntryDetails(props) {
	const [modalShow, setModalShow] = React.useState(false);
	return (
		<div>
			<div onClick={() => setModalShow(true)}>
				<OpenInFull />
			</div>
			<Modal
				{...props}
				size='lg'
				aria-labelledby='contained-modal-title-vcenter'
				centered
				show={modalShow}
				onHide={() => setModalShow(false)}
			>
				<ModalBody>
					<DetailedViewModal {...props} />
				</ModalBody>
			</Modal>
		</div>
	);
}

export default EntryDetails;
