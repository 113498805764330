import PropTypes from 'prop-types';
import { Row, Col, CrossIcon, StarIcon } from 'components/ui';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Badge as BsBadge } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import { isPlaceholderImage } from 'VaxForm_04_InfoZurPerson/components/PersonData.js';

const StyledCity = styled.div`
	border-width: 2px !important;
`;

const CountryImage = styled.img`
	border-radius: 50%;
	width: 20px;
	height: 20px;
	min-width: 20px;
	min-height: 20px;
`;

const Badge = styled(BsBadge)`
	color: ${(props) => props.color} !important;
	background: ${(props) => props.background} !important;
	border: ${(props) => props.border} !important;
`;

const ThemeBadge = styled(Badge)`
	color: ${(props) => props.theme.themePrimary} !important;
`;

export const getAge = (formData, globAgeGroups, t) => {
	let age = formData.age;
	if (age != null) {
		let unit = formData.age_unit;
		if (age > 1) {
			unit += 'e';
		}
		age = `${age}` + ' ' + t('common.' + unit);
	} else if (formData.glob_age_groups_id) {
		if (formData.glob_age_groups_id == 1) {
			return null;
		}
		const ageGroup = globAgeGroups.find((ageGroup) => {
			return ageGroup.glob_age_group_id == formData.glob_age_groups_id;
		});
		if (ageGroup) {
			age = t('master_data.age_group.' + ageGroup.age_group_i18n_key);
		}
	}
	return age;
};

const EntryProfile = (props) => {

	const {
		visible_avatar,
		avatar_glob_file_id,
		city,
		visible_city,
		visible_first_name,
		first_name,
		visible_last_name,
		last_name,
		sex,
		country_iso_code,
		glob_health_result_id,
		promi,
	} = props;

	const globAgeGroups = useSelector((state) => state.masterData.globAgeGroups);
	const avatarID =
		visible_avatar || isPlaceholderImage(avatar_glob_file_id)
			? avatar_glob_file_id
			: 1;
	const avatarLink = `${process.env.API_URL}v1/glob/files/${avatarID}/avatar.png`;
	const [t] = useTranslation();
	const age = getAge(props, globAgeGroups, t);
	const countries = useSelector((state) => state.masterData.countries);

	const extendedCountries = cloneDeep(countries);
	extendedCountries.push({
		country_iso_code: 'DACH',
		country_name_en: 'Unknown - german-speaking area DACH',
		country_name_de: 'Unbekannt - deutschsprachiger Raum DACH',
		standard_language_name_foreign: 'Unbekannt - deutschsprachiger Raum DACH',
		country_name: t('master_data.countries.unknown_dach'),
	});
	extendedCountries.push({
		country_iso_code: 'WORLD',
		country_name_en: 'Unknown - Worldwide',
		country_name_de: 'Unbekannt - Weltweit',
		standard_language_name_foreign: 'Unbekannt - Weltweit',
		country_name: t('master_data.countries.unknown_worldwide'),
	});

	const country_name = extendedCountries.find((country) => {
		return (
			country?.country_iso_code?.toLowerCase() ==
			country_iso_code?.toLowerCase()
		);
	})?.country_name;


	return (
		<>
			<Row>
				<Col className='avatar mb-1 d-flex justify-content-center position-relative'>
					<div className='position-relative'>
						<img
							width='160'
							src={avatarLink}
							style={{ borderRadius: '50%', color: 'grey' }}
							alt={'avatar'}
						/>
						{glob_health_result_id == 6 && (
							<div
								className='position-absolute top-0 start-0'
								title={
									t('common.ausgang_der_impfreaktion') +
									': ' +
									t('master_data.health_result.tod')
								}
							>
								<CrossIcon color='#5E5E5E' />
							</div>
						)}
						{promi && (
							<div
								className='position-absolute bottom-0 end-0'
								style={{ fontSize: '22px' }}
								title={t('impfschaden.erfassen.promi')}
							>
								<StarIcon color='#f39f18' />
							</div>
						)}
					</div>
				</Col>
			</Row>

			{(first_name || last_name) && (
				<Row>
					<Col
						className={
							'd-flex mb-1 justify-content-center'
							// (age == null
							// 	? ' justify-content-end'
							// 	: ' justify-content-center')
						}
					>
						<ThemeBadge
							color='orange'
							background='white'
							border='1px solid lightgray'
							style={{
								fontSize: 14,
								display: 'flex',
								maxWidth: 'calc(100%)',
							}}
						>
							<div
								className='me-1'
								style={{
									fontSize: 14,
									textOverflow: 'ellipsis',
									whiteSpace: 'nowrap',
									overflow: 'hidden',
								}}
							>
								{first_name &&
									(promi
										? first_name
										: !visible_first_name
										? first_name.substr(0, 1) + '.'
										: first_name)}
							</div>
							<div>
								{last_name &&
									(promi
										? last_name
										: !visible_last_name
										? last_name.substr(0, 1) + '.'
										: last_name)}
							</div>
						</ThemeBadge>
					</Col>
				</Row>
			)}

			<Row>
				<Col className='d-flex justify-content-center mb-1'>
					{age != null && (
						<Badge
							color='white'
							background='#45ba49'
							className='me-2'
							style={{ fontSize: 14 }}
						>
							{age}
						</Badge>
					)}
					{sex && sex != 'unknown' && (
						<Badge
							color='white'
							background={sex === 'm' ? 'blue' : '#f93bb0'}
							style={{ fontSize: 14 }}
						>
							{props.detail
								? sex === 'm'
									? t('common.maennlich')
									: t('common.weiblich')
								: sex == 'm'
								? t('common.maennlich_kurz')
								: t('common.weiblich_kurz')}
						</Badge>
					)}
				</Col>
			</Row>
			<Row>
				<Col className='d-flex justify-content-center mb-1'>
					{!props.detail &&
						country_iso_code &&
						country_iso_code != 'unknown' && (
							<CountryImage
								src={'/img/country_flags/' + country_iso_code + '.png'}
								alt={'country-' + country_iso_code}
								className={'overflow-hidden ' + (visible_city ? 'me-2' : '')}
								title={t('common.land') + ': ' + country_name}
							/>
						)}
					{props.detail &&
						country_iso_code &&
						country_iso_code != 'unknown' && (
							<StyledCity
								className={'border pe-1' + (visible_city ? ' me-2' : '')}
								style={{
									borderTopLeftRadius: '20px',
									borderTopRightRadius: '6px',
									borderBottomLeftRadius: '20px',
									borderBottomRightRadius: '6px',
									fontSize: '14px',
									height: '24px',
									lineHeight: 'normal',
								}}
							>
								<CountryImage
									src={'/img/country_flags/' + country_iso_code + '.png'}
									alt={'country-' + country_iso_code}
									className={'overflow-hidden me-1'}
									title={t('common.land') + ': ' + country_name}
								/>
								{country_name}
							</StyledCity>
						)}
					{visible_city == '1' && city && (
						<Badge
							color='white'
							background='#f39f18'
							style={{
								fontSize: 14,
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap',
								overflow: 'hidden',
							}}
							title={city}
						>
							{city}
						</Badge>
					)}
				</Col>
			</Row>
		</>
	);
};

EntryProfile.propTypes = {
	visible_first_name: PropTypes.number,
	visible_last_name: PropTypes.number,
	visible_city: PropTypes.number,
	visible_avatar: PropTypes.number,
	glob_health_result_id: PropTypes.number,
	first_name: PropTypes.string,
	last_name: PropTypes.string,
	country_iso_code: PropTypes.string,
	sex: PropTypes.string,
	city: PropTypes.string,
	vax_entry_id: PropTypes.number,
	avatar_glob_file_id: PropTypes.string,
	vax_health_damage_tags: PropTypes.arrayOf(PropTypes.object),
	description: PropTypes.string,
	vax_damage_occurred: PropTypes.string || PropTypes.number,
	vax_pre_health_damage_tags: PropTypes.arrayOf(PropTypes.object),
	vax_entry_vaccinations: PropTypes.arrayOf(PropTypes.object),
	case_id: PropTypes.string,
	internet_link: PropTypes.string,
	glob_referrer_id: PropTypes.number,
	edit: PropTypes.bool,
	detail: PropTypes.bool,
	promi: PropTypes.bool,
};

export { EntryProfile };
