import EntryCard from 'components/impfschaeden/uebersicht/EntryCard/EntryCard';
import i18n from '../../../../i18n'; // eslint-disable-line
import PropTypes from 'prop-types';

import { Col, Pagination, PaginationItem, Row } from 'components/ui';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const StyledPaginationItem = styled(PaginationItem)`
	&.Mui-selected {
		background-color: ${(props) => props.theme.themePrimary} !important;
		color: white !important;
	}
`;

const PublicEntries = ({ entries, page, fetchPage, autoTranslate, search }) => {
	const [t] = useTranslation();
	return (
		<>
			{entries?.data?.length == 0 && (
				<div>{t('common.keine_impfschaeden_gefunden')}</div>
			)}
			{(entries?.links?.length > 3 || entries?.current_page > 1) && (
				<Row>
					<Col className='d-flex justify-content-center my-3'>
						<Pagination
							page={page}
							count={entries?.last_page}
							boundaryCount={2}
							siblingCount={1}
							onChange={fetchPage}
							renderItem={(item) => (
								<StyledPaginationItem
									components={{
										previous: ArrowBackIcon,
										next: ArrowForwardIcon,
									}}
									{...item}
								/>
							)}
						/>
					</Col>
				</Row>
			)}
			{entries?.data &&
				entries.data.map((entry, index) => {
					if (entry.description !== null && entry.description !== '1') {
						return (
							<Row key={'entry' + index + entry.vax_entry_id}>
								<Col>
									<EntryCard
										{...entry}
										autoTranslate={autoTranslate}
										search={search}
									/>
								</Col>
							</Row>
						);
					}
				})}
			{entries?.links?.length > 3 && (
				<Row>
					<Col className='d-flex justify-content-center mt-3'>
						<Pagination
							page={page}
							count={entries?.last_page}
							onChange={fetchPage}
							renderItem={(item) => (
								<StyledPaginationItem
									components={{
										previous: ArrowBackIcon,
										next: ArrowForwardIcon,
									}}
									{...item}
								/>
							)}
						/>
					</Col>
				</Row>
			)}
		</>
	);
};

PublicEntries.propTypes = {
	page: PropTypes.number,
	entries: PropTypes.object,
	fetchPage: PropTypes.func,
	autoTranslate: PropTypes.bool,
	search: PropTypes.string,
};

export default PublicEntries;
