import { useState, useEffect } from 'react';
import {
	Container,
	Row,
	Col,
	ThemeTitle,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Pagination,
	PaginationItem,
	Paper,
	Button,
	FormHelperText,
} from 'components/ui';
import { useEntriesLazyQuery } from 'api/internal.api.ts';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { StatusBadge } from 'components/nutzerbereich/UserArea/components/StatusBadge';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowDownIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpIcon from '@mui/icons-material/ArrowUpward';
import styled from 'styled-components';
import { EntryDisplaySmall } from 'components/nutzerbereich/UserArea/components/EntryDisplaySmall';
import { useRouter } from 'next/router.js';
import parse from 'html-react-parser';
import dayjs from 'dayjs';
import {
	FormatListBulletedOutlined,
	RecentActorsOutlined,
} from '@mui/icons-material';
import {
	FilterButton,
	FilterSettings,
} from 'components/nutzerbereich/UserArea/components/FilterSettings.js';
import { highlight } from 'lib/utils.js';
import EntryCard from 'components/impfschaeden/uebersicht/EntryCard/EntryCard.js';
import {
	mapPreIllnesTags,
	mapVaxHealthDamageTags,
} from 'components/nutzerbereich/impfschaden/hooks/useEditFormUpdate.js';
import cloneDeep from 'lodash/cloneDeep';
import isArray from 'lodash/isArray';
import useLocalStoredState from 'hooks/useLocalStoredState.js';
import useFilterState from 'components/nutzerbereich/UserArea/hooks/useFilterState.js';
import useRefreshToken from 'hooks/useRefreshToken.js';
import { CircularProgress, LinearProgress } from '@mui/material';

const StyledTable = styled(TableBody)`
	tr:nth-of-type(odd) {
		background-color: #f8f9fa;
	}
`;

const StyledPaginationItem = styled(PaginationItem)`
	&.Mui-selected {
		background-color: ${(props) => props.theme.themePrimary} !important;
		color: white !important;
	}
`;

export const initialModeratorFilter = {
	page: 1,
	sortCol: 'updated_at',
	sortAsc: false,
	search: '',
	statusNotValidated: true,
	statusPublished: false,
	statusDeleted: false,
	teams: 'all',
	referrers: [],
	entries_vax: true,
	newTags: null,
	entryBy: undefined,
	publishedBy: undefined,
	deletedBy: undefined,
};

const UserArea = () => {
	useRefreshToken();
	const moderatorFilter = useSelector(
		(state) => state.settings.moderatorFilter
	);
	const [fetchEntries, { data, isFetching }] = useEntriesLazyQuery();
	const user = useSelector((state) => state.settings.currentUser);
	const [t] = useTranslation();
	const [entries, setEntries] = useState([]);
	const router = useRouter();
	const { state: view, storeState: setView } = useLocalStoredState(
		'list',
		'adminViewMode'
	);
	const { state: page, setFilter: setPage } = useFilterState(1, 'page');
	const { state: search, setFilter: setSearch } = useFilterState('', 'search');
	const { state: sortCol, setFilter: setSortCol } = useFilterState(
		'updated_at',
		'sortCol'
	);
	const { state: sortAsc, setFilter: setSortAsc } = useFilterState(
		false,
		'sortAsc'
	);
	const { state: statusDeleted, setFilter: setStatusDeleted } = useFilterState(
		false,
		'statusDeleted'
	);
	const { state: statusNotValidated, setFilter: setStatusNotValidated } =
		useFilterState(true, 'statusNotValidated');
	const { state: statusPublished, setFilter: setStatusPublished } =
		useFilterState(false, 'statusPublished');

	const { state: newTags, setFilter: setNewTags } = useFilterState(
		null,
		'newTags'
	);
	const initGroup = user?.user_team_id ? [user.user_team_id] : 'mine';
	const { state: teams, setFilter: setTeams } = useFilterState(
		initGroup,
		'teams'
	);

	const { state: referrers, setFilter: setReferrers } = useFilterState(
		[],
		'referrers'
	);

	const toggleOnlyNewTags = () => {
		if (newTags == 'only') {
			setNewTags(null);
		} else {
			setNewTags('only');
		}
	};

	const toggleOnlyNoNewTags = () => {
		if (newTags == 'none') {
			setNewTags(null);
		} else {
			setNewTags('none');
		}
	};

	const toggleDeleted = () => {
		setStatusDeleted(!statusDeleted);
	};

	const toggleNotValidated = () => {
		setStatusNotValidated(!statusNotValidated);
	};

	const togglePublished = () => {
		setStatusPublished(!statusPublished);
	};

	const [publishedEntries, setPublishedEntries] = useState(0);
	const [notValidatedEntries, setNotValidationEntries] = useState(0);
	const [deletedEntries, setDeletedEntries] = useState(0);

	useEffect(() => {
		fetchEntries(moderatorFilter);
	}, [moderatorFilter]);

	useEffect(() => {
		if (!data || !moderatorFilter) {
			return;
		}
		setEntries(data.entries);
		setNotValidationEntries(
			data.info?.find((entry) => {
				return entry.glob_entry_status_id == 1;
			})?.total || 0
		);
		setPublishedEntries(
			data.info?.find((entry) => {
				return entry.glob_entry_status_id == 2;
			})?.total || 0
		);
		setDeletedEntries(
			data.info?.find((entry) => {
				return entry.glob_entry_status_id == 3;
			})?.total || 0
		);
		// if (data.entries?.current_page) {
		// 	setFilter({ page: data.entries.current_page });
		// }
		if (Math.ceil(data.entries?.total / 5) < page) {
			setPage(1, true);
		}
	}, [data]);

	const countFilter = () => {
		let counter = 0;
		if (isArray(teams)) {
			if (teams.indexOf(user.user_team_id.toString()) == -1) {
				counter += teams.length;
			} else {
				counter += teams.length - 1;
			}
		}
		counter += referrers.length;
		if (moderatorFilter.entryBy) {
			counter += 1;
		}
		if (moderatorFilter.publishedBy) {
			counter += 1;
		}
		if (moderatorFilter.deletedBy) {
			counter += 1;
		}

		return counter;
	};

	const fetchPage = (event, value) => {
		setPage(value);
	};

	const updateSort = (value) => {
		if (value == sortCol) {
			setSortAsc(!sortAsc);
		} else {
			setSortCol(value);
			setSortAsc(true);
		}
	};

	const updateSearch = (event) => {
		setSearch(event.target.value);
	};

	const getUserAreaLink = (id) => {
		if (user && !user.is_moderator) {
			return '/nutzerbereich/meine_eintraege/bearbeiten/' + id;
		} else if (user && (user.is_moderator == true || user.is_admin == true)) {
			return '/adminbereich/eintraege/bearbeiten/' + id;
		} else {
			return '/';
		}
	};

	const openEntry = (id) => {
		router.push(getUserAreaLink(id));
	};

	const hasAndGetSearchResultsInDescription = (entry) => {
		if (!search || !entry.description) {
			return false;
		}
		const getSearchString = (index) => {
			const startIndex = Math.max(index - 20, 0);
			const endIndex = Math.min(index + 20, entry.description.length);
			const startEllipses = startIndex !== 0;
			const endEllipses = endIndex !== entry.description.length;
			let searchString = '';
			if (startEllipses) {
				searchString += '...';
			}
			searchString += entry.description.substring(startIndex, endIndex);
			if (endEllipses) {
				searchString += '...';
			}
			searchString = searchString.replaceAll(
				search,
				'<mark>' + search + '</mark>'
			);
			return searchString;
		};
		const searchStrings = [];
		const index = entry?.description.indexOf(search);
		if (index > -1) {
			searchStrings.push(getSearchString(index));
			const secondIndex = entry?.description?.indexOf(
				search,
				index + search.length
			);
			if (secondIndex > -1) {
				if (secondIndex > index + 20) {
					searchStrings.push(getSearchString(secondIndex));
				}
				const thirdIndex = entry?.description?.indexOf(
					search,
					secondIndex + search.length
				);
				if (
					(searchStrings.length > 1 && thirdIndex > secondIndex + 20) ||
					(searchStrings.length == 1 && thirdIndex > index + 20)
				) {
					searchStrings.push(getSearchString(thirdIndex));
				}
			}
			return searchStrings;
		}
		return false;
	};

	// if (!moderatorFilter || !data) {
	// 	return null;
	// }

	let titleText = t('nutzerbereich.eintraege.meine_eintraege');

	const noEntries =
		!(data?.entries?.data?.length > 0) || data?.entries?.data?.length == 0;

	// console.log('UserArea', isFetching);

	return (
		<Container>
			{!user?.is_moderator && (
				<Row>
					<Col>
						<ThemeTitle text={titleText} h={3} />
					</Col>
				</Row>
			)}
			<Row>
				<Col className='align-items-center mb-lg-0 mb-1'>
					<div className='d-flex w-100 justify-content-between align-items-center flex-column'>
						<FilterSettings
							publishedEntries={publishedEntries}
							notValidatedEntries={notValidatedEntries}
							deletedEntries={deletedEntries}
							toggleOnlyNewTags={toggleOnlyNewTags}
							toggleOnlyNoNewTags={toggleOnlyNoNewTags}
							toggleDeleted={toggleDeleted}
							toggleNotValidated={toggleNotValidated}
							togglePublished={togglePublished}
							teams={teams}
							setTeams={setTeams}
							setReferrers={setReferrers}
							referrers={referrers}
							search={search}
							updateSearch={updateSearch}
							countFilter={countFilter}
						/>
					</div>
				</Col>
				{/* <Col lg={2}></Col> */}
			</Row>

			{/* {data?.entries?.links?.length > 3 && ( */}
			<Row className='mt-3'>
				<Col
					lg={3}
					style={{
						visibility: noEntries ? 'hidden' : 'visible',
					}}
				>
					<div className='d-flex w-100 cursor-pointer justify-content-start'>
						<FilterButton
							onClick={() => setView('list')}
							variant='outline-primary'
							className={'py-0 me-1 mt-1' + (view == 'list' ? ' selected' : '')}
						>
							<FormatListBulletedOutlined className='me-2' />
						</FilterButton>
						<FilterButton
							onClick={() => setView('cards')}
							variant='outline-primary'
							className={
								'py-0 me-1 mt-1' + (view == 'cards' ? ' selected' : '')
							}
						>
							<RecentActorsOutlined />
						</FilterButton>
						{isFetching && <CircularProgress />}
					</div>
				</Col>
				<Col className='d-flex align-items-center'>
					<div
						className='d-flex justify-content-center'
						style={{
							visibility:
								data?.entries?.links?.length > 3 ? 'visible' : 'hidden',
						}}
					>
						<Pagination
							page={page}
							count={data?.entries?.last_page}
							onChange={fetchPage}
							renderItem={(item) => (
								<StyledPaginationItem
									components={{
										previous: ArrowBackIcon,
										next: ArrowForwardIcon,
									}}
									{...item}
								/>
							)}
						/>
					</div>
				</Col>
				<Col lg={3} className='d-flex align-items-center'>
					<FormHelperText
						className='float-right mt-2 font-weight-bold w-100 '
						variant='outlined'
						style={{ color: 'steelblue', textAlign: 'right' }}
					>
						{data?.entries?.total} {'/'}
						{data?.total} {t('common.eintraege')}
					</FormHelperText>
				</Col>
			</Row>
			{/* )} */}
			{noEntries && (
				<Row>
					<Col>
						<div className='mt-4'>
							<ThemeTitle
								text={t('nutzerbereich.eintraege.keine_eintraege')}
								h={4}
							/>
						</div>
					</Col>
				</Row>
			)}
			<Row>
				<Col>
					{data?.entries?.data?.length > 0 &&
						view == 'cards' &&
						data?.entries.data.map((item, index) => {
							const tags = mapVaxHealthDamageTags(
								cloneDeep(item),
								t
							).vax_health_damage_tags;
							const preIllness = mapPreIllnesTags(
								cloneDeep(item),
								t
							).vax_pre_health_damage_tags;
							return (
								<div className='mt-3' key={'entry' + index}>
									<EntryCard
										{...item}
										search={search}
										userArea={true}
										vax_health_damage_tags={tags}
										vax_pre_health_damage_tags={preIllness}
									/>
								</div>
							);
						})}
					{data?.entries?.data?.length > 0 && view == 'list' && (
						<TableContainer component={Paper} className=''>
							<Table className='table-striped' style={{ tableLayout: 'fixed' }}>
								<colgroup>
									<col />
									<col className='d-none d-xl-table-cell' />
									<col />
								</colgroup>
								<TableHead>
									<TableRow>
										<TableCell style={{ fontWeight: '600', width: '60%' }}>
											<div
												className='cursor-pointer'
												onClick={() => updateSort('vax_entry_id')}
											>
												{t('nutzerbereich.eintrag')}
												{sortCol == 'vax_entry_id' && !sortAsc && (
													<ArrowUpIcon />
												)}
												{sortCol == 'vax_entry_id' && sortAsc && (
													<ArrowDownIcon />
												)}
											</div>
										</TableCell>
										<TableCell
											className='d-none d-xl-table-cell'
											style={{ fontWeight: '600', width: '20%' }}
										>
											<div
												className='cursor-pointer'
												onClick={() => updateSort('updated_at')}
											>
												{t('nutzerbereich.zuletzt_geaendert')}
												{sortCol == 'updated_at' && !sortAsc && <ArrowUpIcon />}
												{sortCol == 'updated_at' && sortAsc && (
													<ArrowDownIcon />
												)}
											</div>
										</TableCell>
										<TableCell align={'right'} style={{ fontWeight: '600' }}>
											<div
												className='cursor-pointer'
												onClick={() => updateSort('glob_entry_status_id')}
											>
												{t('nutzerbereich.status')}
												{sortCol == 'glob_entry_status_id' && !sortAsc && (
													<ArrowUpIcon />
												)}
												{sortCol == 'glob_entry_status_id' && sortAsc && (
													<ArrowDownIcon />
												)}
											</div>
										</TableCell>
									</TableRow>
								</TableHead>
								<StyledTable>
									{entries?.data &&
										entries.data.map((item, index) => {
											const searchStrings =
												hasAndGetSearchResultsInDescription(item);
											return (
												<TableRow
													key={'entry' + item.vax_entry_id + index}
													onClick={() => openEntry(item.vax_entry_id)}
													className='cursor-pointer'
												>
													<TableCell>
														<EntryDisplaySmall entry={item} search={search} />
														{searchStrings &&
															searchStrings.map((searchString, index) => (
																<div key={'result' + index}>
																	{parse(searchString)}
																</div>
															))}
														{moderatorFilter?.search &&
															item?.case_id?.indexOf(search) > -1 && (
																<div>
																	<span>
																		{t('impfschaeden.uebersicht.id')}{' '}
																	</span>
																	{parse(highlight(search, item.case_id))}
																</div>
															)}
													</TableCell>
													<TableCell className='d-none d-xl-table-cell'>
														{item.updated_at &&
															dayjs(item.updated_at).format('lll')}
													</TableCell>
													<TableCell align={'right'}>
														<StatusBadge status={item.glob_entry_status_id} />
													</TableCell>
												</TableRow>
											);
										})}
								</StyledTable>
							</Table>
						</TableContainer>
					)}
				</Col>
			</Row>
			<Row>
				<Col>
					{data?.entries?.links?.length > 3 && (
						<div className='d-flex justify-content-center mt-3'>
							<Pagination
								page={page}
								count={data?.entries?.last_page}
								onChange={fetchPage}
								renderItem={(item) => (
									<StyledPaginationItem
										components={{
											previous: ArrowBackIcon,
											next: ArrowForwardIcon,
										}}
										{...item}
									/>
								)}
							/>
						</div>
					)}
				</Col>
			</Row>
		</Container>
	);
};

export default UserArea;
