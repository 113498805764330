import PropTypes from 'prop-types';
import { FormCheck } from 'react-bootstrap';

const Switch = (props) => {
	return (
		<FormCheck
			type='switch'
			label={props.label}
			onChange={props.onChange}
			checked={props.checked}
			disabled={props.disabled}
		/>
	);
};

Switch.propTypes = {
	onChange: PropTypes.func,
	label: PropTypes.string,
	checked: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.string,
		PropTypes.number,
	]),
	disabled: PropTypes.bool,
};

export { Switch };
