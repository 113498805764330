import { SingleSelectFilter } from 'components/impfschaeden/uebersicht/components/SingleSelectFilter.js';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const HospitalizedFilter = ({ filter, updateFilter, onBack }) => {
	const [t] = useTranslation();
	const options = ['ja', 'nein'];
	const defaultOption = null;
	return (
		<SingleSelectFilter
			options={options}
			defaultOption={defaultOption}
			updateFilter={updateFilter}
			filter={filter}
			onBack={onBack}
			filterKey='hospitalized'
			title={t('common.krankenhauseinweisung')}
		/>
	);
};

HospitalizedFilter.propTypes = {
	filter: PropTypes.object,
	updateFilter: PropTypes.func,
	onBack: PropTypes.func,
};

export { HospitalizedFilter };
