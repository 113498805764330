import React, { useCallback, useRef, useState } from 'react';
import { Carousel } from 'react-bootstrap';
import { PropTypes } from 'prop-types';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import orderBy from 'lodash/orderBy';
import { useWindowSize } from '@reactuses/core';

const StyledCarousel = styled.div`
	.carousel {
		/* height: 380px; */
		margin: auto;
		position: relative;
		background: black;
		border-radius: 3px;
		padding: 3px 0;
	}

	.carousel-control-prev {
		height: 300px;
	}
	.carousel-control-next {
		height: 300px;
	}

	.carousel .carousel-control-prev-icon {
		visibility: hidden;
		background-color: black;
		opacity: 0.5;
		padding: 3px;
		border-radius: 50%;
		height: 2.5rem;
		width: 2.5rem;
		background-size: 2rem 2rem;
	}
	.carousel .carousel-control-next-icon {
		visibility: hidden;
		background-color: black;
		opacity: 0.5;
		padding: 3px;
		border-radius: 50%;
		height: 2.5rem;
		width: 2.5rem;
		background-size: 2rem 2rem;
	}
	.carousel:hover .carousel-control-prev-icon {
		visibility: visible;
	}
	.carousel:hover .carousel-control-next-icon {
		visibility: visible;
	}
	.carousel-inner {
		height: 100%;
	}
	.carousel-item.active,
	.carousel-item-prev,
	.carousel-item-start {
		display: flex;
	}
	.carousel-item img {
		max-width: 100%;
	}
	.carousel-indicator {
		margin: 0;
		padding-right: 15%;
		padding-left: 15%;
		padding-bottom: 1rem;
	}
	.carousel-indicators {
		bottom: unset;
		top: 0;
		margin-top: 1rem;
	}
`;

const VideoSlideshow = ({ vax_entry_files }) => {
	const [, updateState] = useState();
	const forceUpdate = useCallback(() => updateState({}), []);
	const ref = useRef();
	const [controlsStat, setControlsStat] = useState(false);
	const [playing, setPlaying] = useState(-1);
	const { width } = useWindowSize();

	return (
		<div className='VideoSlideshow'>
			<StyledCarousel
				onMouseEnter={() => {
					setControlsStat(true);
				}}
				onMouseLeave={() => {
					setControlsStat(false);
				}}
			>
				<Carousel
					// interval={playing != -1 ? null : 2000}
					interval={null}
					controls={vax_entry_files?.length > 1}
					onSlide={() => {
						// console.log('OnSlide');
						setPlaying(-1);
						setTimeout(() => {
							setControlsStat(false);
							forceUpdate();

							if (ref.current) {
								// console.log(ref.current.getInternalPlayer());
								ref.current.getInternalPlayer().width = '30px';
								ref.current.getInternalPlayer().controls = false;
								ref.current.getInternalPlayer().controls = true;
							}
						}, 0);
					}}
					onSlid={() => {
						setTimeout(() => {
							setControlsStat(true);
							forceUpdate();

							if (ref.current) {
								// console.log(ref.current.getInternalPlayer());
								ref.current.getInternalPlayer().width = '30px';
								ref.current.getInternalPlayer().controls = false;
								ref.current.getInternalPlayer().controls = true;
							}
						}, 0);
					}}
				>
					{orderBy(vax_entry_files, 'lexical_order').map(
						(data, index, array) => {
							const filename = data.filename || data.file?.filename;
							const isVideo =
							filename?.substring(filename.length - 4).toLowerCase() ==
							'.3gp' ||filename?.substring(filename.length - 4).toLowerCase() ==
									'.mp4' ||
								filename?.substring(filename.length - 4).toLowerCase() ==
									'.webm' ||
								filename?.substring(filename.length - 4).toLowerCase() ==
									'.mov';
							const glob_file_id = data.glob_file_id || data.file?.glob_file_id;
							// console.log('data', data);
							return (
								<Carousel.Item
									key={glob_file_id}
									style={{
										height: '100%',
										width: '100%',
										minHeight:
											width > 700 ? '360px' : width * (9 / 16) - 10 + 'px',
										alignItems: 'center',
									}}
								>
									{isVideo && (
										<ReactPlayer
											height={
												width > 700 ? '360px' : width * (9 / 16) - 10 + 'px'
											}
											width={width > 700 ? '640px' : width - 10 + 'px'}
											// eslint-disable-next-line max-len
											url={`${process.env.API_URL}v1/glob/files/${glob_file_id}/${filename}`}
											pip={true}
											controls={controlsStat}
											playing={playing == index}
											ref={ref}
											onPlay={() => setPlaying(index)}
											onPause={() => setPlaying(-1)}
										/>
									)}
									{/* prettier-ignore */}
									{(filename?.substring(filename.length - 4).toLowerCase() ==
										'.webp' || filename?.substring(filename.length - 4).toLowerCase() ==
										'.jpg' ||
										filename?.substring(filename.length - 5).toLowerCase() ==
											'.jpeg' ||
										filename?.substring(filename.length - 4).toLowerCase() ==
											'.png') && (
										<div
											style={{
												display: 'flex',
												height: '100%',
												width: '100%',
												justifyContent: 'center',
												alignItems: 'center',
											}}
										>
											<img
												// eslint-disable-next-line max-len
												src={`${process.env.API_URL}v1/glob/files/${glob_file_id}/image.jpg`}
												// eslint-disable-next-line max-len
												alt={`${process.env.API_URL}v1/glob/files/${filename}/image.jpg`}
											/>
										</div>
										)}
									{(filename?.substring(filename.length - 4).toLowerCase() ==
									'.pdf') && <a href={`${process.env.API_URL}v1/glob/files/${glob_file_id}/${filename}.pdf`} target="_blank" style={{width: '100%', height: '300px'}}><iframe title={filename} src={`${process.env.API_URL}v1/glob/files/${glob_file_id}/${filename}.pdf`} style={{width: '100%', height: '300px'}}></iframe></a>}
								</Carousel.Item>
							);
						}
					)}
				</Carousel>
			</StyledCarousel>
		</div>
	);
};
VideoSlideshow.propTypes = {
	vax_entry_files: PropTypes.object,
};
export default VideoSlideshow;
