import { ThemeTitle } from 'components/ui/ThemeTitle.js';
import { useEffect } from 'react';
import useAnimatedStartCounter from '../hooks/useAnimatedStartCounter.ts';
import { useTranslation } from 'react-i18next';

export type AnimatedCountProps = {
	count?: Number;
};

const AnimatedCount = (props: AnimatedCountProps) => {
	const [t] = useTranslation();
	const { count } = props;
	const CURRENT_COUNT = 52000;
	const { animatedNumber, setAsyncCount } = useAnimatedStartCounter(CURRENT_COUNT);
	
	useEffect(() => {
		if (count == null || Number.isNaN(count)) {
			return;
		}
		setAsyncCount(count);
	}, [count]);
	
	return (
		<ThemeTitle
				h={1}
				text={
					Math.round(animatedNumber) +
					' ' +
					(Math.round(animatedNumber) != 1
						? t('common.impfschaeden')
						: t('common.impfschaden'))
				}
			/>
	)
};
export { AnimatedCount };