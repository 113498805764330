import { Button, Col, Container, Row } from 'components/ui';
import PublicEntries from 'components/impfschaeden/uebersicht/components/PublicEntries.js';
import { VaxFilterContainer } from 'components/impfschaeden/uebersicht/components/VaxFilterContainer.js';
import { VaxDashboard } from 'components/impfschaeden/uebersicht/components/VaxDashboard.js';
import { useEffect, useState } from 'react';
import { useEntriesLazyQuery } from 'api/vax.api.js';
import { settingsActions } from 'store/settingsSlice.ts';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumb from 'components/layout/Breadcrumb.js';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import qs from 'qs';
import { AnimatedCount } from 'components/impfschaeden/uebersicht/components/AnimatedCount.tsx';
import useVaxHealthDamages from 'components/impfschaeden/uebersicht/hooks/useVaxHealthDamages.ts';
import useVaxTypeCounts from 'components/impfschaeden/uebersicht/hooks/useVaxTypeCounts.ts';
import useVaxManufacturerCounts from 'components/impfschaeden/uebersicht/hooks/useVaxManufacturerCount.ts';
import useCountryCounts from 'components/impfschaeden/uebersicht/hooks/useCountryCounts.ts';
import useLanguageCounts from 'components/impfschaeden/uebersicht/hooks/useLanguageCounts.ts';
import useVaxPreIllnessCounts from 'components/impfschaeden/uebersicht/hooks/useVaxPreIllnessCounts.ts';

export const emptyFilter = {
	sort: 'relevanz',
	sex: null,
	hospitalized: null,
	vaccination: [],
	manufacturer: [],
	vax_damage: [],
	health_result: [],
	age: [],
	zip: null,
	batch_number: null,
	country: [],
	filter_language: [],
	damage_occurred: [],
	admin_date: {
		start: null,
		end: null,
		unknown_day: false,
		unknown_month: false,
		unknown_date: false,
		include_unknown: false,
	},
	search: undefined,
	pre_health_damage: [],
};

export const initialFilter = {};

const VaxOverview = () => {
	useVaxHealthDamages();
	useVaxTypeCounts();
	useVaxManufacturerCounts();
	useCountryCounts();
	useLanguageCounts();
	useVaxPreIllnessCounts();
	const router = useRouter();
	const [t] = useTranslation();
	const [page, setPage] = useState(1);
	const [fetchEntries, { data: entries, isFetching, isLoading }] =
		useEntriesLazyQuery();

	const [sort] = useState({ col: null, asc: true });
	const dispatch = useDispatch();
	const filter = useSelector((state) => state.settings.vaxFilter);
	const language = useSelector((state) => state.settings.language);

	const resetFilter = () => {
		dispatch(settingsActions.setVaxFilter(initialFilter));
		router.replace({
			pathname: router.pathname.replace('[lang]', router.query.lang),
			query: initialFilter,
		});
	};

	useEffect(() => {
		if (router?.query && Object.keys(router.query).length > 0) {
			// console.log(
			// 	'got Query',
			// 	router.query,
			// 	qs.parse(router.asPath.split('?')[1], { arrayFormat: 'indices' })
			// );
			dispatch(
				settingsActions.setVaxFilter(
					qs.parse(router.asPath.split('?')[1], {
						arrayFormat: 'brackets',
					})
				)
			);
		}
	}, [router]);

	const updateFilter = (update) => {
		dispatch(settingsActions.setVaxFilter({ ...filter, ...update }));
		let { lang: removeLang, ...queryWithoutLang } = router.query;
		window.history.replaceState(
			{},
			'',
			'/impfschaden?' +
				qs.stringify(
					{ ...queryWithoutLang, ...{ ...filter, ...update } },
					{ arrayFormat: 'brackets' }
				)
		);
		// router.replace(
		// 	'/impfschaden?' +
		// 		qs.stringify(
		// 			{ ...router.query, ...{ ...filter, ...update } },
		// 			{ arrayFormat: 'comma' }
		// 		),
		// 	null,
		// 	{
		// 		shallow: true,
		// 		scroll: false,
		// 	}
		// );
	};

	const fetchPage = (_event, value) => {
		fetchEntries({
			page: value,
			sort: sort,
			filter: { ...filter, page: value },
			seed: entries?.seed,
		});
		let { lang: removeLang, ...queryWithoutLang } = router.query;
		window.history.pushState(
			{},
			'',
			'/impfschaden?' +
				qs.stringify(
					{ ...queryWithoutLang, ...{ ...filter, page: value } },
					{ arrayFormat: 'brackets' }
				)
		);
	};

	useEffect(() => {
		if (!entries) {
			return;
		}
		// setEntries(data.entries);
		if (Math.ceil(entries.total / entries.per_page) < entries.current_page) {
			setPage(1);
			fetchPage(1);
		} else if (entries?.current_page) {
			setPage(entries.current_page);
		}
	}, [entries]);

	useEffect(() => {
		fetchEntries({
			page: page,
			sort: sort,
			filter: filter,
			seed: entries?.seed,
		});
	}, [filter, language]);

	// console.log('vaxOverview', filter);

	return (
		<>
			<Breadcrumb />
			<VaxDashboard />
			<Container>
				<Row>
					<Col>
						<AnimatedCount count={entries?.total} />
						<VaxFilterContainer
							filter={filter}
							resetFilter={resetFilter}
							updateFilter={updateFilter}
							count={entries?.total}
							isFetching={isFetching || isLoading}
						/>
					</Col>
					<Col lg='3'>
						<Button
							href='/impfschaden/erfassen'
							className='w-100 h-100 d-flex align-items-center justify-content-center'
						>
							<h2>{t('impfschaeden.erfassen.impfschaden_hier_erfassen')}</h2>
						</Button>
					</Col>
				</Row>
			</Container>
			<Container className='mt-3'>
				<PublicEntries
					entries={entries}
					page={page}
					fetchPage={fetchPage}
					search={filter?.search || ''}
				/>
			</Container>
		</>
	);
};

export default VaxOverview;
