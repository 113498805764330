import { useRefreshTokenMutation } from 'api/internal.api.ts';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUser, getJWTData, setJWT } from 'service/user.service.js';
import { settingsActions } from 'store/settingsSlice.ts';

const useRefreshToken = () => {
	const dispatch = useDispatch();
	const currentUser = useSelector((state) => state.settings.currentUser);
	const [refreshToken, { data: newToken, isSuccess: refreshTokenSuccess }] =
		useRefreshTokenMutation();
	useEffect(() => {
		if (!newToken || !refreshTokenSuccess) {
			return;
		}
		setJWT(newToken.token);
		dispatch(settingsActions.setUser(getCurrentUser()));
	}, [newToken]);

	useEffect(() => {
		if (currentUser) {
			refreshToken();
		}
	}, []);
	return;
};

export default useRefreshToken;
