import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBearer } from 'service/user.service';
import qs from 'qs';

export const translationApi = createApi({
	reducerPath: 'translationApi',
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.API_URL + 'v1/translation/',
		prepareHeaders: (headers, api) => {
			headers.set('accept', 'application/json');
			headers.set(
				'X-User-Language',
				api.getState().settings.language?.toLowerCase()
			);
			headers.set('Authorization', 'Bearer ' + getBearer());
			return headers;
		},
	}),
	endpoints: (build) => ({
		getTranslation: build.query({
			query: (payload) => {
				return {
					url: 'data/' + payload,
				};
			},
		}),
		saveTranslation: build.mutation({
			query: (payload) => {
				return {
					url: 'translate',
					method: 'POST',
					body: payload,
				};
			},
		}),
		addTranslation: build.mutation({
			query: (payload) => {
				return {
					url: 'add_translation',
					method: 'POST',
					body: payload,
				};
			},
		}),
		availableLanguages: build.query({
			query: () => {
				return {
					url: 'available_languages',
				};
			},
		}),
		autoTranslateLanguage: build.mutation({
			query: (payload) => {
				return {
					url: 'auto_translate_language',
					method: 'POST',
					body: { autoTranslateLanguage: payload },
				};
			},
		}),
		autoTranslateContent: build.mutation({
			query: (payload) => {
				return {
					url: 'auto_translate_content',
					method: 'POST',
					body: { autoTranslateLanguage: payload },
				};
			},
		}),
	}),
});

export const useGetTranslationLazyQuery =
	translationApi.endpoints.getTranslation.useLazyQuery;

export const {
	useGetTranslationQuery,
	useSaveTranslationMutation,
	useAddTranslationMutation,
	useAvailableLanguagesQuery,
	useAutoTranslateLanguageMutation,
	useAutoTranslateContentMutation,
} = translationApi;
