export type OriginallyPostedDateProps = {
	date: string;
};

const OriginallyPostedDate = (props: OriginallyPostedDateProps) => {
	const { date } = props;
	return (
		<>
			{date && (
				<div className='mt-2'>
					<div className='justify-content-right d-right'>{date}</div>
				</div>
			)}
		</>
	);
};
export { OriginallyPostedDate };
