import PropTypes from 'prop-types';
import { Autocomplete, TextField, Chip, FormHelperText } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { Search } from '@mui/icons-material';
import parse from 'html-react-parser';
import { highlight } from 'lib/utils.js';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const StyledOption = styled.li`
	display: inline-block !important;
	background-color: #f8f9fa !important;
	&:hover {
		background-color: #e9ecef !important;
	}
`;


const StyledWarningOption = styled.li`
	display: inline-block !important;
	background-color: orange !important;
	&:hover {
		background-color: darkorange !important;
	}
`;

const TagInput = (props) => {
	const placeholder = props.placeholder || props.label || '';
	const displayProperty = props.displayProperty || 'name';

	const getOptionLabel = (option) => {
		// Value selected with enter, right from the input
		if (typeof option === 'string') {
			return option;
		}
		// Add "xxx" option created dynamically
		if (option.inputValue) {
			return option.inputValue;
		}
		// Regular option
		return option[displayProperty] || '';
	};

	let multiple = true;
	if (props.multiple === false) {
		multiple = false;
	}

	return (
		<div className='w-100 position-relative'>
			<Autocomplete
				className={props.className + ' position-relative'}
				multiple={multiple}
				options={props.options}
				freeSolo
				onKeyDown={props.onKeyDown}
				onInputChange={props.onInputChange}
				value={props.value}
				onChange={props.onChange}
				renderTags={(value, getTagProps) => {
					return value.map((option, index) => {
						return (
							<Chip
								key={`chip-${index}-${option.name}`}
								variant='outlined'
								color={
									option.lang_iso_code == 'translation' && option.verified == 1
										? 'default'
										: 'warning'
								}
								label={option.name}
								{...getTagProps({ index })}
							/>
						);
					});
				}}
				getOptionLabel={getOptionLabel}
				renderOption={(props, option, { inputValue }) => {
					return (
						<>
							{option.verified == 1 && (
								<StyledOption {...props}>
									{parse(highlight(inputValue, getOptionLabel(option)))}
								</StyledOption>
							)}
							{option.verified != 1 && (
								<StyledWarningOption {...props}>
									{parse(highlight(inputValue, getOptionLabel(option)))}
								</StyledWarningOption>
							)}
						</>
					);
				}}
				renderInput={(params) => {
					// params.InputProps.startAdornment = (
					// 	<InputAdornment position='start'>
					// 		<Search />
					// 	</InputAdornment>
					// );
					return (
						<TextField
							error={props.isInvalid}
							{...params}
							InputProps={{
								...params.InputProps,
								startAdornment: (
									<>
										<InputAdornment position='start'>
											<Search />
										</InputAdornment>
										{params.InputProps.startAdornment}
									</>
								),
							}}
							variant='outlined'
							placeholder={placeholder}
							label={props.label}
						/>
					);
				}}
			/>
			{props.isInvalid && (
				<FormHelperText
					style={{
						color: '#dc3545',
						position: 'absolute',
						right: 5,
						bottom: 0,
					}}
				>
					{props.isInvalid}
				</FormHelperText>
			)}
		</div>
	);
};

TagInput.propTypes = {
	label: PropTypes.string.isRequired,
	displayProperty: PropTypes.string,
	options: PropTypes.arrayOf(
		PropTypes.oneOfType([PropTypes.string, PropTypes.object])
	).isRequired,
	value: PropTypes.arrayOf(
		PropTypes.oneOfType([PropTypes.string, PropTypes.object])
	).isRequired,
	onChange: PropTypes.func.isRequired,
	onInputChange: PropTypes.func,
	onKeyDown: PropTypes.func,
	placeholder: PropTypes.func,
	isInvalid: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	multiple: PropTypes.bool,
	className: PropTypes.string,
};

const TagInputNoNew = (props) => {
	const placeholder = props.placeholder || props.label || '';
	const displayProperty = props.displayProperty || 'name';
	const [t] = useTranslation();

	const getOptionLabel = (option) => {
		// Value selected with enter, right from the input
		if (typeof option === 'string') {
			return option;
		}
		// Add "xxx" option created dynamically
		if (option.inputValue) {
			return option.inputValue;
		}
		// Regular option
		return option[displayProperty] || '';
	};
	return (
		<div className='w-100 position-relative'>
			<Autocomplete
				disabled={props.disabled}
				multiple
				options={props.options}
				noOptionsText={t('common.keine_optionen')}
				// freeSolo
				// onKeyDown={props.onKeyDown}
				// onInputChange={props.onInputChange}
				value={props.value}
				onChange={props.onChange}
				renderTags={(value, getTagProps) => {
					return value.map((option, index) => {
						return (
							<Chip
								variant='outlined'
								sx={{
									'&:nth-of-type(2)': {
										maxWidth: 'calc(100% - 38px)',
									},
									minHeight: '32px',
									height: 'unset',
									'& .MuiChip-label': {
										whiteSpace: 'normal',
									},
								}}
								color={
									option.lang_iso_code == 'translation' && option.verified == 1
										? 'default'
										: 'warning'
								}
								title={option.name}
								label={option.name}
								{...getTagProps({ index })}
								key={`chip-${index}-${option.name}`}
							/>
						);
					});
				}}
				getOptionLabel={getOptionLabel}
				renderOption={(props, option, { inputValue }) => {
					return (
						<>
							<StyledOption {...props}>
								{parse(highlight(inputValue, getOptionLabel(option)))}
							</StyledOption>{' '}
						</>
					);
				}}
				renderInput={(params) => {
					// params.InputProps.startAdornment = (
					// 	<InputAdornment position='start'>
					// 		<Search />
					// 	</InputAdornment>
					// );
					return (
						<TextField
							error={props.isInvalid}
							{...params}
							InputProps={{
								...params.InputProps,
								startAdornment: (
									<>
										<InputAdornment position='start'>
											<Search />
										</InputAdornment>
										{params.InputProps.startAdornment}
									</>
								),
							}}
							variant='outlined'
							placeholder={placeholder}
							label={props.label}
						/>
					);
				}}
			/>
			{props.isInvalid && (
				<FormHelperText
					style={{
						color: '#dc3545',
						position: 'absolute',
						right: 5,
						bottom: 0,
					}}
				>
					{props.isInvalid}
				</FormHelperText>
			)}
		</div>
	);
};

TagInputNoNew.propTypes = {
	label: PropTypes.string.isRequired,
	displayProperty: PropTypes.string,
	options: PropTypes.arrayOf(
		PropTypes.oneOfType([PropTypes.string, PropTypes.object])
	).isRequired,
	value: PropTypes.arrayOf(
		PropTypes.oneOfType([PropTypes.string, PropTypes.object])
	).isRequired,
	onChange: PropTypes.func.isRequired,
	onInputChange: PropTypes.func,
	onKeyDown: PropTypes.func,
	placeholder: PropTypes.func,
	disabled: PropTypes.bool,
	isInvalid: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export { TagInput, TagInputNoNew };
