import { createSlice } from '@reduxjs/toolkit';

const errorSlice = createSlice({
	name: 'error',
	initialState: [],
	reducers: {
		addError(state, { payload }) {
			state.push(payload);
		},
		removeAllErrors() {
			return [];
		},
	},
});

export const errorActions = errorSlice.actions;

export default errorSlice.reducer;
