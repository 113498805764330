import { VaxFilterSidebar } from 'components/impfschaeden/uebersicht/components/VaxFilterSidebar.js';
import { initialFilter } from 'components/impfschaeden/uebersicht/VaxOverview.js';
import { Badge, Button, Input, Modal } from 'components/ui';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Sliders } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import isEqual from 'lodash/isEqual';
import { InputAdornment, CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';

export const StyledModal = styled(Modal)`
	z-index: 1600;
	transform: rotate(0rad);
	> div.modal-dialog {
		background-color: white;
		margin-top: 0;
		width: 100vw !important;
		> div.modal-content {
			border: none;
			border-radius: 0;
		}
	}
`;

const FilterButton = styled(Button)`
	height: 40px;
	/* margin-top: 0 !important; */
	border-radius: 4px !important;
	&:hover {
		color: white !important;
	}
`;

const VaxFilterContainer = ({
	filter,
	updateFilter,
	count,
	resetFilter,
	isFetching,
}) => {
	
	const [section, setSection] = useState(null);
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const [t] = useTranslation();
	const [search, setSearch] = useState(filter.search || '');
	const [timeoutHandle, setTimeoutHandle] = useState();
	const language = useSelector((state) => state.settings.language);

	const open = (filterKey) => {
		setSection(filterKey);
		handleShow();
	};

	const updateSearch = (e) => {
		setSearch(e.target.value);
		clearTimeout(timeoutHandle);
		setTimeoutHandle(
			setTimeout(() => {
				updateFilter({ search: e.target.value, language });
			}, 1000)
		);
	};

	return (
		<div>
			<div className='d-flex flex-wrap align-items-center'>
				<FilterButton
					variant='outline-primary'
					className='py-0 me-1 mt-1'
					onClick={() => open('sort')}
				>
					<span className='me-2'>{t('common.sortieren_nach')}</span>
					{Object.prototype.hasOwnProperty.call(filter, 'sort') &&
						filter?.sort !== null && (
							<Badge color='primary' variant='dot' className='mt-n1' />
						)}
				</FilterButton>
				<FilterButton
					variant='outline-primary'
					className='py-0 me-1 mt-1'
					onClick={() => open('vax_damage')}
				>
					<span className='me-3'>{t('common.impfschaden')}</span>
					{filter?.vax_damage?.length > 0 && (
						<Badge
							color='primary'
							badgeContent={filter.vax_damage.length}
							className='mt-n1'
						/>
					)}
				</FilterButton>
				<FilterButton
					variant='outline-primary'
					className='py-0 me-1 mt-1'
					onClick={() => open('vaccination')}
				>
					<span className='me-3'>{t('common.impfung')}</span>
					{filter?.vaccination?.length > 0 && (
						<Badge
							color='primary'
							badgeContent={filter.vaccination.length}
							className='mt-n1'
						/>
					)}
				</FilterButton>
				<FilterButton
					variant='outline-primary'
					className='py-0 me-1 mt-1'
					onClick={() => open('manufacturer')}
				>
					<span className='me-3'>{t('common.hersteller')}</span>
					{filter?.manufacturer?.length > 0 && (
						<Badge
							color='primary'
							badgeContent={filter.manufacturer.length}
							className='mt-n1'
						/>
					)}
				</FilterButton>
				<FilterButton
					variant='outline-primary'
					className='py-0 me-1 mt-1'
					onClick={() => open('zip')}
				>
					<span className='me-2'>{t('common.ort')}</span>
					{Object.prototype.hasOwnProperty.call(filter, 'zip') &&
						filter?.zip !== '' && (
							<Badge color='primary' variant='dot' className='mt-n1' />
						)}
				</FilterButton>

				<FilterButton
					variant='outline-primary'
					className='py-0 me-1 mt-1'
					onClick={() => open('country')}
				>
					<span className='me-3'>{t('common.land')}</span>
					{filter?.country?.length > 0 && (
						<Badge
							color='primary'
							badgeContent={filter.country.length}
							className='mt-n1'
						/>
					)}
				</FilterButton>
				<FilterButton
					variant='outline-primary'
					className='py-0 me-1 mt-1'
					onClick={() => open('filter_language')}
				>
					<span className='me-3'>{t('common.sprache')}</span>
					{filter?.filter_language?.length > 0 && (
						<Badge
							color='primary'
							badgeContent={filter.filter_language.length}
							className='mt-n1'
						/>
					)}
				</FilterButton>
				<FilterButton
					variant='outline-primary'
					className='py-0 me-1 mt-1'
					onClick={() => open('age')}
				>
					<span className='me-3'>{t('common.alter')}</span>
					{filter?.age?.length > 0 && (
						<Badge
							color='primary'
							badgeContent={filter.age.length}
							className='mt-n1'
						/>
					)}
				</FilterButton>
				<FilterButton
					variant='outline-primary'
					className='py-0 me-1 mt-1'
					onClick={() => open('batch_number')}
				>
					<span className='me-2'>{t('common.chargennummer')}</span>
					{Object.prototype.hasOwnProperty.call(filter, 'batch_number') &&
						filter?.batch_number !== '' && (
							<Badge color='primary' variant='dot' className='mt-n1' />
						)}
				</FilterButton>
				<div className='p-1 ps-0 me-1 mt-1'>
					<Input
						InputProps={{
							endAdornment: isFetching ? (
								<InputAdornment position='start' sx={{ padding: '5px' }}>
									<CircularProgress size={20} />
								</InputAdornment>
							) : null,
						}}
						variant='outline-primary'
						value={search || ''}
						onChange={updateSearch}
						label={t('common.volltextsuche')}
					/>
				</div>
				<FilterButton
					size='small'
					variant='outline-primary'
					className='py-0 me-1 mt-1'
					onClick={() => open(null)}
				>
					<Sliders className='mt-n1 me-2' />
					<span>{t('common.mehr_filter')}</span>
				</FilterButton>
				<FilterButton
					variant='outline-primary'
					className={
						'py-0 me-1 mt-1' +
						(isEqual(filter, initialFilter) ? ' opacity-0 disabled' : '')
					}
					onClick={resetFilter}
				>
					{t('common.alle_filter_zuruecksetzen')}
				</FilterButton>
				{isFetching && <CircularProgress size={20} />}
			</div>
			<StyledModal
				show={show}
				onHide={handleClose}
				dialogClassName='position-absolute vh-100 end-0'
			>
				<VaxFilterSidebar
					filter={filter}
					updateFilter={updateFilter}
					resetFilter={resetFilter}
					section={section}
					setSection={setSection}
					handleClose={handleClose}
					count={count}
				/>
			</StyledModal>
		</div>
	);
};

VaxFilterContainer.propTypes = {
	filter: PropTypes.object,
	updateFilter: PropTypes.func,
	resetFilter: PropTypes.func,
	count: PropTypes.number,
	isFetching: PropTypes.bool,
};

export { VaxFilterContainer };
