import { SearchableFilter } from 'components/impfschaeden/uebersicht/components/SearchableFilter.js';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import pull from 'lodash/pull';
import { getChildrenIds } from 'components/impfschaeden/uebersicht/components/VaxDamageFilter.js';
import { useSelector } from 'react-redux';

const PreHealthDamageFilter = ({
	filter,
	updateFilter,
	onBack,
	handleClose,
}) => {
	const [t] = useTranslation();
	const [options, setOptions] = useState([]);
	const globHealthDamages = useSelector(
		(state) => state.masterData.vaxPreIllnessCounts
	);
	const [selectChilds, setSelectChilds] = useState(true);

	const updateSelectChilds = () => {
		setSelectChilds(!selectChilds);
	};

	useEffect(() => {
		if (globHealthDamages?.length > 0) {
			setOptions(
				globHealthDamages.map((health_damage) => {
					return {
						name: t(
							'master_data.health_damage.' +
								health_damage.health_damage_i18n_key
						),
						key: health_damage.health_damage_i18n_key,
						id: health_damage.glob_health_damage_id,
						count: health_damage.vax_pre_illnesses_count,
					};
				})
			);
		}
	}, [globHealthDamages]);

	const onSelect = (tag) => {
		const remove =
			filter?.pre_health_damage?.indexOf(tag.glob_health_damage_id) > -1 ||
			filter?.pre_health_damage?.indexOf(tag.glob_health_damage_id.toString()) >
				-1;
		const currentPreHealthDamages = filter.pre_health_damage || [];
		let selectedIds = [];
		if (!selectChilds) {
			selectedIds = [tag.glob_health_damage_id];
			// updateFilter({
			// 	pre_health_damage: [...currentPreHealthDamages, tag.glob_health_damage_id],
			// });
		} else {
			selectedIds = [
				tag.glob_health_damage_id,
				...getChildrenIds(tag, globHealthDamages),
			];
		}
		selectedIds = selectedIds.map((id) => {
			return id.toString();
		});
		// console.log('onSelect', tag, remove, currentPreHealthDamages, selectedIds);
		if (remove) {
			let copy = [...currentPreHealthDamages];
			pull(copy, ...selectedIds);
			updateFilter({
				pre_health_damage: copy,
			});
		} else {
			updateFilter({
				pre_health_damage: [...currentPreHealthDamages, ...selectedIds],
			});
		}
	};

	const defaultOption = null;
	return (
		<SearchableFilter
			selected={filter.pre_health_damage || []}
			selectChilds={selectChilds}
			setSelectChilds={updateSelectChilds}
			onSelect={onSelect}
			options={options}
			defaultOption={defaultOption}
			updateFilter={updateFilter}
			filter={filter}
			onBack={onBack}
			filterKey='pre_health_damage'
			title={t('common.vorerkrankung')}
			placeholder={t('common.vorerkrankung_suchen')}
			handleClose={handleClose}
			skipTranslation={true}
			display={(option, rawOption) => {
				return '' + rawOption.count + ' ' + t('common.faelle');
			}}
		/>
	);
};

PreHealthDamageFilter.propTypes = {
	filter: PropTypes.object,
	updateFilter: PropTypes.func,
	onBack: PropTypes.func,
	handleClose: PropTypes.func,
};

export { PreHealthDamageFilter };
