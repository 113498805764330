import Cookies from 'js-cookie';
var jwt = require('jsonwebtoken');

export function getCurrentUser() {
	const token = Cookies.get('auth_token');
	const user = jwt.decode(token);
	if (!verify(user)) {
		return null;
	}
	return user?.user;
}

export function getJWTData() {
	const token = Cookies.get('auth_token');
	const user = jwt.decode(token);
	if (!verify(user)) {
		return null;
	}
	return user;
}

function getBrowserLang() {
	return navigator.language.split('-')[0];
}

function verify(user) {
	if (!user) return false;
	return new Date().getTime() / 1000 < user.exp;
}

export function isLoggedIn() {
	const token = Cookies.get('auth_token');
	const payload = jwt.decode(token);
	return verify(payload);
}

export function getBearer() {
	return Cookies.get('auth_token');
}

export function setJWT(token) {
	Cookies.set('auth_token', token);
}
