/* eslint-disable no-mixed-spaces-and-tabs */
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
	Button,
	Card,
	Col,
	Row,
	Stack,
	FormGroup,
	FormText,
	Form,
	SearchSelect,
	TextField,
	Input,
	DropdownSelect,
	FormHelperText,
	Modal,
} from 'components/ui';
import { useSelector } from 'react-redux';
import { getLastYearsList } from 'lib/utils';
import { Add, InfoOutlined } from '@mui/icons-material';
import indexOf from 'lodash/indexOf';
import { useState } from 'react';

const ImpfungenForm = ({
	removeVaccination,
	formData,
	validation,
	onChangeManufacturer,
	editVaccination,
	addVaccination,
}) => {
	const [t] = useTranslation();
	const { vaxManufacturers, vaxTypes } = useSelector(
		(state) => state.masterData
	);
	const [showInfo, setShowInfo] = useState(false);

	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	// useEffect(() => {
	// 	if (formData?.vax_entry_vaccinations?.length > 0) {
	// 		const age = formData.vax_entry_vaccinations[0].age;
	// 		if (parseInt(age, 10).toString() == age) {
	// 			if (parseInt(age, 10) == 1) {
	// 				setAgeUnitOptions(singularAgeUnitOptions);
	// 				setAgeLabel(
	// 					t('common.' + formData.vax_entry_vaccinations[0].age_unit)
	// 				);
	// 			} else if (parseInt(age, 10) > 1) {
	// 				setAgeUnitOptions(pluralAgeUnitOptions);
	// 				setAgeLabel(
	// 					t('common.' + formData.vax_entry_vaccinations[0].age_unit + 'e')
	// 				);
	// 			}
	// 		}
	// 	}
	// }, [formData]);

	const getTitle = (index) => {
		return `${index + 1}. ` + t('impfschaeden.erfassen.impfung');
	};

	const getVaccineValue = (index) => {
		if (formData.vax_entry_vaccinations[index]['vax_type_id']) {
			const verifiedVaxType = vaxTypes.find((vaxType) => {
				return (
					formData.vax_entry_vaccinations[index]['vax_type_id'] ==
					vaxType.vax_type_id
				);
			});
			if (verifiedVaxType) {
				return verifiedVaxType;
			}
			const vaccination = formData.vax_entry_vaccinations[index];
			if (typeof vaccination?.vax_type_id == 'string') {
				return {
					vax_type_i18n_key: vaccination?.vax_type_id,
				};
			} else if (vaccination?.vax_type?.vax_type_i18n_key) {
				return vaccination?.vax_type?.vax_type_i18n_key;
			}
		} else {
			return '';
		}
	};

	const getManufacturerValue = (index) => {
		if (formData.vax_entry_vaccinations[index]['vax_manufacturer_id']) {
			const verifiedVaxManufacturer = vaxManufacturers.find(
				(vaxManufacturer) => {
					return (
						formData.vax_entry_vaccinations[index]['vax_manufacturer_id'] ==
						vaxManufacturer.vax_manufacturer_id
					);
				}
			);
			if (verifiedVaxManufacturer) {
				return verifiedVaxManufacturer;
			}
			const vaccination = formData.vax_entry_vaccinations[index];
			if (vaccination?.vax_manufacturer?.manufacturer_i18n_key) {
				return vaccination?.vax_manufacturer?.manufacturer_i18n_key;
			} else {
				return {
					manufacturer_i18n_key: vaccination?.vax_manufacturer_id,
				};
			}
		} else {
			return '';
		}
	};
	const years = getLastYearsList(100);
	const yearOptions = years.map((year) => ({
		value: year,
		text: year,
	}));
	yearOptions.unshift({ value: '0000', text: t('common.unbekannt') });
	yearOptions.unshift({ value: '----', text: t('common.bitte_waehlen') });
	const months = Array.from(new Array(12), (val, index) =>
		(index + 1).toString().padStart(2, '0')
	);
	const monthOptions = months.map((month) => ({
		value: month,
		text: month,
	}));
	monthOptions.unshift({ value: '00', text: t('common.unbekannt') });
	monthOptions.unshift({ value: '--', text: '----' });
	const days = Array.from(new Array(31), (val, index) =>
		(index + 1).toString().padStart(2, '0')
	);
	const dayOptions = days.map((day) => ({
		value: day,
		text: day,
	}));
	dayOptions.unshift({ value: '00', text: t('common.unbekannt') });
	dayOptions.unshift({ value: '--', text: '----' });

	return (
		<>
			{formData?.vax_entry_vaccinations &&
				formData?.vax_entry_vaccinations.map(
					(vaccination, index, vaccinations) => {
						let manufacturerOptions = vaxManufacturers;
						if (vaccination.vax_type_id == '35') {
							manufacturerOptions = vaxManufacturers.filter((manufacturer) => {
								const covidManufacturerIds = [
									1, 4, 10, 12, 16, 27, 23, 7, 30, 22, 52,
								];
								if (
									indexOf(
										covidManufacturerIds,
										manufacturer.vax_manufacturer_id
									) > -1
								) {
									return true;
								}
								return false;
							});
						}

						return (
							<FormGroup
								className={`mb-3${
									vaccinations.length > 1 ? ' multiple-containers' : ''
								}`}
								style={{ marginTop: '10px' }}
								key={index}
							>
								<div
									key={'' + vaccination.vax_type_id + index}
									style={{ marginBottom: '50px' }}
								>
									<Row className='mb-1'>
										<Col xs={10} md={10}>
											<Card.Title>{getTitle(index)}</Card.Title>
										</Col>
										<Col className='d-flex justify-content-end'>
											{index > 0 && (
												<Button
													variant='danger'
													onClick={() => removeVaccination(index)}
												>
													{t('impfschaeden.erfassen.entfernen')}
												</Button>
											)}
										</Col>
									</Row>
									<Row>
										<Col xs={12} md={6}>
											<Stack direction='vertical' gap={3} spacing={2}>
												<SearchSelect
													options={vaxTypes}
													displayProperty='vax_type_i18n_key'
													value={getVaccineValue(index)}
													label={t('impfschaeden.erfassen.impfung_auswaehlen')}
													onChange={(newValue) => {
														if (typeof newValue === 'string') {
															const existing = vaxTypes.find((vaxType) => {
																return vaxType.vax_type_i18n_key === newValue;
															});
															if (existing) {
																editVaccination(index, {
																	vax_type_id: existing.vax_type_id,
																});
															} else {
																editVaccination(index, {
																	vax_type_id: newValue,
																});
															}
														}
													}}
													isInvalid={
														validation?.vax_entry_vaccinations?.length >
															index &&
														validation.vax_entry_vaccinations[index] &&
														validation.vax_entry_vaccinations[index][
															'vax_type_id'
														]
													}
												/>
												{vaccination.vax_type_id == 41 && (
													<Input
														type='text'
														placeholder={
															t(
																'impfschaeden.erfassen.welche_impfung_konkret'
															) + ' *'
														} // eslint-disable-line
														label={
															t(
																'impfschaeden.erfassen.welche_impfung_konkret'
															) + ' *'
														} // eslint-disable-line
														value={vaccination.customType}
														onChange={(e) =>
															editVaccination(index, {
																customType: e.target.value,
															})
														}
													/>
												)}
												<div>
													<div>{t('common.chargennummer')}</div>
													<div className='d-flex'>
														<FormHelperText>
															{t(
																'impfschaeden.erfassen.chargen_nummer_hinweis'
															)}
														</FormHelperText>
														<InfoOutlined
															color='info'
															onClick={() => setShowInfo(!showInfo)}
															className='cursor-pointer mb-1 ms-1'
														/>
													</div>
													{showInfo && (
														<>
															<img
																className='w-100 cursor-pointer'
																onClick={handleShow}
																src='/img/batch_number.png'
																alt={t(
																	'impfschaeden.erfassen.chargen_nummer_hinweis'
																)}
															/>
															<Modal
																show={show}
																onHide={handleClose}
																centered
																size='lg'
															>
																<Modal.Header closeButton></Modal.Header>
																<Modal.Body>
																	<img
																		className='w-100 cursor-pointer'
																		src='/img/batch_number.png'
																		alt={t(
																			// eslint-disable-next-line max-len
																			'impfschaeden.erfassen.chargen_nummer_hinweis'
																		)}
																	/>
																</Modal.Body>
															</Modal>
														</>
													)}
													<TextField
														label={t(
															'impfschaeden.erfassen.impfstoff_chargen_nummer'
														)}
														size='small'
														className='w-100 mt-2'
														value={vaccination.batch_number}
														onChange={(e) =>
															editVaccination(index, {
																batch_number: e.target.value,
															})
														}
													/>
												</div>
											</Stack>
										</Col>
										{vaccination.vax_type_id !== '' &&
											vaccination.vax_type_id !== '0' && (
												<Col xs={12} md={6} className='pt-4 pt-md-0'>
													<Stack direction='vertical' gap={3} spacing={2}>
														<SearchSelect
															options={manufacturerOptions}
															displayProperty='manufacturer_i18n_key'
															value={getManufacturerValue(index)}
															label={t(
																'impfschaeden.erfassen.hersteller_waehlen'
															)}
															onChange={(newValue) =>
																onChangeManufacturer(newValue, index)
															}
															isInvalid={
																validation?.vax_entry_vaccinations?.length >
																	index &&
																validation.vax_entry_vaccinations[index] &&
																validation.vax_entry_vaccinations[index][
																	'vax_manufacturer_id'
																]
															}
														/>
														{(vaccination.vax_manufacturer_id == 4 ||
															vaccination.vax_manufacturer_id ==
																t(
																	'impfschaeden.erfassen.hersteller_fehlt'
																)) && (
															<Input
																type='text'
																label={
																	t(
																		'impfschaeden.erfassen.welcher_hersteller' // eslint-disable-line
																	) + ' *'
																}
																value={vaccination.customManufacturer}
																onChange={(e) =>
																	editVaccination(index, {
																		customManufacturer: e.target.value,
																	})
																}
															/>
														)}
													</Stack>
												</Col>
											)}
									</Row>
									<Row style={{ marginTop: '30px' }}>
										<Col xs={12} md={6}>
											<Stack direction='vertical' gap={3}>
												{formData.anotherPerson
													? t(
															'impfschaeden.erfassen.datum_der_impfung_soweit_bekannt'
													  )
													: t('impfschaeden.erfassen.datum_der_impfung')}
												<div
													style={{
														display: 'flex',
														justifyContent: 'space-between',
														alignItems: 'start',
													}}
												>
													<Form.Group>
														<Form.Label>{t('common.jahr')}*</Form.Label>
														<DropdownSelect
															onChange={(e) => {
																editVaccination(index, {
																	admin_year: e.target.value,
																});
															}}
															value={vaccination.admin_year}
															isInvalid={
																validation.vax_entry_vaccinations &&
																validation.vax_entry_vaccinations[index] &&
																validation.vax_entry_vaccinations[index] &&
																validation.vax_entry_vaccinations[index][
																	'admin_year'
																]
															}
															options={yearOptions}
														/>
													</Form.Group>
													<Form.Group
														style={{
															visibility:
																vaccination.admin_year > 0
																	? 'visible'
																	: 'hidden',
														}}
													>
														<DropdownSelect
															label={t('common.monat') + ' *'}
															onChange={(e) => {
																if (e.target.value == '00') {
																	editVaccination(index, {
																		admin_day: '00',
																		admin_month: e.target.value,
																	});
																} else {
																	editVaccination(index, {
																		admin_month: e.target.value,
																	});
																}
															}}
															value={vaccination.admin_month}
															isInvalid={
																validation.vax_entry_vaccinations &&
																validation.vax_entry_vaccinations[index] &&
																validation.vax_entry_vaccinations[index][
																	'admin_month'
																]
															}
															options={monthOptions}
														/>
													</Form.Group>
													<Form.Group
														style={{
															visibility:
																vaccination.admin_month > 0 &&
																vaccination.admin_year > 0
																	? 'visible'
																	: 'hidden',
														}}
													>
														<DropdownSelect
															label={t('common.tag') + ' *'}
															onChange={(e) => {
																editVaccination(index, {
																	admin_day: e.target.value,
																});
															}}
															value={vaccination.admin_day}
															isInvalid={
																validation.vax_entry_vaccinations &&
																validation.vax_entry_vaccinations[index] &&
																validation.vax_entry_vaccinations[index][
																	'admin_day'
																]
															}
															options={dayOptions}
														/>
													</Form.Group>
												</div>
											</Stack>
										</Col>
									</Row>
								</div>
							</FormGroup>
						);
					}
				)}
			<Row xs={12} style={{ marginTop: '20px' }}>
				<Col xs={12}>
					<FormText style={{ fontWeight: 'bold' }}>
						{t('impfschaeden.erfassen.weitere_impfung_im_Zusammenhang')}
					</FormText>
				</Col>
			</Row>
			<Row xs={12} style={{ marginTop: '10px' }}>
				<Col xs={12} md={6}>
					<Stack direction='horizontal' gap={3} spacing={2}>
						<Button
							onClick={() => addVaccination()}
							className='d-flex'
							variant='success'
						>
							<Add />
							{`${formData?.vax_entry_vaccinations.length + 1}. ` +
								t('impfschaeden.erfassen.impfung_hinzufuegen')}
						</Button>
					</Stack>
				</Col>
				{formData?.vax_entry_vaccinations[
					formData?.vax_entry_vaccinations.length - 1
				].vax_type_id == '35' && (
					<Col xs={12} md={6}>
						<Stack direction='horizontal' gap={3} spacing={2}>
							<Button
								onClick={() => {
									addVaccination(
										formData?.vax_entry_vaccinations[
											formData?.vax_entry_vaccinations.length - 1
										]
									);
								}}
								className='d-flex'
								variant='success'
							>
								<Add />
								{`${formData?.vax_entry_vaccinations.length + 1}. ` +
									t('impfschaeden.erfassen.covid_impfung_hinzufuegen')}
							</Button>
						</Stack>
					</Col>
				)}
			</Row>
		</>
	);
};

ImpfungenForm.propTypes = {
	addVaccination: PropTypes.func,
	editVaccination: PropTypes.func,
	onChangeManufacturer: PropTypes.func,
	removeVaccination: PropTypes.func,
	formData: PropTypes.object,
	validation: PropTypes.object,
};

export { ImpfungenForm };
