import { SingleSelectFilter } from 'components/impfschaeden/uebersicht/components/SingleSelectFilter.js';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const SexFilter = ({ filter, updateFilter, onBack }) => {
	const [t] = useTranslation();
	const options = ['maennlich', 'weiblich'];
	const defaultOption = null;
	return (
		<SingleSelectFilter
			options={options}
			defaultOption={defaultOption}
			updateFilter={updateFilter}
			filter={filter}
			onBack={onBack}
			filterKey='sex'
			title={t('common.geschlecht')}
		/>
	);
};

SexFilter.propTypes = {
	filter: PropTypes.object,
	updateFilter: PropTypes.func,
	onBack: PropTypes.func,
};

export { SexFilter };
