import PropTypes from 'prop-types';
import { FormControl } from 'react-bootstrap';
import { FormHelperText, TextField, TextareaAutosize } from 'components/ui';

const TextArea = (props) => {
	return (
		<div>
			<FormControl
				as='textarea'
				label={props.label}
				rows={props.rows}
				value={props.value}
				onChange={props.onChange}
				isInvalid={props.isInvalid}
			/>
			{props.isInvalid && (
				<FormHelperText style={{ color: '#dc3545' }}>
					{props.isInvalid}
				</FormHelperText>
			)}
		</div>
	);
};

TextArea.propTypes = {
	label: PropTypes.string,
	rows: PropTypes.number,
	value: PropTypes.string,
	onChange: PropTypes.func,
	isInvalid: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

const AutoResizeTextArea = (props) => {
	return (
		<div className='w-100 position-relative'>
			<TextField
				error={props.isInvalid}
				className='w-100'
				label={props.label}
				value={props.value}
				onChange={props.onChange}
				InputProps={{
					inputComponent: TextareaAutosize,
					inputProps: {
						minRows: 5,
						style: {
							resize: 'auto',
						},
					},
				}}
			/>
			{props.isInvalid && (
				<FormHelperText
					style={{ color: '#dc3545' }}
					className='position-absolute bottom-0 end-0 pe-2'
				>
					{props.isInvalid}
				</FormHelperText>
			)}
		</div>
	);
};

AutoResizeTextArea.propTypes = {
	label: PropTypes.string,
	rows: PropTypes.number,
	value: PropTypes.string,
	onChange: PropTypes.func,
	isInvalid: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export { TextArea, AutoResizeTextArea };
