import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

const useAvailableFilters = () => {
	const filter = useSelector((state) => state.settings.vaxFilter);
	const [t] = useTranslation();
	const globAgeGroups = useSelector((state) => state.masterData.globAgeGroups);
	const globHealthDamages = useSelector(
		(state) => state.masterData.globHealthDamages
	);
	const countries = useSelector((state) => state.masterData.countries);
	const languages = useSelector((state) => state.masterData.languages);
	const globDamageOccurred = useSelector(
		(state) => state.masterData.globDamageOccurred
	);

	const availableFilters = [
		{
			key: 'sort',
			name: 'sortieren_nach',
			display: () => {
				if (filter.sort) {
					return t('common.' + filter?.sort);
				} else {
					return t('common.relevanz');
				}
			},
		},
		{
			key: 'vax_damage',
			name: 'impfschaden',
			display: (option) => {
				if (filter?.vax_damage?.length > 0) {
					return (
						<>
							{filter.vax_damage.map((item, index) => {
								const tag = globHealthDamages.find((health_damage) => {
									return (
										health_damage.glob_health_damage_id.toString() ==
										item.toString()
									);
								});
								return (
									<div key={index}>
										<div>
											{t(
												'master_data.health_damage.' +
													tag?.health_damage_i18n_key
											)}
										</div>
										<span>{option.vax_health_damages_count}</span>
									</div>
								);
							})}
						</>
					);
				} else {
					return null;
				}
			},
		},
		{
			key: 'vaccination',
			name: 'impfung',
			display: () => {
				if (filter?.vaccination?.length > 0) {
					return (
						<>
							{filter.vaccination.map((vacc, index) => {
								return <div key={index}>{vacc}</div>;
							})}
						</>
					);
				} else {
					return null;
				}
			},
		},
		{
			key: 'manufacturer',
			name: 'hersteller',
			display: () => {
				if (filter?.manufacturer?.length > 0) {
					return (
						<>
							{filter.manufacturer.map((item, index) => {
								return <div key={index}>{item}</div>;
							})}
						</>
					);
				} else {
					return null;
				}
			},
		},
		{
			key: 'zip',
			name: 'ort',
			display: () => {
				if (filter.zip) {
					return filter.zip;
				}
			},
		},

		{
			key: 'country',
			name: 'land',
			display: () => {
				if (filter?.country?.length > 0) {
					return (
						<>
							{filter.country.map((id, index) => {
								const country = countries.find((item) => {
									return item.country_iso_code.toLowerCase() === id;
								});
								if (country)
									return (
										<div
											key={index}
										>{`${country.country_name}, ${country.country_name_de}`}</div>
									);
								else return null;
							})}
						</>
					);
				} else {
					return null;
				}
			},
		},
		{
			key: 'filter_language',
			name: 'sprache',
			display: () => {
				if (filter?.filter_language?.length > 0) {
					return (
						<>
							{filter.filter_language.map((id, index) => {
								const language = languages.find((item) => {
									return item.lang_iso_code === id;
								});
								if (language) {
									return <div key={index}>{`${language.lang_name}`}</div>;
								} else return null;
							})}
						</>
					);
				} else {
					return null;
				}
			},
		},
		// {
		// 	key: 'search',
		// 	name: 'volltextsuche',
		// 	display: () => {
		// 		if (filter.search) {
		// 			return filter.search;
		// 		}
		// 	},
		// },
		{
			key: 'age',
			name: 'alter',
			display: () => {
				if (filter?.age?.length > 0) {
					return (
						<>
							{filter.age.map((id, index) => {
								const ageGroupItem = globAgeGroups.find((item) => {
									return item.glob_age_group_id === id;
								});
								if (ageGroupItem)
									return (
										<div key={index}>
											{t(
												'master_data.age_group.' +
													ageGroupItem.age_group_i18n_key
											)}
										</div>
									);
								else return null;
							})}
						</>
					);
				} else {
					return null;
				}
			},
		},
		{
			key: 'sex',
			name: 'geschlecht',
			display: () => {
				if (filter.sex) {
					return t('common.' + filter.sex);
				}
			},
		},
		{
			key: 'pre_health_damage',
			name: 'vorerkrankung',
			display: () => {
				if (filter?.pre_health_damage?.length > 0) {
					return (
						<>
							{filter.pre_health_damage.map((item, index) => {
								const tag = globHealthDamages.find((health_damage) => {
									return (
										health_damage.glob_health_damage_id.toString() ==
										item.toString()
									);
								});
								return (
									<div key={index}>
										{t(
											'master_data.health_damage.' + tag.health_damage_i18n_key
										)}
									</div>
								);
							})}
						</>
					);
				} else {
					return null;
				}
			},
		},
		{
			key: 'hospitalized',
			name: 'krankenhauseinweisung',
			display: () => {
				if (filter.hospitalized) {
					return t('common.' + filter.hospitalized);
				}
			},
		},

		{
			key: 'admin_date',
			name: 'zeitpunkt_der_impfung',
			display: () => {
				return (
					<>
						{filter?.admin_date?.start && (
							<div>
								{t('common.von')} {dayjs(filter.admin_date.start).format('L')}
							</div>
						)}
						{filter?.admin_date?.end && (
							<div>
								{t('common.bis')} {dayjs(filter.admin_date.end).format('L')}
							</div>
						)}
					</>
				);
			},
		},
		{
			key: 'damage_occurred',
			name: 'zeitspanne_impfungen_symptome',
			display: () => {
				if (filter?.damage_occurred?.length > 0) {
					return (
						<>
							{filter.damage_occurred.map((id, index) => {
								const damageOccuredItem = globDamageOccurred.find((item) => {
									return item.glob_damage_occurred_id === id;
								});
								if (damageOccuredItem)
									return (
										<div key={index}>
											{t(
												'master_data.damage_occurred.' +
													damageOccuredItem.occurred_i18n_key
											)}
										</div>
									);
								else return null;
							})}
						</>
					);
				} else {
					return null;
				}
			},
		},
		{
			key: 'batch_number',
			name: 'chargennummer',
			display: () => {
				if (filter.batch_number) {
					return filter.batch_number;
				}
			},
		},
		{
			key: 'health_result',
			name: 'ausgang_der_impfreaktion',
			display: () => {
				if (filter?.health_result?.length > 0) {
					return (
						<>
							{filter.health_result.map((item, index) => {
								return (
									<div key={index}>
										{t('master_data.health_result.' + item)}
									</div>
								);
							})}
						</>
					);
				} else {
					return null;
				}
			},
		},
	];
	return { availableFilters };
};

export default useAvailableFilters;
