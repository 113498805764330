import { ProgressBar as Progress } from 'react-bootstrap';
import PropTypes from 'prop-types';

const ProgressBar = (props) => {
	return <Progress now={props.progress} label={`${props.progress}%`} />;
};

ProgressBar.propTypes = {
	progress: PropTypes.number,
};

export { ProgressBar };
