import { jsPDF } from 'jspdf';

const makePDF = (data) => {
	// Default export is a4 paper, portrait, using millimeters for units
	let x = 10;
	let y = 10;
	const doc = new jsPDF();
	doc.setTextColor('#FF9B0D');
	doc.setFontSize(20);
	doc.text('Schlagwort-Lexikon', 10, 10);
	y = 20;

	let stack = [];
	stack.unshift(...data[0]);

	// console.log('makePDF', data, stack);
	doc.setTextColor('#555555');
	doc.setFontSize(16);
	// console.log('data', data);
	let level = [data[0].length];
	while (stack.length) {
		let obj = stack.shift();

		if (y >= 270) {
			doc.addPage();
			y = 20;
		}
		doc.setLineWidth(180 - x);
		x = 10 + (level.length - 1) * 10;

		switch (level.length) {
			case 1:
				doc.setFontSize(16);
				doc.setFont('helvetica', 'normal', 'bold');
				y = y + 3;
				break;
			default:
				if (data[obj.glob_health_damage_id]) {
					doc.setFontSize(Math.max(16 - level.length * 2, 12));
					doc.setFont('helvetica', 'normal', 'bold');
				} else {
					doc.setFontSize(12);
					doc.setFont('helvetica', 'normal', 'normal');
				}
		}
		let lines = doc.splitTextToSize(obj.name, 180 - x);

		// console.log(
		// 	doc.getFont(),
		// 	x,
		// 	y,
		// 	obj.name,
		// 	level,
		// 	doc.getTextWidth(obj.name),
		// 	doc.getTextDimensions(obj.name),
		// 	doc.getLineWidth(),
		// 	lines
		// );
		// let result = doc.text(obj.name, x, y, { maxWidth: 180 - x });
		for (let i = 0; i < lines.length; i++) {
			doc.text(lines[i], x, y, { maxWidth: 180 - x });
			switch (level.length) {
				case 1:
					y = y + 6.5;
					break;
				case 2:
					y = y + 5;
					break;
				default:
					y = y + 5;
			}
		}
		y = y + 3;
		// console.log(result);

		level[level.length - 1] = level[level.length - 1] - 1;
		if (data[obj.glob_health_damage_id]) {
			// stack.unshift(...data[obj.parent_health_damage_id]);
			// console.log('should add', data[obj.glob_health_damage_id]);
			stack.unshift(...data[obj.glob_health_damage_id]);
			level.push(data[obj.glob_health_damage_id].length);
		} else if (level[level.length - 1] == 0) {
			while (level[level.length - 1] === 0) {
				// While the last element is a 0,
				level.pop(); // Remove that last element
			}
		}
		// switch (level.length) {
		// 	case 1:
		// 		y = y + 3 + lines.length * 7;
		// 		break;
		// 	case 2:
		// 		y = y + 3 + lines.length * 5;
		// 		break;
		// 	default:
		// 		y = y + 3 + lines.length * 5;
		// }

		// let space = 180 - x;
		// let textwidth = doc.getTextWidth(obj.name);
		// let additionalLines = Math.floor(textwidth / space);
		// if (additionalLines > 0) {
		// 	y = y + additionalLines * 7;
		// }
		// if (doc.getTextWidth(obj.name) + x > 400) {
		// 	y = y + 21;
		// } else if (doc.getTextWidth(obj.name) + x > 300) {
		// 	y = y + 14;
		// } else if (doc.getTextWidth(obj.name) + x > 200) {
		// 	y = y + 7;
		// }
	}
	doc.save('test.pdf');
	const datauri = doc.output('blob');
	// console.log(datauri);
	// const now = new Date();
	// doc.save(
	// 	now.getFullYear().toString() +
	// 		(now.getMonth() + 1).toString().padStart(2, '0') +
	// 		now.getDate().toString().padStart(2, '0') +
	// 		'_Vetopedia_Schlagworte_fuer_Benutzer.pdf'
	// );
	return datauri;
};

export default makePDF;
