import { AngleRight, ActiveLink, HomeIcon } from 'components/ui';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router.js';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

const BreadcrumbItem = styled.span`
	cursor: pointer;
	&:hover {
		text-decoration: underline;
	}
`;

const Breadcrumb = () => {
	const [t] = useTranslation();
	const router = useRouter();
	const [routeConfig, setRouteConfig] = useState(null);

	useEffect(() => {
		if (!router) {
			return;
		}
		const routeParts = router?.route?.split('/').slice(1);
		const firstPart = routeParts[0];
		const secondPart = routeParts[1];
		setRouteConfig({ routeParts, firstPart, secondPart });
	}, [router]);

	// const Icon = (() => {
	// 	if (!routeConfig) {
	// 		return '';
	// 	}
	// 	switch (routeConfig.firstPart) {
	// 		case 'nutzerbereich':
	// 			return PersonCircle;
	// 		case 'adminbereich':
	// 			return PersonCircle;
	// 		default:
	// 			return '';
	// 	}
	// })();

	if (!routeConfig) {
		return null;
	}
	return (
		<nav
			aria-label='breadcrumb'
			className='bg-white d-inline-block shadow-sm ps-4 py-1 pe-1 mx-n4 mb-4'
		>
			<span className='mx-1'>
				<ActiveLink href='/'>
					<BreadcrumbItem>
						<HomeIcon size='1x' className='cursor-pointer' />
					</BreadcrumbItem>
				</ActiveLink>

				{routeConfig.routeParts.map((crumb, index, crumbs) => {
					const href =
						'/' +
						crumbs
							.slice(0, index + 1)
							.map((item) => t('routing.' + item))
							.join('/');
					if (crumb == '[lang]') {
						return null;
					}
					return (
						<span key={'breadcrumb-' + index}>
							<span className='mx-2'>
								<AngleRight />
							</span>
							<ActiveLink href={href}>
								<BreadcrumbItem>
									<span>{t('common.' + crumb)}</span>
								</BreadcrumbItem>
							</ActiveLink>
						</span>
					);
				})}
			</span>
		</nav>
	);
};

export default Breadcrumb;
