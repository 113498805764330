import { createApi } from '@reduxjs/toolkit/query/react';
import axios from 'axios';

const axiosBaseQuery =
	({ baseUrl } = { baseUrl: '' }) =>
	async ({ url, method, data, onUploadProgress }, { getState }) => {
		try {
			const result = await axios({
				url: baseUrl + url,
				method,
				headers: {
					accept: 'application/json',
					'X-User-Language': getState().settings.language?.toLowerCase(),
				},
				data,
				onUploadProgress,
			});
			return { data: result.data };
		} catch (axiosError) {
			let err = axiosError;
			return {
				error: {
					status: err.response?.status,
					data: err.response?.data || err.message,
				},
			};
		}
	};

export const globApi = createApi({
	reducerPath: 'globApi',
	baseQuery: axiosBaseQuery({
		baseUrl: process.env.API_URL + 'v1/glob/',
	}),
	endpoints: (builder) => ({
		uploadVaxFile: builder.mutation({
			query: (arg) => {
				return {
					url: 'files/uploadVaxFile',
					method: 'POST',
					data: arg.body,
					onUploadProgress: (e) => arg.onProgress(e, arg.filename),
				};
			},
		}),
		uploadAvatar: builder.mutation({
			query: (arg) => {
				return {
					url: 'files/uploadAvatar',
					method: 'POST',
					data: arg.body,
				};
			},
		}),
		getFilesById: builder.query({
			query: ({ id, filename }) => ({
				url: `files/${id}/${filename}`,
				method: 'get',
			}),
		}),
		deleteFile: builder.mutation({
			query: (arg) => ({
				url: `files/delete/${arg.glob_file_id}`,
				method: 'DELETE',
			}),
		}),
		damage_occurrences: builder.query({
			query: () => ({
				url: 'damage_occurrences',
				method: 'GET',
			}),
		}),
		health_results: builder.query({
			query: () => ({
				url: 'health_results',
				method: 'GET',
			}),
		}),
		health_damages: builder.query({
			query: () => ({
				url: 'health_damages',
				method: 'GET',
			}),
		}),
		referrers: builder.query({
			query: () => ({
				url: 'referrers',
				method: 'GET',
			}),
		}),
		age_groups: builder.query({
			query: () => ({
				url: 'age_groups',
				method: 'GET',
			}),
		}),
		vax_health_damages: builder.query({
			query: () => ({
				url: 'vax_health_damages',
				method: 'GET',
			}),
		}),
		vax_pre_illnesses: builder.query({
			query: () => ({
				url: 'vax_pre_illnesses',
				method: 'GET',
			}),
		}),
		vax_language_counts: builder.query({
			query: () => ({
				url: 'vax_language_counts',
				method: 'GET',
			}),
		}),
		route_translations: builder.query({
			query: () => ({
				url: 'route_translations',
				method: 'GET',
			}),
		}),
	}),
});

export const {
	useGetFilesByIdLazyQuery,
	useUploadVaxFileMutation,
	useUploadAvatarMutation,
	useDeleteFileMutation,
} = globApi;

export const useReferrersLazyQuery = globApi.endpoints.referrers.useLazyQuery;
export const useAgeGroupsLazyQuery = globApi.endpoints.age_groups.useLazyQuery;
export const useRouteTranlationsLazyQuery =
	globApi.endpoints.route_translations.useLazyQuery;
export const useHealthResultsQuery =
	globApi.endpoints.health_results.useLazyQuery;
export const useHealthDamagesLazyQuery =
	globApi.endpoints.health_damages.useLazyQuery;
export const useVaxHealthDamagesLazyQuery =
	globApi.endpoints.vax_health_damages.useLazyQuery;
export const useVaxLanguageCountsLazyQuery =
	globApi.endpoints.vax_language_counts.useLazyQuery;
export const useVaxPreIllnessesLazyQuery =
	globApi.endpoints.vax_pre_illnesses.useLazyQuery;
export const useDamageOccurrencesLazyQuery =
	globApi.endpoints.damage_occurrences.useLazyQuery;
