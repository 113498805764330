import { prepareTagForLexicon } from 'components/schlagworte/components/TagLexicon.js';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Button, Card, Container, Row, Col } from 'components/ui';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { LexiconAccordion } from 'components/schlagworte/components/LexiconAccordion.js';
import Color from 'color';
import { LexiconItem } from 'components/schlagworte/components/LexiconItem.js';
import { Download, MenuBook } from '@mui/icons-material';
import { API_URL } from 'env.js';

const LexiconTile = (props) => {
	return (
		<Card
			style={{
				backgroundColor: props.tag.color ? props.tag.color + '55' : '#55555555',
				borderColor: props.tag.color ? props.tag.color : '#555555',
				borderWidth: '5px',
				height: '100%',
			}}
		>
			<Card.Body className='d-flex align-items-center'>
				<Card.Title
					className='d-flex w-100 text-wrap justify-content-center'
					style={{ textAlign: 'center' }}
				>
					{props.tag.name}
				</Card.Title>
			</Card.Body>
		</Card>
	);
};

LexiconTile.propTypes = {
	tag: PropTypes.object,
};

export const calcColors = (color) => {
	if (!color) {
		color = '#aaaaaa';
	}
	const luminosity = Color(color).luminosity();
	const result = [
		color && luminosity < 0.5 ? 'white' : '#5E5E5E', // text color
		color,
		Color(color).lighten(0.2).hex().toString(),
		Color(color).lighten(0.4).hex().toString(),
		Color(color).lighten(0.6).hex().toString(),
		Color(color).lighten(0.8).hex().toString(),
		Color(color).lighten(1).hex().toString(),
	];
	// console.log('calcColors', result);
	return result;
};

const LexiconGridView = (props) => {
	const [t] = useTranslation();
	const [index, setIndex] = useState(0);
	const globHealthDamages = useSelector(
		(state) => state.masterData.globHealthDamages
	);
	const tags = prepareTagForLexicon(globHealthDamages, t, true);

	const renderTiles = (items) => {
		return items.map((item) => {
			return (
				<div
					key={item.glob_health_damage_id}
					onClick={() => {
						setIndex(item.glob_health_damage_id);
					}}
					style={{
						minHeight: '150px',
						width: '23%',
						minWidth: '23%',
						marginBottom: '5px',
						cursor: 'pointer',
					}}
				>
					<LexiconTile
						tag={item}
						onSelect={props.onSelect}
						selected={props.selected}
					/>
				</div>
			);
		});
	};
	const onSelect = (tag) => {
		props.onSelect(tag);
	};

	const headTag = tags[0].find((item) => item.glob_health_damage_id == index);

	return (
		<div>
			<Container>
				{tags && index > 0 && (
					<Row className='mb-1'>
						<Col className='d-flex justify-content-between'>
							<Button
								onClick={() =>
									setIndex(
										globHealthDamages.find((item) => {
											return item.glob_health_damage_id == index;
										}).parent_health_damage_id
									)
								}
							>
								{t('common.zurueck')}
							</Button>
						</Col>
					</Row>
				)}
				<Row>
					<Col>
						{tags && index == 0 && tags[index]?.length > 0 && (
							<div className='d-flex flex-row flex-wrap justify-content-between'>
								{renderTiles(tags[index])}
							</div>
						)}
						{
							tags && index > 0 && tags[index]?.length > 0 && (
								// tags[index].map((item) => {
								// 	return (
								// 		<div key={item.glob_health_damage_id}>
								<>
									{headTag?.is_selectable == 1 && (
										<LexiconItem
											selected={props.selected}
											onSelect={onSelect}
											selectionMode={true}
											level={2}
											start={index}
											data={tags}
											edit={false}
											dragging={false}
											draggedItem={{}}
											key={headTag.glob_health_damage_id}
											tag={headTag}
											lastItem={false}
											levelBgColorMap={calcColors(
												tags[0].find(
													(item) => item.glob_health_damage_id == index
												)?.color
											)}
											levelColorMap={calcColors(
												tags[0].find(
													(item) => item.glob_health_damage_id == index
												)?.color
											)}
										/>
									)}
									<LexiconAccordion
										selected={props.selected}
										onSelect={onSelect}
										selectionMode={true}
										level={2}
										start={index}
										data={tags}
										edit={false}
										dragging={false}
										draggedItem={{}}
										levelBgColorMap={calcColors(
											tags[0].find(
												(item) => item.glob_health_damage_id == index
											)?.color
										)}
										levelColorMap={calcColors(
											tags[0].find(
												(item) => item.glob_health_damage_id == index
											)?.color
										)}
									/>
								</>
							)
							// 		</div>
							// 	);
							// })
						}
					</Col>
				</Row>
			</Container>
		</div>
	);
};

LexiconGridView.propTypes = {
	onSelect: PropTypes.func,
	selected: PropTypes.array,
};

export { LexiconGridView };
