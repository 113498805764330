import { AgeFilter } from 'components/impfschaeden/uebersicht/components/AgeFilter.js';
import { BatchNumberFilter } from 'components/impfschaeden/uebersicht/components/BatchNumberFilter.js';
import { CountryFilter } from 'components/impfschaeden/uebersicht/components/CountryFilter.js';
import { LanguageFilter } from 'components/impfschaeden/uebersicht/components/LanguageFilter.js';
import { DamageOccurredFilter } from 'components/impfschaeden/uebersicht/components/DamageOccurredFilter.js';
import { HealthResultFilter } from 'components/impfschaeden/uebersicht/components/HealthResultFilter.js';
import { HospitalizedFilter } from 'components/impfschaeden/uebersicht/components/HospitalizedFilter.js';
import { ManufacturerFilter } from 'components/impfschaeden/uebersicht/components/ManufacturerFilter.js';
import { PreHealthDamageFilter } from 'components/impfschaeden/uebersicht/components/PreHealthDamageFilter.js';
import { SexFilter } from 'components/impfschaeden/uebersicht/components/SexFilter.js';
import { SortFilter } from 'components/impfschaeden/uebersicht/components/SortFilter.js';
import { VaccinationFilter } from 'components/impfschaeden/uebersicht/components/VaccinationFilter.js';
import { VaxDamageFilter } from 'components/impfschaeden/uebersicht/components/VaxDamageFilter.js';
import { VaxDateFilter } from 'components/impfschaeden/uebersicht/components/VaxDateFilter.js';
import { ZipFilter } from 'components/impfschaeden/uebersicht/components/ZipFilter.js';
import PropTypes from 'prop-types';
import { EntryFilterSelection } from 'components/nutzerbereich/UserArea/components/EntryFilterSelection.js';
import { TeamFilter } from 'components/nutzerbereich/UserArea/components/TeamFilter.js';
import { ReferrerFilter } from 'components/nutzerbereich/UserArea/components/ReferrerFilter.js';

const EntryFilterSidebar = ({
	section,
	setSection,
	handleClose,
	filter,
	updateFilter,
}) => {
	const clearSection = () => {
		setSection(null);
	};

	switch (section) {
		case 'arbeitsgruppe':
			return (
				<TeamFilter
					setSection={setSection}
					filter={filter}
					updateFilter={updateFilter}
					onBack={clearSection}
				/>
			);
		case 'referrer':
			return (
				<ReferrerFilter
					setSection={setSection}
					filter={filter}
					updateFilter={updateFilter}
					onBack={clearSection}
				/>
			);
		case 'sort':
			return (
				<SortFilter
					setSection={setSection}
					filter={filter}
					updateFilter={updateFilter}
					onBack={clearSection}
				/>
			);
		case 'sex':
			return (
				<SexFilter
					setSection={setSection}
					filter={filter}
					updateFilter={updateFilter}
					onBack={clearSection}
				/>
			);
		case 'hospitalized':
			return (
				<HospitalizedFilter
					setSection={setSection}
					filter={filter}
					updateFilter={updateFilter}
					onBack={clearSection}
				/>
			);
		case 'vaccination':
			return (
				<VaccinationFilter
					setSection={setSection}
					filter={filter}
					updateFilter={updateFilter}
					onBack={clearSection}
					handleClose={handleClose}
				/>
			);
		case 'manufacturer':
			return (
				<ManufacturerFilter
					setSection={setSection}
					filter={filter}
					updateFilter={updateFilter}
					onBack={clearSection}
					handleClose={handleClose}
				/>
			);
		case 'age':
			return (
				<AgeFilter
					setSection={setSection}
					filter={filter}
					updateFilter={updateFilter}
					onBack={clearSection}
					handleClose={handleClose}
				/>
			);
		case 'vax_damage':
			return (
				<VaxDamageFilter
					setSection={setSection}
					filter={filter}
					updateFilter={updateFilter}
					onBack={clearSection}
					handleClose={handleClose}
				/>
			);
		case 'health_result':
			return (
				<HealthResultFilter
					setSection={setSection}
					filter={filter}
					updateFilter={updateFilter}
					onBack={clearSection}
					handleClose={handleClose}
				/>
			);
		case 'country':
			return (
				<CountryFilter
					setSection={setSection}
					filter={filter}
					updateFilter={updateFilter}
					onBack={clearSection}
					handleClose={handleClose}
				/>
			);
		case 'filter_language':
			return (
				<LanguageFilter
					setSection={setSection}
					filter={filter}
					updateFilter={updateFilter}
					onBack={clearSection}
					handleClose={handleClose}
				/>
			);
		case 'zip':
			return (
				<ZipFilter
					filterKey={'zip'}
					setSection={setSection}
					filter={filter}
					updateFilter={updateFilter}
					onBack={clearSection}
					handleClose={handleClose}
				/>
			);
		case 'batch_number':
			return (
				<BatchNumberFilter
					filterKey={'batch_number'}
					setSection={setSection}
					filter={filter}
					updateFilter={updateFilter}
					onBack={clearSection}
					handleClose={handleClose}
				/>
			);
		case 'damage_occurred':
			return (
				<DamageOccurredFilter
					setSection={setSection}
					filter={filter}
					updateFilter={updateFilter}
					onBack={clearSection}
					handleClose={handleClose}
				/>
			);
		case 'admin_date':
			return (
				<VaxDateFilter
					filterKey={'admin_date'}
					setSection={setSection}
					filter={filter}
					updateFilter={updateFilter}
					onBack={clearSection}
					handleClose={handleClose}
				/>
			);
		case 'pre_health_damage':
			return (
				<PreHealthDamageFilter
					filterKey={'pre_health_damage'}
					setSection={setSection}
					filter={filter}
					updateFilter={updateFilter}
					onBack={clearSection}
					handleClose={handleClose}
				/>
			);
		default:
			return (
				<EntryFilterSelection
					onBack={handleClose}
					section={section}
					setSection={setSection}
					filter={filter}
					updateFilter={updateFilter}
				/>
			);
	}
};

EntryFilterSidebar.propTypes = {
	section: PropTypes.string,
	setSection: PropTypes.func,
	handleClose: PropTypes.func,
	updateFilter: PropTypes.func,
	filter: PropTypes.object,
};

export { EntryFilterSidebar };
