import { Container, Form } from 'components/ui';
import styled from 'styled-components';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'next-i18next';
import i18n from '../../../../i18n'; // eslint-disable-line
import useFormUpdate from 'components/impfschaeden/assistent/hooks/useFormUpdate.js';
import { VaxFormTitle } from 'components/impfschaeden/assistent/components/VaxFormTitle.js';
import { ImpfungenForm } from 'components/impfschaeden/assistent/VaxForm_09_Impfungen/components/ImpfungenForm.js';
import { useEffect, useState } from 'react';
import mapValues from 'lodash/mapValues';
import range from 'lodash/range';
import without from 'lodash/without';
import dayjs from 'dayjs';
import * as duration from 'dayjs/plugin/duration';
import { isPlaceholderImage } from 'VaxForm_04_InfoZurPerson/components/PersonData.js';
dayjs.extend(duration);

const FormStyle = styled(Form)`
	width: '100%';
	align-items: 'center';
`;

const VaxContainer = styled(Container)`
	.multiple-containers {
		padding: 20px;
		border: 1px solid lightgray;
		border-radius: 5px;
	}
`;

export const initialVaccinationData = {
	batch_number: '',
	vax_type_id: 35,
	vax_manufacturer_id: 1,
	admin_year: '----',
	admin_month: '00',
	admin_day: '00',
};

const Step09 = (props) => {
	const [t] = useTranslation(['translation']);
	const vaxFormData = useSelector((state) => state.vaxFormData);
	const { updateVaccination, update } = useFormUpdate('vaxStep9');
	const { vaxManufacturers } = useSelector((state) => state.masterData);
	const [translatedValidation, setTranslatedValidation] = useState({});
	const user = useSelector((state) => state.settings.currentUser);
	const language = useSelector((state) => state.settings.language);
	const validation = useSelector((state) => state.formValidation.vaxStep9);

	useEffect(() => {
		if (
			!validation?.vax_entry_vaccinations?.length &&
			!validation?.vax_entry_vaccinations?.length == 0
		) {
			return;
		}
		setTranslatedValidation({
			vax_entry_vaccinations: validation?.vax_entry_vaccinations?.map(
				(item) => {
					return mapValues(item, (value) => {
						return t('validation.' + value);
					});
				}
			),
		});
	}, [validation, user, language]);

	const addVaccination = (prevVaccination) => {
		let currentVaccinations = vaxFormData?.vax_entry_vaccinations
			? [...vaxFormData?.vax_entry_vaccinations]
			: [];
		if (prevVaccination) {
			const newVaccination = { ...initialVaccinationData };
			newVaccination.vax_type_id = prevVaccination.vax_type_id;
			newVaccination.vax_manufacturer_id = prevVaccination.vax_manufacturer_id;
			currentVaccinations.push(newVaccination);
		} else {
			currentVaccinations.push(initialVaccinationData);
		}

		updateVaccination({
			vaccinations: currentVaccinations,
			trigger: null,
			index: vaxFormData?.vax_entry_vaccinations?.length,
		});
	};

	if (!vaxFormData?.vax_entry_vaccinations) {
		addVaccination();
	}

	const removeVaccination = (index) => {
		let currentVaccinations = [...vaxFormData?.vax_entry_vaccinations];
		currentVaccinations.splice(index, 1);
		updateVaccination({
			vaccinations: currentVaccinations,
			trigger: null,
			index,
		});
	};

	const getAgeDuration = (age, age_unit) => {
		const unit = age_unit == 'tag' ? 'd' : age_unit == 'monat' ? 'M' : 'y';
		return dayjs.duration(age, unit);
	};

	const getAgeGroupByAge = (age, age_unit) => {
		const duration = getAgeDuration(age, age_unit);
		const durationMonth = duration.asMonths();
		const durationYear = duration.asYears();
		if (durationMonth <= 1) {
			return 2;
		} else if (durationMonth <= 3) {
			return 3;
		} else if (durationMonth <= 4) {
			return 4;
		} else if (durationMonth <= 6) {
			return 5;
		} else if (durationYear <= 1) {
			return 6;
		} else if (durationYear < 3) {
			return 7;
		} else if (durationYear < 5) {
			return 8;
		} else if (durationYear < 7) {
			return 9;
		} else if (durationYear < 13) {
			return 10;
		} else if (durationYear < 19) {
			return 11;
		} else if (durationYear < 30) {
			return 12;
		} else if (durationYear < 40) {
			return 13;
		} else if (durationYear < 50) {
			return 14;
		} else if (durationYear < 60) {
			return 15;
		} else if (durationYear < 70) {
			return 16;
		} else if (durationYear < 80) {
			return 17;
		} else if (durationYear < 90) {
			return 18;
		} else {
			return 19;
		}
	};

	const oneOf = (items) => {
		return items[Math.floor(Math.random() * items.length)];
	};

	const setAvatarByAgeGroupId = (ageGroupId) => {
		switch (ageGroupId) {
			case 2: // 0-1 monate
			case 3: // 2-3 monate
			case 4: // 3-4 monate
			case 5: // 5-6 monate
			case 6: // 7-12 monate
				if (vaxFormData.sex == 'm') {
					update({ avatar_glob_file_id: oneOf([10, 11]) });
				} else {
					update({ avatar_glob_file_id: oneOf([12, 13]) });
				}
				break;
			case 7: // 1-2 jahre
				if (vaxFormData.sex == 'm') {
					update({ avatar_glob_file_id: oneOf([202, 203, 204, 205, 206]) });
				} else {
					update({ avatar_glob_file_id: oneOf([230, 231, 232, 233, 234]) });
				}
				break;
			case 8: // 3-4 jahre
				if (vaxFormData.sex == 'm') {
					update({ avatar_glob_file_id: oneOf([213, 214, 215, 216, 217]) });
				} else {
					update({ avatar_glob_file_id: oneOf([248, 249, 250, 251, 252]) });
				}
				break;
			case 9: // 5-6 jahre
				if (vaxFormData.sex == 'm') {
					update({ avatar_glob_file_id: oneOf([218, 219, 220, 221, 222]) });
				} else {
					update({ avatar_glob_file_id: oneOf([253, 254, 255, 256, 257]) });
				}
				break;
			case 10: // 7-12 jahre
				if (vaxFormData.sex == 'm') {
					update({
						avatar_glob_file_id: oneOf([223, 224, 225, 226, 227, 228, 229]),
					});
				} else {
					update({
						avatar_glob_file_id: oneOf([258, 259, 260, 261, 262, 263, 264]),
					});
				}
				break;
			case 11: // 13-18 jahre
				if (vaxFormData.sex == 'm') {
					update({
						avatar_glob_file_id: oneOf([207, 208, 209, 210, 211, 212]),
					});
				} else {
					update({
						avatar_glob_file_id: oneOf([
							235, 236, 237, 238, 239, 240, 241, 242, 243, 244, 245, 246, 247,
						]),
					});
				}
				break;
			case 12: // 19-29 jahre
				if (vaxFormData.sex == 'm') {
					update({
						avatar_glob_file_id: oneOf(range(265, 292)),
					});
				} else {
					update({
						avatar_glob_file_id: oneOf(range(14, 41)),
					});
				}
				break;
			case 13: // 30-39 jahre
				if (vaxFormData.sex == 'm') {
					update({
						avatar_glob_file_id: oneOf(without(range(293, 331), 300)),
					});
				} else {
					update({
						avatar_glob_file_id: oneOf(range(42, 69)),
					});
				}
				break;
			case 14: // 40-49 jahre
				if (vaxFormData.sex == 'm') {
					update({
						avatar_glob_file_id: oneOf(range(332, 368)),
					});
				} else {
					update({
						avatar_glob_file_id: oneOf(range(71, 82)),
					});
				}
				break;
			case 15: // 50-59 jahre
				if (vaxFormData.sex == 'm') {
					update({
						avatar_glob_file_id: oneOf(range(369, 394)),
					});
				} else {
					update({
						avatar_glob_file_id: oneOf(range(83, 116)),
					});
				}
				break;
			case 16: // 60-69 jahre
				if (vaxFormData.sex == 'm') {
					update({
						avatar_glob_file_id: oneOf(without(range(395, 429), 411)),
					});
				} else {
					update({
						avatar_glob_file_id: oneOf(range(117, 154)),
					});
				}
				break;
			case 17: // 70-79 jahre
				if (vaxFormData.sex == 'm') {
					update({
						avatar_glob_file_id: oneOf(without(range(430, 454), 433)),
					});
				} else {
					update({
						avatar_glob_file_id: oneOf([
							...range(155, 159),
							...range(503, 512),
						]),
					});
				}
				break;
			case 18: // 80-89 jahre
				if (vaxFormData.sex == 'm') {
					update({
						avatar_glob_file_id: oneOf(range(455, 478)),
					});
				} else {
					update({
						avatar_glob_file_id: oneOf(range(164, 182)),
					});
				}
				break;
			case 19: // > 90 jahre
				if (vaxFormData.sex == 'm') {
					update({
						avatar_glob_file_id: oneOf(range(479, 502)),
					});
				} else {
					update({
						avatar_glob_file_id: oneOf(range(183, 201)),
					});
				}
				break;
		}
	};

	const editVaccination = (index, newData) => {
		let currentVaccinations = [...vaxFormData?.vax_entry_vaccinations];
		currentVaccinations[index] = {
			...currentVaccinations[index],
			...newData,
		};

		// Alter wurde geändert
		if (newData.age || newData.age_unit || newData.glob_age_groups_id) {
			// Dann setze die Alters-Gruppe entsprechend der Angabe
			const age = newData.age || currentVaccinations[index].age;
			const age_unit = newData.age_unit || currentVaccinations[index].age_unit;
			let ageGroupId =
				newData.glob_age_groups_id ||
				currentVaccinations[index].glob_age_groups_id;
			if (newData.age || newData.age_unit) {
				ageGroupId = getAgeGroupByAge(age, age_unit);
				currentVaccinations[index].glob_age_groups_id = ageGroupId;
			}
			// Falls kein FOto hochgeladen wurde ändere den Patzhalter Avatar
			if (
				index == 0 &&
				isPlaceholderImage(vaxFormData.avatar_glob_file_id) &&
				vaxFormData.sex != 'unknown'
			) {
				setAvatarByAgeGroupId(parseInt(ageGroupId, 10));
			}
		}
		updateVaccination({
			vaccinations: currentVaccinations,
			trigger: Object.keys(newData)[0],
			index,
		});
	};

	const onChangeManufacturer = (newValue, index) => {
		if (typeof newValue === 'string') {
			const existing = vaxManufacturers.find((vaxManufacturer) => {
				return vaxManufacturer.manufacturer_i18n_key === newValue;
			});
			if (existing) {
				editVaccination(index, {
					vax_manufacturer_id: existing.vax_manufacturer_id,
				});
			} else {
				editVaccination(index, {
					vax_manufacturer_id: newValue,
				});
			}
		}
	};
	return (
		<>
			<form
				className={FormStyle}
				onSubmit={(e) => {
					e.preventDefault();
					props.next();
				}}
			>
				<VaxContainer spacing={4}>
					<VaxFormTitle
						title={t('impfschaeden.erfassen.welche_impfung_gegeben')}
						edit={props.edit}
					/>
					<ImpfungenForm
						addVaccination={addVaccination}
						editVaccination={editVaccination}
						removeVaccination={removeVaccination}
						onChangeManufacturer={onChangeManufacturer}
						formData={vaxFormData}
						validation={translatedValidation}
					/>
				</VaxContainer>
			</form>
		</>
	);
};

Step09.propTypes = {
	anotherPerson: PropTypes.bool,
	next: PropTypes.func,
	validation: PropTypes.object,
	vaxTypes: PropTypes.object,
	vaxManufacturers: PropTypes.object,
	globAgeGroups: PropTypes.array,
	loadGlobMasterData: PropTypes.func,
	updateFormData: PropTypes.func,
	vaxFormData: PropTypes.object,
	edit: PropTypes.bool,
};

export default Step09;
