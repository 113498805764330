import PropTypes from 'prop-types';
import { TextField, FormHelperText } from 'components/ui';
import { InputAdornment } from '@mui/material';

const SearchInput = ({
	type,
	value,
	onChange,
	required,
	isInvalid,
	label,
	clear,
}) => {
	const searchInputLabel = label || '';
	return (
		<div>
			<TextField
				size='small'
				type={type}
				label={searchInputLabel}
				value={value}
				onChange={onChange}
				required={required}
				error={!!isInvalid}
				fullWidth
				InputProps={
					clear
						? {
								endAdornment: (
									<InputAdornment position='end'>
										<span onClick={clear} className='cursor-pointer p-2'>
											x
										</span>
									</InputAdornment>
								),
						  }
						: {}
				}
			/>
			{isInvalid && (
				<FormHelperText style={{ color: '#dc3545' }}>
					{isInvalid}
				</FormHelperText>
			)}
		</div>
	);
};

SearchInput.propTypes = {
	type: PropTypes.string,
	label: PropTypes.string,
	value: PropTypes.string,
	onChange: PropTypes.func,
	clear: PropTypes.func,
	required: PropTypes.bool,
	isInvalid: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export { SearchInput };
