/* eslint-disable max-len */
/* eslint-disable no-mixed-spaces-and-tabs */
import styled from 'styled-components';
import {
	SquareShadowCard,
	Card,
	Container,
	Row,
	Col,
	ChatDotsFill,
	FormHelperText,
	StyledEntryCard,
	Modal,
} from 'components/ui';
import PropTypes from 'prop-types';
import EntryDetails, {
	DetailedViewModal,
} from 'components/impfschaeden/uebersicht/EntryCard/EntryDetails.js';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Edit, OpenInFull } from '@mui/icons-material';
//import { propTypes } from 'react-bootstrap/esm/Image.js';
import {
	getInternetLinkParts,
	getStatusColor,
	getStatusText,
	highlight,
} from 'lib/utils.js';
import parse from 'html-react-parser';

import { EntryProfile } from 'components/impfschaeden/uebersicht/EntryCard/components/EntryProfile.js';
import { Steckbrief } from 'components/impfschaeden/uebersicht/EntryCard/components/Steckbrief.js';
import { InfoCircle } from 'react-bootstrap-icons';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router.js';
import VideoSlideshow from 'components/layout/VideoSlideshow.js';
import { useHover } from '@reactuses/core';
import { useRef } from 'react';
import { EntryDescription } from 'components/impfschaeden/uebersicht/EntryCard/components/EntryDescription.js';

// const UserCardContainer = styled.div`
// 	display: flex;
// 	width: 800px;
// 	margin: 30px;
// 	background-color: white;
// 	padding: 20px;

// 	h5 {
// 		padding-bottom: 10px;
// 		border-bottom: 2px solid lightgray;
// 	}
// `;

// const ProfileDescriptionHeader = styled.h5`
// 	color: orange;
// `;

const ProfileDescription = styled.div`
	padding-right: 10px;

	> div {
		display: flex;
	}

	.avatar {
		width: 130px;
		height: 130px;

		margin-bottom: 20px;

		img {
			width: inherit;
			border-radius: 100px;
		}
	}

	.text {
		margin: 0 20px;
	}
`;

const ProfileHeader = styled.h5`
	color: #5e5e5e;
	font-size: 27.65px;
`;

export const ThemeCommentIcon = styled.div`
	color: ${(props) => props.theme.themePrimary} !important;
`;

const EntryCard = (props) => {
	const router = useRouter();
	const vaxTypes = useSelector((state) => state.masterData.vaxTypes);
	const user = useSelector((state) => state.settings.currentUser);

	const ref = useRef(null);
	const hovered = useHover(ref);
	const [query, setQuery] = useState('');
	const [modalShow, setModalShow] = useState(false);
	const [t] = useTranslation();

	useEffect(() => {
		if (router?.query) {
			setQuery('?' + router.asPath.split('?')[1]);
		}
	}, [router]);

	useEffect(() => {
		setReturnLink('impfschaden' + window.location.search);
	}, [window.location.search]);

	const { vax_entry_vaccinations, vax_entry_id, edit, long, promi } = props;

	const vaxType = vax_entry_vaccinations[0].vax_type_id;

	let detailLink =
		'/' +
		t('routing.impfschaden') +
		'/' +
		props.vax_entry_id +
		(query && query != '?undefined' ? query : '');
	if (props.userArea) {
		detailLink = '/adminbereich/eintraege/bearbeiten/' + props.vax_entry_id;
	}
	if (props.preview) {
		detailLink = '#';
	}

	const previewProps = props.preview
		? { onClick: () => setModalShow(true) }
		: {};

	const statusColor = getStatusColor(props.glob_entry_status_id);
	const statusText = getStatusText(props.glob_entry_status_id);

	const [returnLink, setReturnLink] = useState(
		'impfschaden' + (query && query != '?undefined' ? query : '')
	);

	return (
		<>
			<StyledEntryCard
				className={'mb-3 pb-2 ' + (hovered ? 'hovered' : '')}
				id={vax_entry_id}
				ref={ref}
				style={
					props.userArea
						? {
								boxShadow: '0px 0px 4px 1px ' + statusColor,
								marginTop: '40px',
						  }
						: {}
				}
			>
				{props.userArea && (
					<div
						className='d-flex flex-end position-absolute'
						style={{
							right: 0,
							zIndex: 1000,
							top: '-31px',
							color: 'white',
							background: statusColor,
							padding: '3px',
							borderTopLeftRadius: '10px',
							borderTopRightRadius: '10px',
						}}
					>
						{t('nutzerbereich.status')}: {t(statusText)}
					</div>
				)}
				<Card.Body className='p-0'>
					<Container>
						<Row className='position-relative'>
							<a
								href={detailLink}
								draggable={false}
								className='text-decoration-none p-3 pb-2'
								{...previewProps}
							>
								<Col className='d-flex justify-content-between'>
									<div className='d-flex justify-content-between'>
										<ThemeCommentIcon className='me-3'>
											<ChatDotsFill size={35} />
										</ThemeCommentIcon>
										<ProfileHeader>
											{vaxTypes.find((item) => {
												return item.vax_type_id == vaxType;
											})?.vax_type_i18n_key ||
												vax_entry_vaccinations[0].vax_type?.vax_type_i18n_key ||
												vax_entry_vaccinations[0].vax_type_id}
										</ProfileHeader>
									</div>
								</Col>
							</a>
							<div
								className='d-flex position-absolute justify-content-end'
								style={{ top: '15px', right: '10px' }}
							>
								{hovered &&
									!edit &&
									!long &&
									user &&
									(user.is_moderator || user.is_admin || user.is_expert) && (
										<a
											href={
												'/adminbereich/eintraege/bearbeiten/' +
												vax_entry_id +
												'?' +
												returnLink
											}
											className='me-3'
										>
											<Edit />
										</a>
									)}
								{/* <EntryDetails {...props} /> */}
								{hovered && !props.userArea && (
									<a href={detailLink} {...previewProps}>
										<OpenInFull />
									</a>
								)}
							</div>
						</Row>
						<Row>
							<Col className='p-0' style={{ width: '20%' }}>
								<a
									href={detailLink}
									draggable={false}
									className='text-decoration-none'
									{...previewProps}
								>
									<div className='p-0 ps-3'>
										<Container className='p-0'>
											<EntryProfile {...props} promi={promi} />
										</Container>
									</div>
								</a>
							</Col>
							<Col lg={6}>
								<EntryDescription {...props} />
							</Col>
							<Col>
								<Steckbrief {...props} />
							</Col>
						</Row>
					</Container>
				</Card.Body>
			</StyledEntryCard>
			<Modal
				{...props}
				size='lg'
				aria-labelledby='contained-modal-title-vcenter'
				centered
				show={modalShow}
				onHide={() => setModalShow(false)}
			>
				<Modal.Header closeButton>
					<Modal.Title>{t('common.detailansicht')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<DetailedViewModal {...props} />
				</Modal.Body>
			</Modal>
		</>
	);
};

EntryCard.propTypes = {
	visible_first_name: PropTypes.number,
	visible_last_name: PropTypes.number,
	visible_city: PropTypes.number,
	visible_avatar: PropTypes.number,
	first_name: PropTypes.string,
	last_name: PropTypes.string,
	country_iso_code: PropTypes.string,
	sex: PropTypes.string,
	city: PropTypes.string,
	vax_entry_id: PropTypes.number,
	avatar_glob_file_id: PropTypes.string,
	vax_health_damage_tags: PropTypes.arrayOf(PropTypes.object),
	description: PropTypes.string,
	vax_damage_occurred: PropTypes.string || PropTypes.number,
	vax_pre_health_damage_tags: PropTypes.arrayOf(PropTypes.object),
	vax_entry_vaccinations: PropTypes.arrayOf(PropTypes.object),
	case_id: PropTypes.string,
	internet_link: PropTypes.string,
	lang_iso_code: PropTypes.string,
	search: PropTypes.string,
	de: PropTypes.string,
	en: PropTypes.string,
	ru: PropTypes.string,
	fr: PropTypes.string,
	created_at: PropTypes.string,
	glob_referrer_id: PropTypes.number,
	edit: PropTypes.bool,
	long: PropTypes.bool,
	vax_entry_files: PropTypes.array,
	userArea: PropTypes.bool,
	promi: PropTypes.bool,
	preview: PropTypes.bool,
};

export default EntryCard;
