import { createSlice } from '@reduxjs/toolkit';

export const initialFormValidation = {
	vaxStep2: {},
	vaxStep3: {},
	vaxStep4: {},
	vaxStep5: {},
	vaxStep6: {},
	vaxStep7: {},
	vaxStep8: {},
	vaxStep9: { vax_entry_vaccinations: [] },
	vaxStep11: {},
	profileForm: {},
};

const formValidationSlice = createSlice({
	name: 'formValidation',
	initialState: initialFormValidation,
	reducers: {
		setValidation(state, { payload }) {
			return { ...state, ...payload };
		},
		removeValidation(state, { payload }) {
			if (payload) {
				delete state[payload.step][payload.key];
			}
		},
		removeUserValidation(state, { payload }) {
			delete state.vaxStep2[payload];
		},
		removeVaccinationValidation(state, { payload }) {
			const copy = [...state.vaxStep9.vax_entry_vaccinations];
			const copiedItem = { ...copy[payload.index] };
			delete copiedItem[payload.trigger];
			copy[payload.index] = copiedItem;
			state.vaxStep9.vax_entry_vaccinations = copy;
		},
		clearValidation() {
			return initialFormValidation;
		},
	},
});

export const formValidationActions = formValidationSlice.actions;

export default formValidationSlice.reducer;
