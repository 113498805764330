import PropTypes from 'prop-types';
import { Check } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const FilterOption = ({
	onClick,
	selected,
	option,
	isDefault,
	display,
	rawOption,
}) => {
	const [t] = useTranslation();
	let className = '';
	if (selected || isDefault) {
		className += ' fw-bolder';
	}

	return (
		<div
			className='border-bottom p-3 cursor-pointer'
			onClick={onClick}
			style={{ backgroundColor: selected ? '#ceeafb' : 'white' }}
		>
			<div className='d-flex justify-content-between align-items-center cursor-pointer  pe-3'>
				<div className={className}>{option}</div>
				{selected && (
					<div>
						<Check />
					</div>
				)}
				{!selected && isDefault && <div>{t('common.vorauswahl')}</div>}
			</div>
			{display && (
				<div style={{ fontSize: '14px', color: 'var(--bs-blue)' }}>
					{display(option, rawOption)}
				</div>
			)}
		</div>
	);
};

FilterOption.propTypes = {
	selected: PropTypes.bool,
	isDefault: PropTypes.bool,
	onClick: PropTypes.func,
	option: PropTypes.string,
	rawOption: PropTypes.object,
	index: PropTypes.number,
	display: PropTypes.func,
};

export { FilterOption };
