/* eslint-disable no-mixed-spaces-and-tabs */
import EntryDetails from 'components/impfschaeden/uebersicht/EntryCard/EntryDetails.js';
import { getAdminDate } from 'lib/utils.js';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Plus } from 'react-bootstrap-icons';
import { Button } from 'components/ui';
import uniq from 'lodash/uniq';
import { useState } from 'react';

const ProfileHeader = styled.h5`
	color: #5e5e5e;
	font-size: 27.65px;
`;

const ColoredLine = styled.hr`
	color: ${(props) => props.theme.themePrimary} !important;
	background-color: ${(props) => props.theme.themePrimary} !important;
	height: 3px;
	opacity: 1;
`;
ColoredLine.propTypes = { color: PropTypes.string };
const Profile = styled.div``;

const ProfilePoint = styled.div`
	padding: 2px 5px;
	margin: 7px 0;
	line-height: 19px;

	div:first-child {
		color: #578dae;
		font-weight: 700;
	}

	&:nth-of-type(odd) {
		font-weight: 400;
		color: #5e5e5e;
		background-color: #f6f8fb;
	}
	&:nth-of-type(even) {
		font-weight: 400;
		color: #5e5e5e;
		background-color: white;
	}
`;

const Steckbrief = (props) => {
	const {
		vax_health_damage_tags,
		detail,
		vax_damage_occurred,
		vax_entry_vaccinations,
		vax_pre_health_damage_tags,
		healthy_before_vax,
		hospitalized,
		long,
		vax_entry_id,
		glob_health_result_id,
	} = props;
	const [t] = useTranslation();
	const glob_health_damages = useSelector(
		(state) => state.masterData.globHealthDamages
	);
	const glob_health_results = useSelector(
		(state) => state.masterData.globHealthResults
	);
	const glob_damages_occurred = useSelector(
		(state) => state.masterData.globDamageOccurred
	);
	const [showMoreHealthDamages, setShowMoreHealthDamages] = useState(false);
	const [showMorePreIllnesses, setShowMorePreIllnesses] = useState(false);

	let maxTags = (() => {
		const maxLength = 80;
		for (var i = 1; i < vax_health_damage_tags.length; i++) {
			const currentLength = vax_health_damage_tags
				.slice(0, i)
				.map((item) => {
					if (item.name) {
						return item.name;
					} else {
						return t(
							'master_data.health_damage.' +
								glob_health_damages.find((itemHealthDamage) => {
									return (
										item.glob_health_damage_id ==
										itemHealthDamage.glob_health_damage_id
									);
								})?.health_damage_i18n_key
						);
					}
				})
				.join('').length;
			if (currentLength > maxLength) {
				return i - 1;
			}
		}
		return i;
	})();
	maxTags = Math.max(1, maxTags);
	if (detail || long || showMoreHealthDamages) {
		maxTags = 1000;
	}

	let maxPreIllness = (() => {
		const maxLength = 80;
		for (var i = 1; i < vax_pre_health_damage_tags.length; i++) {
			const currentLength = vax_pre_health_damage_tags
				.slice(0, i)
				.map((item) => {
					if (item.name) {
						return item.name;
					} else {
						return t(
							'master_data.health_damage.' +
								glob_health_damages.find((itemHealthDamage) => {
									return (
										item.glob_health_damage_id ==
										itemHealthDamage.glob_health_damage_id
									);
								})?.health_damage_i18n_key
						);
					}
				})
				.join('').length;
			if (currentLength > maxLength) {
				return i - 1;
			}
		}
		return i;
	})();
	maxPreIllness = Math.max(1, maxPreIllness);
	if (detail || long || showMorePreIllnesses) {
		maxPreIllness = 1000;
	}
	let dates = [];
	for (let i = 0; i < vax_entry_vaccinations.length; i++) {
		let currentDate = getAdminDate(
			vax_entry_vaccinations[i],
			t('impfschaeden.erfassen.unknown')
		);
		if (currentDate !== t('impfschaeden.erfassen.unknown')) {
			dates.push(currentDate);
		}
	}
	const date = uniq(dates).join(', ');

	return (
		<>
			{/* <ProfileHeader>{t('common.steckbrief')}</ProfileHeader> */}
			<Profile>
				<div>
					<ProfilePoint className='ProfilePoint'>
						<div>{t('common.impffolgen')}</div>
						{vax_health_damage_tags
							.slice(0, maxTags)
							.map((wrappedItem, index) => {
								let item;
								if (
									wrappedItem.glob_health_damage &&
									wrappedItem.glob_health_damage.length > 0
								) {
									item = wrappedItem.glob_health_damage[0];
								} else {
									item = wrappedItem;
								}
								if (!item) {
									return null;
								}
								return (
									<div
										key={index + 'damageTag' + vax_entry_id}
										style={{
											borderTop: index > 0 ? '1px solid lightgray' : '',
										}}
									>
										{item.lang_iso_code == 'translation'
											? t(
													'master_data.health_damage.' +
														item.health_damage_i18n_key
											  )
											: item.name != undefined
											? item.name
											: item.health_damage_i18n_key != undefined
											? item.health_damage_i18n_key
											: t(
													'master_data.health_damage.' +
														glob_health_damages.find((itemHealthDamage) => {
															return (
																item.glob_health_damage_id ==
																itemHealthDamage.glob_health_damage_id
															);
														})?.health_damage_i18n_key
											  )}
									</div>
								);
							})}
						{!showMoreHealthDamages &&
							maxTags < vax_health_damage_tags.length && (
								<Button
									className='mt-1 mb-1'
									size='sm'
									onClick={() => setShowMoreHealthDamages(true)}
								>
									<Plus />
									{vax_health_damage_tags.length -
										maxTags +
										' ' +
										t('impfschaeden.erfassen.weitere_impfschaeden')}
								</Button>
							)}
					</ProfilePoint>
					{vax_damage_occurred != '1' && (
						<ProfilePoint>
							<div>{t('common.auftreten')}</div>
							<div>
								{t(
									'master_data.damage_occurred.' +
										glob_damages_occurred.find((item) => {
											return (
												item.glob_damage_occurred_id == vax_damage_occurred
											);
										})?.occurred_i18n_key
								)}
							</div>
						</ProfilePoint>
					)}
					{date && !detail && (
						<ProfilePoint>
							<div>{t('common.zeitpunkt_der_impfung')}</div>
							<div>{date}</div>
						</ProfilePoint>
					)}
					{healthy_before_vax === 'no_unknown' && (
						<ProfilePoint>
							<div>{t('common.vorerkrankung')}</div>
							<div>{t('common.ja_keine_angaben_vorerkrankung')}</div>
						</ProfilePoint>
					)}
					{vax_pre_health_damage_tags?.length > 0 && (
						<ProfilePoint>
							<div>{t('common.vorerkrankung')}</div>
							{vax_pre_health_damage_tags
								.slice(0, maxPreIllness)
								.map((wrappedItem, index) => {
									let item;
									if (
										wrappedItem.glob_health_damage &&
										wrappedItem.glob_health_damage.length > 0
									) {
										item = wrappedItem.glob_health_damage[0];
									} else {
										item = wrappedItem;
									}
									return (
										<div
											key={index + 'damageTag' + vax_entry_id}
											style={{
												borderTop: index > 0 ? '1px solid lightgray' : '',
											}}
										>
											{item.lang_iso_code == 'translation'
												? t(
														'master_data.health_damage.' +
															item.health_damage_i18n_key
												  )
												: item.name != undefined
												? item.name
												: item.health_damage_i18n_key != undefined
												? item.health_damage_i18n_key
												: t(
														'master_data.health_damage.' +
															glob_health_damages.find((itemHealthDamage) => {
																return (
																	item.glob_health_damage_id ==
																	// eslint-disable-next-line max-len
																	itemHealthDamage.glob_health_damage_id
																);
															})?.health_damage_i18n_key
												  )}
										</div>
									);
								})}
							{!showMorePreIllnesses &&
								maxPreIllness < vax_pre_health_damage_tags.length && (
									<Button
										className='mt-1 mb-1'
										size='sm'
										onClick={() => setShowMorePreIllnesses(true)}
									>
										<Plus />
										{vax_pre_health_damage_tags.length -
											maxPreIllness +
											' ' +
											t('impfschaeden.erfassen.weitere_vorerkrankungen')}
									</Button>
								)}
						</ProfilePoint>
					)}
					{(hospitalized == 'ja' || hospitalized == 'nein') && (
						<ProfilePoint>
							<div>{t('common.krankenhauseinweisung')}</div>
							<div>{t('common.' + hospitalized)}</div>
						</ProfilePoint>
					)}
					{glob_health_result_id != 1 && (
						<ProfilePoint>
							<div>{t('common.ausgang_der_impfreaktion')}</div>
							<div>
								{t(
									'master_data.health_result.' +
										glob_health_results.find((healtResult) => {
											return (
												healtResult.glob_health_result_id ==
												glob_health_result_id
											);
										})?.health_result_i18n_key
								)}
							</div>
						</ProfilePoint>
					)}
				</div>
			</Profile>
		</>
	);
};
Steckbrief.propTypes = {
	visible_first_name: PropTypes.number,
	visible_last_name: PropTypes.number,
	visible_city: PropTypes.number,
	visible_avatar: PropTypes.number,
	first_name: PropTypes.string,
	last_name: PropTypes.string,
	country_iso_code: PropTypes.string,
	sex: PropTypes.string,
	city: PropTypes.string,
	vax_entry_id: PropTypes.number,
	avatar_glob_file_id: PropTypes.string,
	vax_health_damage_tags: PropTypes.arrayOf(PropTypes.object),
	description: PropTypes.string,
	vax_damage_occurred: PropTypes.string || PropTypes.number,
	vax_pre_health_damage_tags: PropTypes.arrayOf(PropTypes.object),
	vax_entry_vaccinations: PropTypes.arrayOf(PropTypes.object),
	case_id: PropTypes.string,
	healthy_before_vax: PropTypes.string,
	internet_link: PropTypes.string,
	glob_referrer_id: PropTypes.number,
	edit: PropTypes.bool,
	detail: PropTypes.bool,
	long: PropTypes.bool,
	hospitalized: PropTypes.string,
	glob_health_result_id: PropTypes.number,
};

export { Steckbrief };
