import { SearchableFilter } from 'components/impfschaeden/uebersicht/components/SearchableFilter.js';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const HealthResultFilter = ({ filter, updateFilter, onBack, handleClose }) => {
	const [t] = useTranslation();
	const [options, setOptions] = useState([]);
	const globHealthResults = useSelector(
		(state) => state.masterData.globHealthResults
	);
	useEffect(() => {
		if (globHealthResults?.length > 0) {
			setOptions(
				globHealthResults.map((health_result) => {
					return {
						name: t(
							'master_data.health_result.' +
								health_result.health_result_i18n_key
						),
						key: health_result.health_result_i18n_key,
						id: health_result.glob_health_result_id,
					};
				})
			);
		}
	}, [globHealthResults]);
	const defaultOption = null;
	return (
		<SearchableFilter
			options={options}
			defaultOption={defaultOption}
			updateFilter={updateFilter}
			filter={filter}
			onBack={onBack}
			filterKey='health_result'
			title={t('common.ausgang_der_impfreaktion')}
			placeholder={t('common.ausgang_der_impfreaktion_suchen')}
			handleClose={handleClose}
			skipTranslation={true}
		/>
	);
};

HealthResultFilter.propTypes = {
	filter: PropTypes.object,
	updateFilter: PropTypes.func,
	onBack: PropTypes.func,
	handleClose: PropTypes.func,
};

export { HealthResultFilter };
