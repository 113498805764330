import PropTypes from 'prop-types';
import {
	FormControl,
	InputLabel,
	OutlinedInput,
	InputAdornment,
	IconButton,
	FormHelperText,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useState } from 'react';

const Password = (props) => {
	const [showPassword, setShowPassword] = useState(false);
	const handleClickShowPassword = () => setShowPassword((show) => !show);
	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};
	return (
		<>
			<FormControl variant='outlined' size='small'>
				<InputLabel htmlFor='outlined-adornment-password'>
					{props.label}
				</InputLabel>
				<OutlinedInput
					label={props.label}
					placeholder={props.label}
					value={props.value || ''}
					onChange={props.onChange}
					required={props.required}
					error={!!props.isInvalid}
					id='outlined-adornment-password'
					type={showPassword ? 'text' : 'password'}
					endAdornment={
						<InputAdornment position='end'>
							<IconButton
								aria-label='toggle password visibility'
								onClick={handleClickShowPassword}
								onMouseDown={handleMouseDownPassword}
								edge='end'
							>
								{showPassword ? <VisibilityOff /> : <Visibility />}
							</IconButton>
						</InputAdornment>
					}
				/>
			</FormControl>
			{props.isInvalid && (
				<FormHelperText style={{ color: '#dc3545' }}>
					{props.isInvalid}
				</FormHelperText>
			)}
		</>
	);
};

Password.propTypes = {
	type: PropTypes.string,
	label: PropTypes.string,
	value: PropTypes.string,
	onChange: PropTypes.func,
	required: PropTypes.bool,
	isInvalid: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export { Password };
