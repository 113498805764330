import PropTypes from 'prop-types';
import { Row, Col, Card } from 'components/ui';


const VaxFormTitle = (props) => {
	return (
		<div style={{ marginBottom: '20px' }}>
			{!props.edit && (
				<Row xs={12}>
					<Col xs={12}>
						<Card.Title>{props.title}</Card.Title>
					</Col>
				</Row>
			)}
		</div>
	);
};

VaxFormTitle.propTypes = {
	edit: PropTypes.string,
	title: PropTypes.string,
	id: PropTypes.string,
};

export { VaxFormTitle };
