import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import Link from 'next/link';
import React, { Children } from 'react';
import { useSelector } from 'react-redux';

const ActiveLink = (props) => {
	const { asPath } = useRouter();
	const child = Children.only(props.children);
	const childClassName = child.props.className || '';
	const language = useSelector((state) => state.settings.language);

	// pages/index.js will be matched via props.href
	// pages/about.js will be matched via props.href
	// pages/[slug].js will be matched via props.as
	const className =
		asPath === props.href || asPath === props.as
			? `${childClassName} active`.trim()
			: childClassName;

	return (
		<Link {...props} className='cursor-pointer' locale={language}>
			{React.cloneElement(child, {
				className: className || null,
			})}
		</Link>
	);
};

ActiveLink.propTypes = {
	children: PropTypes.object,
	href: PropTypes.string,
	as: PropTypes.object,
};

export { ActiveLink };
