import { TileSelectFilter } from 'components/impfschaeden/uebersicht/components/TileSelectFilter.js';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const AgeFilter = ({ filter, updateFilter, onBack, handleClose }) => {
	const [t] = useTranslation();
	const [options, setOptions] = useState([]);
	const globAgeGroups = useSelector((state) => state.masterData.globAgeGroups);
	useEffect(() => {
		if (globAgeGroups?.length > 0) {
			setOptions(
				globAgeGroups.map((ageGroup) => {
					return {
						name: t('master_data.age_group.' + ageGroup.age_group_i18n_key),
						value: ageGroup.glob_age_group_id,
					};
				})
			);
		}
	}, [globAgeGroups]);
	return (
		<TileSelectFilter
			options={options}
			updateFilter={updateFilter}
			filter={filter}
			onBack={onBack}
			filterKey='age'
			title={t('common.alter')}
			handleClose={handleClose}
			noTranslation={true}
		/>
	);
};

AgeFilter.propTypes = {
	filter: PropTypes.object,
	updateFilter: PropTypes.func,
	onBack: PropTypes.func,
	handleClose: PropTypes.func,
};

export { AgeFilter };
