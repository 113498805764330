import { isRejectedWithValue } from '@reduxjs/toolkit';
import { errorActions } from 'store/errorSlice.js';
import postError from 'utils/postError.ts';

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger =
	({ dispatch, getState }) =>
	(next) =>
	(action) => {
		// RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
		if (isRejectedWithValue(action)) {
			console.warn('We got a rejected action!', action);
			if (
				action?.payload?.data == 'Token is not correct' ||
				action?.payload?.data?.message == 'Malformed UTF-8 characters'
			) {
				console.log('should return to login');
			} else {
				postError({
					action: JSON.stringify(action),
					state: JSON.stringify(getState()),
				});
			}
			dispatch(errorActions.addError(action.payload));
		}
		return next(action);
	};
