import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat.js';
dayjs.extend(LocalizedFormat);

export const availableLanguages = [
	{
		lang_iso_code: 'de',
		lang_country_iso_code: 'DE',
		lang_name: 'Deutsch',
		lang_name_de: 'Deutsch',
	},
	{
		lang_iso_code: 'en',
		lang_country_iso_code: 'GB',
		lang_name: 'English',
		lang_name_de: 'Englisch',
	},
	{
		lang_iso_code: 'fr',
		lang_country_iso_code: 'FR',
		lang_name: 'Français',
		lang_name_de: 'Französisch',
	},
	{
		lang_iso_code: 'ru',
		lang_country_iso_code: 'RU',
		lang_name: 'Pусский',
		lang_name_de: 'Russisch',
	},
	{
		lang_iso_code: 'es',
		lang_country_iso_code: 'ES',
		lang_name: 'Español',
		lang_name_de: 'Spanisch',
	},
	{
		lang_iso_code: 'ja',
		lang_country_iso_code: 'JA',
		lang_name: '日本語',
		lang_name_de: 'Japanisch',
	},
	{
		lang_iso_code: 'pt',
		lang_country_iso_code: 'PT',
		lang_name: 'Português',
		lang_name_de: 'Portugiesisch',
	},
	{
		lang_iso_code: 'it',
		lang_country_iso_code: 'IT',
		lang_name: 'Italiano',
		lang_name_de: 'Italienisch',
	},
	// dont forget to add dayjs locales!
];

export function getLastYearsList(count) {
	const year = new Date().getFullYear() - (count - 1);
	const years = Array.from(new Array(count), (val, index) => index + year);
	return years.reverse();
}

export const highlight = (needle, haystack) => {
	if (!needle || !haystack) {
		return haystack;
	}
	return haystack.replace(
		new RegExp(needle.trim().replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1'), 'gi'),
		(str) => `<mark>${str}</mark>`
	);
};

export const getInternetLinkParts = (internet_link, case_id) => {
	let complete_link = null;
	let source_domain = null;
	let source_href = null;
	let telegram_group = null;
	if (internet_link) {
		complete_link = internet_link;
		source_domain = internet_link;
		source_href = internet_link;
		try {
			const url = new URL(internet_link);
			source_domain = url.hostname;
			source_href = url.protocol + '//' + url.hostname;
			if (source_domain == 't.me') {
				telegram_group = url.pathname.split('/')[1];
			}
		} catch (e) {
			source_domain = internet_link;
			source_href = 'https://' + internet_link;
			complete_link = 'https://' + internet_link;
		}
		if (source_domain == 't.me' && telegram_group != null) {
			source_domain = source_domain + '/' + telegram_group;
			source_href = source_href + '/' + telegram_group;
		}
	}
	return { complete_link, source_domain, source_href };
};

export const getAdminDate = (firstVaccination, unknownString) => {
	let adate = '';
	if (
		firstVaccination.admin_year != '0000' &&
		firstVaccination.admin_year != '----'
	) {
		adate += firstVaccination.admin_year || '';
	}
	if (
		firstVaccination.admin_month != '00' &&
		firstVaccination.admin_month != '--'
	) {
		adate += firstVaccination.admin_month || '';
	}
	if (
		firstVaccination.admin_day != '00' &&
		firstVaccination.admin_day != '--'
	) {
		adate += firstVaccination.admin_day || '';
	}
	let date = dayjs(
		adate,
		adate.length == 4
			? 'YYYY'
			: adate.length == 6
			? 'YYYYMM'
			: adate.length == 8
			? 'YYYYMMDD'
			: 'error'
	).format(
		adate.length == 4
			? 'YYYY'
			: adate.length == 6
			? 'MMMM YYYY'
			: adate.length == 8
			? 'L'
			: 'error'
	);

	if (date == 'Invalid Date') {
		date = unknownString;
	}
	return date;
};

export const getStatusColor = (glob_entry_status_id) => {
	switch (glob_entry_status_id) {
		case 1:
			return 'var(--notValidatedYellow)';
		case 2:
			return '#20c997';
		case 3:
			return 'var(--deletedGrey)';
		default:
			return '';
	}
};

export const getStatusText = (glob_entry_status_id) => {
	switch (glob_entry_status_id) {
		case 1:
			return 'nutzerbereich.in_pruefung';
		case 2:
			return 'nutzerbereich.veroeffentlicht';
		case 3:
			return 'nutzerbereich.geloescht';
		default:
			return '';
	}
};
