import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const userApi = createApi({
	reducerPath: 'userApi',
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.API_URL + 'v1/users/',
		prepareHeaders: (headers, api) => {
			headers.set('accept', 'application/json');
			headers.set(
				'X-User-Language',
				api.getState().settings.language?.toLowerCase()
			);
			return headers;
		},
	}),
	endpoints: (build) => ({
		salutations: build.query({
			query: () => {
				return {
					url: 'salutations',
				};
			},
		}),
		titles: build.query({
			query: () => {
				return {
					url: 'titles',
				};
			},
		}),
		registerUser: build.mutation({
			query: (payload) => {
				return {
					url: 'register',
					method: 'POST',
					body: payload,
				};
			},
		}),
		login: build.mutation({
			query: (payload) => {
				return {
					url: 'login',
					method: 'POST',
					body: payload,
				};
			},
		}),
		validateUser: build.mutation({
			query: (payload) => {
				return {
					url: 'validation',
					method: 'POST',
					body: payload,
				};
			},
		}),
		resendConfirmationMail: build.mutation({
			query: (payload) => {
				return {
					url: 'resend_confirmation_mail',
					method: 'POST',
					body: { email: payload },
				};
			},
		}),
		passwordReset: build.mutation({
			query: (payload) => {
				return {
					url: 'pwreset',
					method: 'POST',
					body: payload,
				};
			},
		}),
		passwordChange: build.mutation({
			query: (payload) => {
				return {
					url: 'pwreset/change',
					method: 'POST',
					body: payload,
				};
			},
		}),
	}),
});

export const {
	useRegisterUserMutation,
	useLoginMutation,
	useValidateUserMutation,
	useSalutationsQuery,
	useTitlesQuery,
	usePasswordResetMutation,
	usePasswordChangeMutation,
	useResendConfirmationMailMutation,
} = userApi;

export const useSalutationsLazyQuery =
	userApi.endpoints.salutations.useLazyQuery;

export const useTitlesLazyQuery = userApi.endpoints.titles.useLazyQuery;
