import PropTypes from 'prop-types';
import { Badge, Button, CheckBox, SearchInput, Stack } from 'components/ui';
import {
	PublishedChip,
	DeletedChip,
	NotValidatedChip,
	NewTagChip,
	NoNewTagChip,
} from 'components/nutzerbereich/UserArea/components/Chips';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { StyledModal } from 'components/impfschaeden/uebersicht/components/VaxFilterContainer.js';
import { VaxFilterSidebar } from 'components/impfschaeden/uebersicht/components/VaxFilterSidebar.js';
import { useState } from 'react';
import { EntryFilterSidebar } from 'components/nutzerbereich/UserArea/components/EntryFilterSidebar.js';
import useFilterState from 'components/nutzerbereich/UserArea/hooks/useFilterState.js';
import cloneDeep from 'lodash/cloneDeep';
import remove from 'lodash/remove';
import isArray from 'lodash/isArray';
import { Sliders } from 'react-bootstrap-icons';
import { initialModeratorFilter } from 'components/nutzerbereich/UserArea/UserArea.js';
import { settingsActions } from 'store/settingsSlice.ts';

const CheckChip = styled.div`
	& .form-check-inline {
		margin-right: 3px;
	}
`;

export const FilterButton = styled(Button)`
	height: 40px;
	/* margin-top: 0 !important; */
	border-radius: 4px !important;
	&:hover {
		color: white !important;
	}
	&:active {
		color: white !important;
	}
	&:focus-visible {
		color: white !important;
	}
	&.active {
		color: black;
		/* filter: drop-shadow(30px 10px 4px #4444dd); */
	}
	&.selected {
		color: white !important;
		background-color: var(--bs-btn-hover-bg);
		border-color: var(--bs-btn-hover-border-color);
	}
`;

const FilterSettings = ({
	notValidatedEntries,
	publishedEntries,
	deletedEntries,
	toggleDeleted,
	toggleNotValidated,
	togglePublished,
	teams,
	setTeams,
	referrers,
	setReferrers,
	search,
	updateSearch,
	countFilter,
	toggleOnlyNoNewTags,
	toggleOnlyNewTags,
}) => {
	const moderatorFilter = useSelector(
		(state) => state.settings.moderatorFilter
	);
	const user = useSelector((state) => state.settings.currentUser);
	const [t] = useTranslation();
	const [section, setSection] = useState(null);
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const dispatch = useDispatch();

	const updateFilter = (data) => {
		// console.log('updateFIlter', data);
		if (data.arbeitsgruppe) {
			if (isArray(data.arbeitsgruppe) && data.arbeitsgruppe.length == 0) {
				// console.log('do special things');
				setTeams('all');
			} else {
				setTeams(data.arbeitsgruppe);
			}
		} else if (data.referrer) {
			setReferrers(data.referrer);
		}
	};

	// console.log('FilterSettings', teams, user);

	const open = (filterKey) => {
		setSection(filterKey);
		handleShow();
	};

	return (
		<>
			<div className='w-100'>
				<div className='gap-xl-1 w-100 d-flex flex-wrap mb-2'>
					{user && user.is_moderator && (
						<>
							<FilterButton
								variant='outline-primary'
								className={
									'py-0 me-1 mt-1' + (teams == 'mine' ? ' selected' : '')
								}
								onClick={() => {
									setTeams('mine');
								}}
							>
								{t('navigation.meine_eintraege')}
							</FilterButton>
							{user.user_team_id && (
								<FilterButton
									variant='outline-primary'
									className={
										'py-0 me-1 mt-1' +
										(isArray(teams) &&
										teams.indexOf(user.user_team_id.toString()) > -1
											? ' selected'
											: '')
									}
									onClick={() => {
										setTeams([user.user_team_id.toString()]);
									}}
								>
									{t('common.eintraege_von_meiner_gruppe')}
								</FilterButton>
							)}

							<FilterButton
								variant='outline-primary'
								className={
									'py-0 me-1 mt-1' + (teams == 'all' ? ' selected' : '')
								}
								onClick={() => {
									setTeams('all');
								}}
							>
								{t('common.alle_eintraege')}
							</FilterButton>
							{(user.is_admin || user.is_moderator || user.is_expert) && (
								<FilterButton
									variant='outline-primary'
									className={
										'py-0 me-1 mt-1' + (teams == 'extern' ? ' selected' : '')
									}
									onClick={() => {
										setTeams('extern');
									}}
								>
									{t('common.externe_eintraege')}
								</FilterButton>
							)}
							<FilterButton
								variant='outline-primary'
								className={
									'py-0 me-1 mt-1' + (countFilter() > 0 ? ' selected' : '')
								}
								onClick={() => open(null)}
							>
								<Sliders className='mt-n1 me-2' />
								<span className='me-3'>{t('common.mehr_filter')}</span>

								{countFilter() > 0 && (
									<Badge
										color='warning'
										badgeContent={countFilter()}
										className='mt-n1'
									/>
								)}
							</FilterButton>
							{countFilter() > 0 && (
								<FilterButton
									variant='outline-primary'
									className={'py-0 me-1 mt-1'}
									onClick={() => {
										for (let modFilter in initialModeratorFilter) {
											localStorage.removeItem('adminView_' + modFilter);
										}
										dispatch(
											settingsActions.setModeratorFilter(initialModeratorFilter)
										);
									}}
								>
									{t('common.alle_filter_zuruecksetzen')}
								</FilterButton>
							)}
						</>
					)}
				</div>
			</div>
			<div className='w-100'>
				<div className='gap-xl-1 w-100 d-flex flex-wrap justify-content-between'>
					<div className='gap-xl-1 d-flex flex-wrap'>
						<CheckChip
							onClick={toggleNotValidated}
							className='d-flex cursor-pointer align-items-center mb-1 me-1'
						>
							<CheckBox
								onChange={() => {}}
								checked={moderatorFilter.statusNotValidated}
								className='cursor-pointer me-1'
							/>
							<NotValidatedChip
								inactive={!moderatorFilter.statusNotValidated}
								className='cursor-pointer'
								label={
									notValidatedEntries + ' ' + t('nutzerbereich.in_pruefung')
								}
							/>
						</CheckChip>
						<CheckChip
							onClick={togglePublished}
							className='d-flex cursor-pointer align-items-center mb-1 me-1'
						>
							<CheckBox
								onChange={() => {}}
								checked={moderatorFilter.statusPublished}
								className='cursor-pointer me-1'
							/>
							<PublishedChip
								inactive={!moderatorFilter.statusPublished}
								label={
									publishedEntries + ' ' + t('nutzerbereich.veroeffentlicht')
								}
								onClick={togglePublished}
							/>
						</CheckChip>
						<CheckChip
							onClick={toggleDeleted}
							className='d-flex cursor-pointer align-items-center me-1 mb-1'
						>
							<CheckBox
								onChange={() => {}}
								checked={moderatorFilter.statusDeleted}
								className='cursor-pointer me-1'
							/>
							<DeletedChip
								inactive={!moderatorFilter.statusDeleted}
								label={deletedEntries + ' ' + t('nutzerbereich.geloescht')}
								onClick={toggleDeleted}
							/>
						</CheckChip>
					</div>
					<div className='d-flex flex-column align-items-end'>
						<SearchInput
							label={t('nutzerbereich.volltextsuche')}
							onChange={updateSearch}
							value={search}
							clear={() => {
								updateSearch({ target: { value: '' } });
							}}
						/>
					</div>
				</div>
				{user && user.is_admin && (
					<div className='gap-xl-1 mt-2 d-flex flex-wrap'>
						<CheckChip
							onClick={toggleOnlyNewTags}
							className='d-flex cursor-pointer align-items-center me-1 mb-1'
						>
							<CheckBox
								onChange={() => {}}
								checked={moderatorFilter.newTags === 'only'}
								className='cursor-pointer me-1'
							/>
							<NewTagChip
								inactive={!(moderatorFilter.newTags === 'only')}
								label={t('nutzerbereich.mit_neuem_schlagwort')}
								onClick={toggleOnlyNewTags}
							/>
						</CheckChip>
						<CheckChip
							onClick={toggleOnlyNoNewTags}
							className='d-flex cursor-pointer align-items-center'
						>
							<CheckBox
								onChange={() => {}}
								checked={moderatorFilter.newTags === 'none'}
								className='cursor-pointer me-1'
							/>
							<NoNewTagChip
								inactive={!(moderatorFilter.newTags === 'none')}
								label={t('nutzerbereich.ohne_neuem_schlagwort')}
								onClick={toggleOnlyNoNewTags}
							/>
						</CheckChip>
					</div>
				)}
				<StyledModal
					show={show}
					onHide={handleClose}
					dialogClassName='position-absolute vh-100 end-0'
				>
					<EntryFilterSidebar
						filter={{
							arbeitsgruppe: teams,
							referrer: referrers,
						}}
						updateFilter={updateFilter}
						section={section}
						setSection={setSection}
						handleClose={handleClose}
					/>
				</StyledModal>
			</div>
		</>
	);
};

FilterSettings.propTypes = {
	notValidatedEntries: PropTypes.number,
	publishedEntries: PropTypes.number,
	deletedEntries: PropTypes.number,
	toggleDeleted: PropTypes.func,
	toggleNotValidated: PropTypes.func,
	togglePublished: PropTypes.func,
	toggleOnlyNewTags: PropTypes.func,
	toggleOnlyNoNewTags: PropTypes.func,
	setTeams: PropTypes.func,
	referrers: PropTypes.array,
	setReferrers: PropTypes.func,
	teams: PropTypes.oneOf([PropTypes.string, PropTypes.array]),
	search: PropTypes.string,
	updateSearch: PropTypes.func,
	countFilter: PropTypes.func,
};

export { FilterSettings };
