import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';

import { isLoggedIn } from 'service/user.service';
import { useDispatch, useSelector } from 'react-redux';
import { errorActions } from 'store/errorSlice.js';
import { availableLanguages } from 'lib/utils.js';

function RouteGuard({ children }) {
	const router = useRouter();
	const [authorized, setAuthorized] = useState(false);
	const error = useSelector((state) => state.error);
	const dispatch = useDispatch();

	useEffect(() => {
		const tokenError = error.find((item) => {
			return item.data == 'Token is not correct';
		});
		console.log('got token error, will return to login', tokenError);
		if (tokenError) {
			dispatch(errorActions.removeAllErrors());
			router.push({
				pathname: '/nutzerbereich/login',
				query: { returnUrl: location.pathname },
			});
		}
	}, [error]);

	useEffect(() => {
		// on initial load - run auth check
		authCheck(router.asPath);

		// on route change start - hide page content by setting authorized to false
		const hideContent = () => setAuthorized(false);
		router.events.on('routeChangeStart', hideContent);

		// on route change complete - run auth check
		router.events.on('routeChangeComplete', authCheck);

		// unsubscribe from events in useEffect return function
		return () => {
			router.events.off('routeChangeStart', hideContent);
			router.events.off('routeChangeComplete', authCheck);
		};
	}, []);

	function authCheck(url) {
		// console.log('authCheck', url);
		// redirect to login page if accessing a private page and not logged in
		let publicPaths = [
			'/',
			'/start',
			'/startpage',
			'/test',
			'/ueber-vetopedia',
			'/impressum',
			'/datenschutz',
			'/anmelden',
			'/login',
			'/registrieren',
			'/login/pwreset',
			'/anmelden/pwreset',
			'/login/validate',
			'/anmelden/validate',
			'/impfschaden',
			'/impfschaden/schlagworte/lexicon',
			'/impfschaden/erfassen',
			'/nutzerbereich/anmelden',
			'/nutzerbereich/login',
			'/nutzerbereich/registrieren',
			'/nutzerbereich/passwort_vergessen',
			'/nutzerbereich/validate',
			'/nutzerbereich/aktivieren',
			'/adminbereich/anmelden',
			'/adminbereich/login',
			'/zensurkiosk',
			'/zensurkiosk/erfassen',
			// en todo: printf-wave t()
			'/startpage',
			'/vaccine-injuries',
			'/vaccine-injuries/record',
			'/censorship-kiosk',
			'/censorship-kiosk/record',
			// fr
			'/dommage-vaccinal',
			'/dommage-vaccinal/enregistrer',
			'/kiosque-de-censure',
			'/kiosque-de-censure/enregistrer',
			'/page-daccueil',
			// ru
			// es
			'/danos-por-vacunas',
			'/danos-por-vacunas/registro',
			'/quiosco-de-la-censura',
			'/quiosco-de-la-censura/registro',
			'/pagina-de-inicio',
			// it
			'/danno-da-vaccino',
			'/danno-da-vaccino/registrare',
			'/chiosco-della-censura',
			'/chiosco-della-censura/registrare',
			'/pagina-iniziale',
			// pt
			'/danos-causados-pelas-vacinas',
			'/danos-causados-pelas-vacinas/registo',
			'/quiosque-de-censura',
			'/quiosque-de-censura/registo',
			'/pagina-inicial',
			// ja
			//
			'/',
		];
		// add localized paths
		const pathNumber = publicPaths.length;
		for (const element of [
			...availableLanguages,
			{ lang_iso_code: '[lang]' },
		]) {
			for (let k = 0; k < pathNumber; k++) {
				publicPaths.push(`/${element?.lang_iso_code}${publicPaths[k]}`);
			}
		}
		const publicAreas = ['impfschaden', 'vaccine-injuries'];
		const path = url.split('?')[0];
		const area = url.substring(3).split('/')[1];
		// console.log('routeGuard', router, path, area, publicPaths);
		if (process.env.APP_MODE === 'DEV') {
			setAuthorized(true);
		} else if (
			!publicPaths.includes(path) &&
			!publicAreas.includes(area) &&
			!isLoggedIn()
		) {
			setAuthorized(false);
			router.push({
				pathname: '/nutzerbereich/login',
				query: { returnUrl: location.pathname },
			});
		} else {
			setAuthorized(true);
		}
	}

	return authorized && children;
}

export { RouteGuard };
