import { configureStore } from '@reduxjs/toolkit';
import loginReducer from 'store/loginSlice';
import masterDataReducer, { type MasterData } from 'store/masterDataSlice';
import vaxFormDataReducer, { type VaxEntry } from 'store/vaxFormDataSlice';
import vaxEditDataReducer from 'store/vaxEditDataSlice';
import navigationReducer from 'store/navigationSlice';
import formValidationReducer from 'store/formValidationSlice';
import errorReducer from 'store/errorSlice';
import settingsReducer, { type Settings } from 'store/settingsSlice';
import { globApi } from 'api/glob.api';
import { vaxApi } from 'api/vax.api';
import { kosmosApi } from 'api/kosmos.api';
import { userApi } from 'api/user.api';
import { internalApi } from 'api/internal.api';
import { translationApi } from 'api/translation.api';
import { rtkQueryErrorLogger } from './helpers/errorHandling';
import { createWrapper } from 'next-redux-wrapper';

export type AppState = {
	masterData: MasterData;
	settings: Settings;
	vaxFormData: VaxEntry;
	vaxEditData: VaxEntry;
};

export const store = configureStore({
	reducer: {
		[globApi.reducerPath]: globApi.reducer,
		[kosmosApi.reducerPath]: kosmosApi.reducer,
		[vaxApi.reducerPath]: vaxApi.reducer,
		[userApi.reducerPath]: userApi.reducer,
		[internalApi.reducerPath]: internalApi.reducer,
		[translationApi.reducerPath]: translationApi.reducer,
		login: loginReducer,
		masterData: masterDataReducer,
		vaxFormData: vaxFormDataReducer,
		vaxEditData: vaxEditDataReducer,
		formValidation: formValidationReducer,
		error: errorReducer,
		settings: settingsReducer,
		navigation: navigationReducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware()
			.concat(rtkQueryErrorLogger)
			.concat(globApi.middleware)
			.concat(kosmosApi.middleware)
			.concat(vaxApi.middleware)
			.concat(userApi.middleware)
			.concat(internalApi.middleware)
			.concat(translationApi.middleware),
});

const makeStore = () => store;

export const wrapper = createWrapper(makeStore);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
