import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import { FormHelperText } from 'components/ui';

const Input = (props) => {
	const label = props.label || '';
	return (
		<div>
			<TextField
				className={props.className}
				size='small'
				name={props.name}
				type={props.type}
				label={label}
				value={props.value || ''}
				onChange={props.onChange}
				onBlur={props.onBlur}
				required={props.required}
				error={!!props.isInvalid}
				disabled={props.disabled}
				fullWidth={props.fullWidth}
			/>
			{props.isInvalid && (
				<FormHelperText style={{ color: '#dc3545' }}>
					{props.isInvalid}
				</FormHelperText>
			)}
		</div>
	);
};

Input.propTypes = {
	type: PropTypes.string,
	name: PropTypes.string,
	label: PropTypes.string,
	value: PropTypes.string,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	required: PropTypes.bool,
	disabled: PropTypes.bool,
	fullWidth: PropTypes.bool,
	className: PropTypes.string,
	isInvalid: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export { Input };
