import { useEffect, useRef, useState } from 'react';

const useAnimatedStartCounter = (offlineTargetCount: number) => {
	const [entryCount, setEntryCount] = useState(0);
	const [origin, setOrigin] = useState(performance.now());
	const targetCountRef = useRef<number>();
	const animationRef = useRef<number>();
	const duration = 4000; // milliseconds
	const endOfAnimation = origin + duration;
	const previousTimeRef = useRef<number>();

	useEffect(() => {
		setOrigin(performance.now());
		targetCountRef.current = offlineTargetCount;
		animationRef.current = requestAnimationFrame(animate);
		return () => {
			cancelAnimationFrame(animationRef.current);
		};
	}, []);

	const animate = (time) => {
		if (performance.now() > endOfAnimation) {
			setEntryCount(targetCountRef.current);
			cancelAnimationFrame(animationRef.current);
			return;
		}
		const deltaTime = time - previousTimeRef.current;
		if (previousTimeRef.current != undefined) {
			setEntryCount((prevEntryCount) => {
				const now = performance.now();
				const elapsedTime = now - origin;
				const restDuration = duration - elapsedTime;
				const restCount = targetCountRef.current - prevEntryCount;
				const countPerMillisecond = restCount / (restDuration || 1);
				const stepIncrease = countPerMillisecond * deltaTime;
				// console.log(
				// 	'animate delta',
				// 	deltaTime,
				// 	'now',
				// 	performance.now(),
				// 	'restDuration',
				// 	restDuration,
				// 	'restCount',
				// 	restCount,
				// 	'end',
				// 	endOfAnimation
				// );
				return prevEntryCount + stepIncrease;
			});
		}

		previousTimeRef.current = time;
		animationRef.current = requestAnimationFrame(animate);
	};

	const updateTargetCount = (count) => {
		targetCountRef.current = count;
		if (performance.now() > origin + duration) {
			setEntryCount(count);
		}
	};

	return {
		animatedNumber: entryCount,
		setAsyncCount: updateTargetCount,
	};

	//
	// const [targetCount, setTargetCount] = useState(offlineTargetCount);
	// const [origin, setOrigin] = useState(performance.now());
	// const requestRef = useRef();
	// const previousTimeRef = useRef();
	// let animationDuration = 2000;
	// useEffect(() => {
	// 	setOrigin(performance.now());
	// 	//@ts-ignore
	// 	requestRef.current = requestAnimationFrame(animate);
	// 	return () => cancelAnimationFrame(requestRef.current);
	// }, []);
	// const setAsyncCount = (count) => {
	// 	setTargetCount(count);
	// 	cancelAnimationFrame(requestRef.current);
	// 	//@ts-ignore
	// 	requestRef.current = requestAnimationFrame(animate);
	// };
	// const animate = (time) => {
	// 	if (
	// 		Math.ceil(entryCount) >= targetCount ||
	// 		time > animationDuration + origin
	// 	) {
	// 		setEntryCount(targetCount);
	// 		cancelAnimationFrame(requestRef.current);
	// 		return;
	// 	}
	// 	if (previousTimeRef.current != undefined) {
	// 		const deltaTime = time - origin;
	// 		// Pass on a function to the setter of the state
	// 		// to make sure we always have the latest state
	// 		setEntryCount((prevCount) => {
	// 			return Math.round(targetCount / animationDuration) * deltaTime;
	// 		});
	// 	}
	// 	previousTimeRef.current = time;
	// 	//@ts-ignore
	// 	requestRef.current = requestAnimationFrame(animate);
	// };
	// return {
	// 	animatedNumber: entryCount,
	// 	setAsyncCount: setAsyncCount,
	// };
};

export default useAnimatedStartCounter;
