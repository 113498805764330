import { useCountryCountsLazyQuery } from 'api/vax.api.js';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/store.ts';
import { masterDataActions } from 'store/masterDataSlice';
import { cloneDeep, orderBy } from 'lodash';

const useCountryCounts = () => {
	const dispatch = useDispatch();
	const language = useSelector((state: AppState) => state.settings.language);
	const [fetchCountryCounts] = useCountryCountsLazyQuery();

	const getCountryCounts = async () => {
		const countryCounts = await fetchCountryCounts(null).unwrap();
		dispatch(
			masterDataActions.update({
				countryCounts: orderBy(
					cloneDeep(countryCounts.data),
					['total'],
					['desc']
				),
			})
		);
	};

	useEffect(() => {
		getCountryCounts();
	}, [language]);

	return [getCountryCounts];
};

export default useCountryCounts;
