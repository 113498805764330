import { FilterHeadline } from 'components/impfschaeden/uebersicht/components/FilterHeadline.js';
import { Button, CheckBox, DatePicker } from 'components/ui/index.js';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import cloneDeep from 'lodash/cloneDeep';

const SaveButton = styled.div`
	background: white;
	> button {
		width: 90%;
	}
`;

const VaxDateFilter = ({
	filter,
	updateFilter,
	onBack,
	filterKey,
	placeholder,
	handleClose,
}) => {
	const [t] = useTranslation();
	const [fromDate, setFromDate] = useState(filter[filterKey]?.start || null);
	const [toDate, setToDate] = useState(filter[filterKey]?.end || null);
	const [includeUnknown, setIncludeUnknown] = useState(
		filter[filterKey]?.include_unknown || false
	);

	const onReset = () => {
		let update = {};
		setFromDate(null);
		setToDate(null);
		setIncludeUnknown(false);
		update[filterKey] = { start: null, end: null };
		updateFilter(update);
	};

	const onDateFromChange = (newValue) => {
		if (!newValue) {
			return;
		}
		setFromDate(newValue);
		let update = {};
		if (filter[filterKey]) {
			update.admin_date = cloneDeep(filter[filterKey]);
		} else {
			update.admin_date = { start: null, end: null };
		}
		update.admin_date.start = newValue.format('YYYY-MM-DD');
		updateFilter(update);
	};

	const onDateToChange = (newValue) => {
		if (!newValue) {
			return;
		}
		setToDate(newValue);
		let update = {};
		update.admin_date = cloneDeep(filter[filterKey]);
		update.admin_date.end = newValue.format('YYYY-MM-DD');
		updateFilter(update);
	};

	const onIncludeUnknownDates = () => {
		let update = {};
		update.admin_date = cloneDeep(filter[filterKey]);
		update.admin_date.include_unknown = !update.admin_date.include_unknown;
		if (update.admin_date.include_unknown) {
			update.admin_date.unknown_day = true;
			update.admin_date.unknown_month = true;
			update.admin_date.unknown_date = false;
		} else {
			update.admin_date.unknown_day = false;
			update.admin_date.unknown_month = false;
			update.admin_date.unknown_date = false;
		}
		updateFilter(update);
		setIncludeUnknown(!includeUnknown);
	};

	const onUnknownDay = () => {
		let update = {};
		update.admin_date = cloneDeep(filter[filterKey]);
		update.admin_date.unknown_day = !update.admin_date.unknown_day;
		updateFilter(update);
	};
	const onUnknownMonth = () => {
		let update = {};
		update.admin_date = cloneDeep(filter[filterKey]);
		update.admin_date.unknown_month = !update.admin_date.unknown_month;
		updateFilter(update);
	};
	const onUnknownDate = () => {
		let update = {};
		update.admin_date = cloneDeep(filter[filterKey]);
		update.admin_date.unknown_date = !update.admin_date.unknown_date;
		updateFilter(update);
	};

	if (!filterKey) {
		return null;
	}

	return (
		<>
			<FilterHeadline
				title={t('common.zeitpunkt_der_impfung')}
				onReset={onReset}
				onBack={onBack}
				resetEnabled={filter[filterKey] != null}
			/>
			<>
				<div className='p-2 border-bottom'>
					<div>{t('common.suchzeitraum_von')}</div>
					<DatePicker onChange={onDateFromChange} value={fromDate} />
					<div>{t('common.suchzeitraum_bis')}</div>
					<DatePicker onChange={onDateToChange} value={toDate} />
				</div>
				{/* <div className='p-2'>
					<div>
						<CheckBox
							label={t('common.unbekannte_anzeigen')}
							onChange={onIncludeUnknownDates}
							checked={includeUnknown}
							value={includeUnknown}
							disabled={!fromDate && !toDate}
						/>
					</div>
					<div className='ps-3'>
						<CheckBox
							label={t('common.unbekannter_tag')}
							onChange={onUnknownDay}
							checked={includeUnknown && filter[filterKey].unknown_day}
							disabled={!includeUnknown}
						/>
						<CheckBox
							label={t('common.unbekannter_monat')}
							onChange={onUnknownMonth}
							checked={includeUnknown && filter[filterKey].unknown_month}
							disabled={!includeUnknown}
						/>
						<CheckBox
							label={t('common.unbekanntes_datum')}
							onChange={onUnknownDate}
							checked={includeUnknown && filter[filterKey].unknown_date}
							disabled={!includeUnknown}
						/>
					</div>
				</div> */}
				<SaveButton className='d-flex justify-content-center p-3 position-sticky bottom-0 border-top'>
					<Button disabled={filter[filterKey] === ''} onClick={handleClose}>
						{t('common.speichern')}
					</Button>
				</SaveButton>
			</>
		</>
	);
};

VaxDateFilter.propTypes = {
	filter: PropTypes.object,
	updateFilter: PropTypes.func,
	handleClose: PropTypes.func,
	onBack: PropTypes.func,
	options: PropTypes.arrayOf(PropTypes.object),
	defaultOption: PropTypes.object || PropTypes.null,
	filterKey: PropTypes.string,
	title: PropTypes.string,
	placeholder: PropTypes.string,
};

export { VaxDateFilter };
