import { SearchableFilter } from 'components/impfschaeden/uebersicht/components/SearchableFilter.js';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const LanguageFilter = ({ filter, updateFilter, onBack, handleClose }) => {
	const [t] = useTranslation();
	const [options, setOptions] = useState([]);
	const langCounts = useSelector((state) => state.masterData.languageCounts);
	const languages = useSelector((state) => state.masterData.languages);
	useEffect(() => {
		if (languages?.length > 0 && langCounts.length > 0) {
			setOptions(
				languages
					.filter((lang) => {
						return (
							langCounts.find((item) => {
								return item.lang_iso_code == lang.lang_iso_code;
							}) != null
						);
					})
					.map((language) => {
						return {
							key: language.lang_iso_code.toLowerCase(),
							name: language.lang_name
								? language.lang_name
								: language.lang_name_de,
							id: language.language_id,
							count: langCounts.find((item) => {
								return item.lang_iso_code == language.lang_iso_code;
							})?.total,
						};
					})
			);
		}
	}, [languages, langCounts]);
	const defaultOption = null;

	return (
		<SearchableFilter
			options={options}
			defaultOption={defaultOption}
			updateFilter={updateFilter}
			filter={filter}
			onBack={onBack}
			filterKey='filter_language'
			title={t('common.sprache')}
			placeholder={t('common.sprache_suchen')}
			handleClose={handleClose}
			keyInsteadId={true}
			skipTranslation={true}
			display={(option, rawOption) => {
				return '' + rawOption.count + ' ' + t('common.faelle');
			}}
		/>
	);
};

LanguageFilter.propTypes = {
	filter: PropTypes.object,
	updateFilter: PropTypes.func,
	onBack: PropTypes.func,
	handleClose: PropTypes.func,
};

export { LanguageFilter };
