import { getInternetLinkParts, highlight } from 'lib/utils.js';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { FormHelperText } from '@mui/material';
import { InfoCircle } from 'react-bootstrap-icons';
import VideoSlideshow from 'components/layout/VideoSlideshow.js';
import { Steckbrief } from 'components/impfschaeden/uebersicht/EntryCard/components/Steckbrief.js';
import { SourceReference } from 'components/impfschaeden/uebersicht/EntryCard/components/SourceReference.tsx';
import useEntrySourceReference from 'components/impfschaeden/uebersicht/hooks/useEntrySourceReference.ts';
import { OriginallyPostedDate } from 'components/impfschaeden/uebersicht/EntryCard/components/OriginallyPostedDate.tsx';
import useOriginallyPostedDate from 'components/impfschaeden/uebersicht/hooks/useOriginallyPostedDate.ts';

const EntryDescription = (props) => {
	const [t] = useTranslation();
	const sourceReferenceData = useEntrySourceReference(props);
	const originallyPostedDate = useOriginallyPostedDate(props);
	const {
		vax_entry_id,
		description,
		glob_referrer_id,
		own_entry,
		case_id,
		internet_link,
		created_at,
		vax_entry_files,
		lang_iso_code,
		search,
		long,
		detail,
		originally_posted_at,
	} = props;

	const [showMoreText, setShowMoreText] = useState(false);

	const language = useSelector((state) => state.settings.language);

	const globReferrers = useSelector((state) => state.masterData.globReferrers);

	const machineTranslation = useSelector(
		(state) => state.settings.machineTranslation
	);

	let sealDate = dayjs(created_at).format('L');
	let displayText = description;
	if (
		machineTranslation == true &&
		language != lang_iso_code &&
		!!props[language]
	) {
		displayText = props[language];
	}
	let textLimit = 500;
	let lastPointLimit = 300;
	if (vax_entry_files?.length > 0) {
		textLimit = 95;
		lastPointLimit = 80;
	}
	let text = displayText;
	if (long === true) {
		text = displayText;
	} else if (text.length > textLimit && showMoreText === false) {
		const lastPoint = text.substr(0, textLimit).lastIndexOf('.');
		const lastWhiteSpace = text.substr(0, textLimit).lastIndexOf(' ');
		if (lastPoint > lastPointLimit) {
			text = text.substr(0, lastPoint + 1) + ' ... ';
		} else if (lastWhiteSpace > lastPointLimit) {
			text = text.substr(0, lastWhiteSpace) + ' ... ';
		} else {
			text = text.substr(0, textLimit) + '...';
		}
	}
	if (search) {
		text = parse(highlight(search, text));
	}

	const autoTranslateActive = machineTranslation && displayText != description;

	if (!description) {
		return null;
	}

	// console.log('detail', props);

	return (
		<div className='text d-flex flex-column'>
			{autoTranslateActive && (
				<div className='d-flex justify-content-end'>
					<FormHelperText>
						<InfoCircle className='me-1' />
						{t('common.automatisch_uebersetzt')}
					</FormHelperText>
				</div>
			)}
			<div className='d-flex flex-column justify-content-between flex-grow-1'>
				<div>
					{text}
					{!long &&
						(displayText.length >= 500 ||
							(vax_entry_files?.length > 0 && displayText.length > 95)) && (
							<span onClick={() => setShowMoreText(!showMoreText)}>
								{' '}
								{showMoreText === false && (
									<a href={'#' + vax_entry_id}>{t('common.mehr_anzeigen')}</a>
								)}
								{showMoreText === true && (
									<a href={'#' + vax_entry_id}>
										{t('common.weniger_anzeigen')}
									</a>
								)}
							</span>
						)}
					{vax_entry_files?.length > 0 && !props.detail && (
						<VideoSlideshow {...props} />
					)}
				</div>
				<OriginallyPostedDate {...originallyPostedDate} />

				<div className='d-block d-lg-none'>
					<Steckbrief {...props} detail={true} />
				</div>
			</div>
		</div>
	);
};

EntryDescription.propTypes = {
	description: PropTypes.string,
	long: PropTypes.bool,
	vax_entry_files: PropTypes.array,
	vax_entry_id: PropTypes.number,
	glob_referrer_id: PropTypes.number,
	case_id: PropTypes.string,
	internet_link: PropTypes.string,
	created_at: PropTypes.string,
	lang_iso_code: PropTypes.string,
	search: PropTypes.string,
	detail: PropTypes.bool,
};

export { EntryDescription };

// Meine Friseurin ist gerade mal 34 und hat schon beide Impfungen von Pfizer. Nach der ersten Im bekam sie arge Bauchschmerzen und kam deswegen ins Spital. Es wurde aber nichts gefunden und sie wurde wieder entlassen. Nach der zweiten Impfung hat sie fast jeden Tag einen Puls von 200 und es geht ihr echt schlecht dabei. Der Arzt verschrieb ihr Nitro, ein Medikament für Herzinfarktpatienten Der Arzt sagt, das sei nicht von der Impfung, das kommt vom Stress
