import { useDispatch, useSelector } from 'react-redux';
import { formValidationActions } from 'store/formValidationSlice.js';
import { initialFormValidation } from 'store/formValidationSlice.js';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import {
	validateMailOrSkyfax,
	validatePassword,
	validateRequiredFields,
} from 'lib/validation.js';
import { getInternetLinkParts } from 'lib/utils.js';
import { hashtagId } from 'lib/regex.js';

const useValidation = () => {
	const vaxFormData = useSelector((state) => state.vaxFormData);
	const dispatch = useDispatch();
	const user = useSelector((state) => state.settings.currentUser);

	const requiredFields = {
		vaxStep3: ['anotherPerson'],
		vaxStep4: user
			? ['sex', 'country_iso_code', 'glob_age_groups_id']
			: [
					'first_name',
					'last_name',
					'sex',
					'country_iso_code',
					'glob_age_groups_id',
			  ],
		vaxStep5: ['vax_relationship_id'],
		vaxStep6: ['glob_referrer_id'],
		vaxStep7: ['healthy_before_vax'],
		vaxStep8: [
			'description',
			'vax_damage_occurred',
			'hospitalized',
			'glob_health_result_id',
			'vax_health_damage_tags',
		],
	};

	function checkUserData(data) {
		const required = checkUserRequired(data);
		const pw = data.user.password;
		const email = checkMail(data.user);
		const result = { ...required, ...email };
		if (data.user.createAccount && !required.password) {
			if (validatePassword(pw)) {
				result.password = 'passwort_zu_schwach';
			} else if (data.user.password !== data.user.passwordRepeat) {
				result.passwordRepeat = 'passworte_stimmen_nicht_ueberein';
			}
		}
		return result;
	}

	function checkUserRequired(data) {
		const userFields = [
			'user_salutation_id',
			'first_name',
			'last_name',
			'email',
		];
		if (data.user.createAccount) {
			userFields.push('password', 'passwordRepeat');
		}
		return validateRequiredFields(data.user, userFields, 'pflichtfeld');
	}

	const checkCaseID = (data) => {
		if (data.glob_referrer_id != '1') {
			return {};
		}
		if (!data.internet_link) {
			return {
				internet_link: 'pflichtfeld',
			};
		}

		const { source_domain } = getInternetLinkParts(
			data.internet_link,
			data.case_id
		);
		if (source_domain.substring(0, 4) == 't.me') {
			if (!data.case_id) {
				return {
					case_id: 'pflichtfeld',
				};
			} else {
				return {};
			}
		}
		
	};

	const specialRequirements = {
		vaxStep2: [checkUserData],
		vaxStep6: [checkCaseID],
		vaxStep7: [validatePreHealthDamage],
		vaxStep9: [checkVaccinations],
		vaxStep11: [checkFiles],
		profileForm: [checkMail],
	};

	function checkFiles(data) {
		// console.log('checkFiles', data);
		if (!data.vax_entry_files || data.vax_entry_files?.length == 0) {
			return {};
		}
		let result = [];
		for (let i = 0; i < data.vax_entry_files.length; i++) {
			const error = data.vax_entry_files[i].error;
			if (error) {
				result[i] = error;
			}
		}
		if (result.length > 0) {
			return { vax_entry_files: result };
		} else {
			return {};
		}
	}

	function checkMail(data) {
		if (typeof data.email === 'string' && validateMailOrSkyfax(data.email)) {
			return { email: 'emailadresse_ungueltig' };
		} else {
			return {};
		}
	}

	function validatePreHealthDamage(data) {
		if (
			data.healthy_before_vax === 'no' &&
			!data.vax_pre_health_damage_tags.length
		) {
			return {
				vax_pre_health_damage_tags: 'pflichtfeld',
			};
		}
	}

	function checkVaccinations(data) {
		if (data.vax_entry_vaccinations && data.vax_entry_vaccinations.length) {
			const vaccinationResult = { vax_entry_vaccinations: [] };
			for (let i = 0; i < data.vax_entry_vaccinations.length; i++) {
				let requiredFields = ['vax_type_id', 'vax_manufacturer_id'];
				if (i > 0) {
					requiredFields = ['vax_type_id', 'vax_manufacturer_id'];
				}
				for (const requiredField of requiredFields) {
					if (!data.vax_entry_vaccinations[i][requiredField]) {
						if (!vaccinationResult.vax_entry_vaccinations[i]) {
							vaccinationResult.vax_entry_vaccinations[i] = {};
						}
						vaccinationResult.vax_entry_vaccinations[i][requiredField] =
							'pflichtfeld';
					}
				}
				if (
					!data.vax_entry_vaccinations[i].admin_year ||
					data.vax_entry_vaccinations[i].admin_year == '----'
				) {
					if (!vaccinationResult.vax_entry_vaccinations[i]) {
						vaccinationResult.vax_entry_vaccinations[i] = {};
					}
					vaccinationResult.vax_entry_vaccinations[i].admin_year =
						'pflichtfeld';
				} else if (
					(!data.vax_entry_vaccinations[i].admin_month ||
						data.vax_entry_vaccinations[i].admin_month == '--') &&
					data.vax_entry_vaccinations[i].admin_year != '0000'
				) {
					if (!vaccinationResult.vax_entry_vaccinations[i]) {
						vaccinationResult.vax_entry_vaccinations[i] = {};
					}
					vaccinationResult.vax_entry_vaccinations[i].admin_month =
						'pflichtfeld';
				} else if (
					(!data.vax_entry_vaccinations[i].admin_day ||
						data.vax_entry_vaccinations[i].admin_day == '--') &&
					data.vax_entry_vaccinations[i].admin_month != '00' &&
					data.vax_entry_vaccinations[i].admin_year != '0000'
				) {
					if (!vaccinationResult.vax_entry_vaccinations[i]) {
						vaccinationResult.vax_entry_vaccinations[i] = {};
					}
					vaccinationResult.vax_entry_vaccinations[i].admin_day = 'pflichtfeld';
				}
				const age = data.vax_entry_vaccinations[i].age;
				const age_unit = data.vax_entry_vaccinations[i].age_unit;
				if (age && parseInt(age, 10).toString() !== age) {
					if (!vaccinationResult.vax_entry_vaccinations[i]) {
						vaccinationResult.vax_entry_vaccinations[i] = {};
					}
					vaccinationResult.vax_entry_vaccinations[i].age = 'zahlenfeld';
				} else if (age_unit == 'tag' && parseInt(age, 10) > 90) {
					if (!vaccinationResult.vax_entry_vaccinations[i]) {
						vaccinationResult.vax_entry_vaccinations[i] = {};
					}
					vaccinationResult.vax_entry_vaccinations[i].age = 'monat_verwenden';
				} else if (age_unit == 'monat' && parseInt(age, 10) > 24) {
					if (!vaccinationResult.vax_entry_vaccinations[i]) {
						vaccinationResult.vax_entry_vaccinations[i] = {};
					}
					vaccinationResult.vax_entry_vaccinations[i].age = 'jahr_verwenden';
				} else if (age_unit == 'jahr' && parseInt(age, 10) > 199) {
					if (!vaccinationResult.vax_entry_vaccinations[i]) {
						vaccinationResult.vax_entry_vaccinations[i] = {};
					}
					vaccinationResult.vax_entry_vaccinations[i].age = 'zu_alt';
				}
			}
			if (vaccinationResult.vax_entry_vaccinations.length)
				return vaccinationResult;
		}
	}

	function checkSpecialRequirements(form, data) {
		let result = [];
		if (!specialRequirements[form] || !Object.keys(data).length) {
			return {};
		}
		for (let i = 0; i < specialRequirements[form].length; i++) {
			const validation = specialRequirements[form][i];
			result[i] = validation.call(null, data, form);
		}
		return result.reduce((a, b) => Object.assign(a, b), {});
	}

	function validateForm(form, data) {
		// console.log('validateForm', form, data);
		const validationData = data ? data : vaxFormData;
		const initialValue = initialFormValidation;
		const resultRequired = validateRequiredFields(
			validationData,
			requiredFields[form],
			'pflichtfeld'
		);
		const resultMatch = checkSpecialRequirements(form, validationData);
		let mergedResult = cloneDeep(initialValue);
		if (
			!isEqual(resultMatch, mergedResult[form]) ||
			!isEqual(resultRequired, mergedResult[form])
		) {
			mergedResult[form] = {
				...mergedResult[form],
				...resultMatch,
				...resultRequired,
			};
		}
		dispatch(formValidationActions.setValidation(mergedResult));
		return mergedResult;
	}

	const removeUserValidation = (changedData) => {
		for (const userKey in changedData) {
			dispatch(formValidationActions.removeUserValidation(userKey));
		}
	};

	const removeValidation = (payload) => {
		for (const key in payload.changedData) {
			dispatch(
				formValidationActions.removeValidation({ key: key, step: payload.step })
			);
		}
	};

	const removeVaccinationValidation = (payload) => {
		dispatch(formValidationActions.removeVaccinationValidation(payload));
	};

	const validateAllSteps = () => {
		for (let i = 1; i < 13; i++) {
			validateForm('vaxStep' + i);
		}
	};

	const clearValidation = () => {
		dispatch(formValidationActions.clearValidation());
	};

	const isValid = (validationResult) => {
		const valid = isEqual(validationResult, initialFormValidation);
		return valid;
	};

	return {
		validateForm,
		validateAllSteps,
		removeValidation,
		removeUserValidation,
		removeVaccinationValidation,
		clearValidation,
		isValid,
	};
};

export default useValidation;
