'use client';
import { init, push } from '@socialgouv/matomo-next';
import { Suspense, useEffect } from 'react';

const MATOMO_URL = 'https://privacy.vetopedia.org';
const MATOMO_SITE_ID = '25';

const MatomoComponent = () => {
	useEffect(() => {
		if (MATOMO_URL && MATOMO_SITE_ID) {
			init({ url: MATOMO_URL, siteId: MATOMO_SITE_ID });
			_paq.push([
				'setDomains',
				[
					'*.www.vetopedia.ch',
					'*.vetopedia.ch',
					'*.vetopedia.info',
					'*.vetopedia.org',
					'*.www.vetopedia.ch',
					'*.www.vetopedia.info',
					'*.www.vetopedia.org',
					'*.vetopedia.ch',
					'*.vetopedia.info',
					'*.vetopedia.org',
					'*.www.vetopedia.info',
					'*.www.vetopedia.org',
				],
			]);
			push(['trackPageView']);
			push(['enableLinkTracking']);
		}
	}, []);
	return null;
};

export default function Matomo() {
	return (
		<Suspense>
			<MatomoComponent />
		</Suspense>
	);
}
