// import useValidation from 'components/impfschaeden/assistent/hooks/useValidation.js';
import {
	useUpdateVaxEntryMutation,
	useVaxEntryLazyQuery,
} from 'api/internal.api.ts';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { vaxEditDataActions } from 'store/vaxEditDataSlice.js';
import cloneDeep from 'lodash/cloneDeep';
import { useTranslation } from 'react-i18next';

export const mapPreIllnesTags = (dataCopy, t) => {
	dataCopy.vax_pre_health_damage_tags = dataCopy.vax_pre_health_damage_tags.map(
		(item) => {
			const healthDamage = item.glob_health_damage[0];
			if (healthDamage.lang_iso_code == 'translation') {
				healthDamage.name = t(
					'master_data.health_damage.' + healthDamage.health_damage_i18n_key
				);
			} else {
				healthDamage.name = healthDamage.health_damage_i18n_key;
			}

			return healthDamage;
		}
	);
	return dataCopy;
};

export const mapVaxHealthDamageTags = (dataCopy, t) => {
	dataCopy.vax_health_damage_tags = dataCopy.vax_health_damage_tags.map(
		(item) => {
			const healthDamage = item.glob_health_damage[0];
			if (healthDamage.lang_iso_code == 'translation') {
				healthDamage.name = t(
					'master_data.health_damage.' + healthDamage.health_damage_i18n_key
				);
			} else {
				healthDamage.name = healthDamage.health_damage_i18n_key;
			}

			return healthDamage;
		}
	);
	return dataCopy;
};

const useEditFormUpdate = () => {
	const dispatch = useDispatch();
	const [loadEntry, { data, isLoading: isFetching }] = useVaxEntryLazyQuery();
	const [updateVaxEntry, { isLoading, isSuccess }] =
		useUpdateVaxEntryMutation();
	const [t] = useTranslation();
	const [mappedData, setMappedData] = useState(null);
	const vaxEditData = useSelector((state) => state.vaxEditData);

	const mapData = () => {
		let dataCopy = cloneDeep(data);
		dataCopy = mapPreIllnesTags(dataCopy, t);
		dataCopy = mapVaxHealthDamageTags(dataCopy, t);
		return dataCopy;
	};

	useEffect(() => {
		if (!data) {
			return;
		}
		const dataCopy = mapData();
		dataCopy.edited = false;
		setMappedData(dataCopy);

		dispatch(vaxEditDataActions.update(dataCopy));
	}, [data]);

	const update = (payload) => {
		dispatch(vaxEditDataActions.update(payload));
		// removeValidation(payload);
	};
	const updateUser = (payload) => {
		dispatch(vaxEditDataActions.updateUser(payload));
		// removeUserValidation(payload);
	};
	const updateVaccination = (payload) => {
		dispatch(vaxEditDataActions.updateVaccination(payload.vaccinations));
		// removeVaccinationValidation(payload);
	};

	const updateFile = (payload) => {
		dispatch(vaxEditDataActions.updateFile(payload));
	};

	const saveEntry = () => {
		updateVaxEntry({ ...vaxEditData, glob_entry_status_id: 1 });
	};
	const deleteEntry = () => {
		updateVaxEntry({ ...mappedData, glob_entry_status_id: 3 });
	};
	const publishEntry = () => {
		updateVaxEntry({ ...mappedData, glob_entry_status_id: 2 });
	};
	const fetchEntry = (id) => {
		loadEntry(id);
	};

	const restoreData = () => {
		dispatch(vaxEditDataActions.update({ ...mappedData, edited: false }));
	};

	return {
		update,
		updateUser,
		updateVaccination,
		updateFile,
		fetchEntry,
		isFetching,
		restoreData,
		saveEntry,
		publishEntry,
		deleteEntry,
		isSuccess,
		isLoading,
	};
};

export default useEditFormUpdate;
