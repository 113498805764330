import { SearchableFilter } from 'components/impfschaeden/uebersicht/components/SearchableFilter.js';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import isArray from 'lodash/isArray';
import { useGetTeamsQuery } from 'api/internal.api.ts';
import orderBy from 'lodash/orderBy';

const TeamFilter = ({ filter, updateFilter, onBack, handleClose }) => {
	const [t] = useTranslation();
	const { data: teams } = useGetTeamsQuery();

	// const [fetchManufacturerCounts] = useManufacturerCountsLazyQuery();
	// const [manufacturerCounts, setManufacturerCounts] = useState([]);

	// const fetchManufacturerOptions = async () => {
	// 	const result = await fetchManufacturerCounts()
	// 		.unwrap()
	// 		.catch((e) => {
	// 			console.log(e);
	// 		});
	// 	if (result?.result == 'success') {
	// 		setManufacturerCounts(
	// 			orderBy(cloneDeep(result.data), ['count'], ['desc'])
	// 		);
	// 	} else {
	// 		console.log('error');
	// 	}
	// };

	// useEffect(() => {
	// 	fetchManufacturerOptions();
	// }, []);

	// useEffect(() => {
	// 	if (vaxManufacturers?.length > 0 && manufacturerCounts?.length > 0) {
	// 		setOptions(
	// 			orderBy(
	// 				vaxManufacturers
	// 					.map((manufacturer) => {
	// 						return {
	// 							key: manufacturer.manufacturer_i18n_key.toLowerCase(),
	// 							name: manufacturer.manufacturer_i18n_key,
	// 							id: manufacturer.vax_manufacturer_id,
	// 							count: manufacturerCounts.find((item) => {
	// 								return (
	// 									item.vax_manufacturer_id == manufacturer.vax_manufacturer_id
	// 								);
	// 							})?.vax_entry_vaccinations_count,
	// 						};
	// 					})
	// 					.filter((item) => {
	// 						return !!item.count && item.id != 1;
	// 					}),
	// 				['count'],
	// 				['desc']
	// 			)
	// 		);
	// 	}
	// }, [manufacturerCounts]);

	const defaultOption = null;

	if (!teams?.data || !teams.data.length > 0) {
		return null;
	}

	// console.log('team filter', teams?.data, filter);

	return (
		<SearchableFilter
			options={orderBy(
				teams?.data?.map((team) => {
					return {
						name: team.name,
						id: team.user_team_id,
						key: team.user_team_id.toString(),
					};
				}),
				[(item) => item.name]
			)}
			defaultOption={defaultOption}
			updateFilter={updateFilter}
			filter={{
				...filter,
				arbeitsgruppe: isArray(filter.arbeitsgruppe)
					? filter.arbeitsgruppe
					: [],
			}}
			onBack={onBack}
			filterKey='arbeitsgruppe'
			title={t('common.arbeitsgruppe')}
			placeholder={t('common.arbeitsgruppe_suchen')}
			handleClose={handleClose}
			skipTranslation={true}
		/>
	);
};

TeamFilter.propTypes = {
	filter: PropTypes.object,
	updateFilter: PropTypes.func,
	onBack: PropTypes.func,
	handleClose: PropTypes.func,
};

export { TeamFilter };
