import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBearer } from 'service/user.service';
import qs from 'qs';
import { RootState } from 'store/store.js';

export const internalApi = createApi({
	reducerPath: 'internalApi',
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.API_URL + 'v1/internal/',
		prepareHeaders: (headers, api) => {
			headers.set('accept', 'application/json');
			headers.set(
				'X-User-Language',
				(api.getState() as RootState).settings.language?.toLowerCase()
			);
			headers.set('Authorization', 'Bearer ' + getBearer());
			return headers;
		},
	}),
	endpoints: (build) => ({
		entries: build.query({
			query: (payload) => {
				let url = 'entries';
				if (payload) {
					const params = qs.stringify(payload, { arrayFormat: 'comma' });
					url += '?' + params;
				}
				return {
					url: url,
				};
			},
		}),
		vaxEntry: build.query({
			query: (payload) => {
				return { url: 'vax_entry/' + payload.id };
			},
		}),
		updateVaxEntry: build.mutation({
			query: (payload) => {
				return {
					url: 'vax_entry',
					method: 'POST',
					body: payload,
				};
			},
		}),
		publishVaxEntry: build.mutation({
			query: (payload) => {
				return {
					url: 'publish_vax_entry',
					method: 'POST',
					body: payload,
				};
			},
		}),
		deleteVaxEntry: build.mutation({
			query: (payload) => {
				return {
					url: 'delete_vax_entry',
					method: 'POST',
					body: payload,
				};
			},
		}),
		sendErrorMail: build.mutation({
			query: (payload) => {
				return {
					url: 'error_mail',
					method: 'POST',
					body: payload,
				};
			},
		}),
		editUserData: build.mutation({
			query: (payload) => {
				return {
					url: 'edit_user_data',
					method: 'POST',
					body: payload,
				};
			},
		}),
		editAvatar: build.mutation({
			query: (payload) => {
				return {
					url: 'edit_avatar',
					method: 'POST',
					body: payload.body,
				};
			},
		}),
		refreshToken: build.mutation({
			query: (payload) => {
				return {
					url: 'refresh_token',
					method: 'POST',
					body: payload,
				};
			},
		}),
		internalPasswordChange: build.mutation({
			query: (payload) => {
				return {
					url: 'pw_change',
					method: 'POST',
					body: payload,
				};
			},
		}),
		addTag: build.mutation({
			query: (payload) => ({
				url: 'add_tag',
				method: 'POST',
				body: payload,
			}),
		}),
		combineTags: build.mutation({
			query: (payload) => ({
				url: 'combine_tags',
				method: 'POST',
				body: payload,
			}),
		}),
		splitTag: build.mutation({
			query: (payload) => ({
				url: 'split_tag',
				method: 'POST',
				body: payload,
			}),
		}),
		healthDamageUsage: build.query({
			query: (payload) => {
				let url = 'tag_usage_count';
				if (payload) {
					const params = qs.stringify(payload, { arrayFormat: 'comma' });
					url += '?' + params;
				}
				return { url: url };
			},
		}),
		healthDamagesDirty: build.query({
			query: (payload) => {
				let url = 'tag_usages';
				if (payload) {
					const params = qs.stringify(payload, { arrayFormat: 'comma' });
					url += '?' + params;
				}
				return { url: url };
			},
		}),
		dirtyTags: build.query({
			query: (payload) => {
				let url = 'dirty_tags';
				if (payload) {
					const params = qs.stringify(payload, { arrayFormat: 'comma' });
					url += '?' + params;
				}
				return { url: url };
			},
		}),
		changeTag: build.mutation({
			query: (payload) => ({
				url: 'change_tag',
				method: 'POST',
				body: payload,
			}),
		}),
		deleteTag: build.mutation({
			query: (payload) => ({
				url: 'delete_tag',
				method: 'POST',
				body: payload,
			}),
		}),
		allHealthDamages: build.query({
			query: () => ({
				url: 'all_health_damages',
				method: 'GET',
			}),
		}),
		updateHealthDamage: build.mutation({
			query: (payload) => {
				return {
					url: 'health_damages/' + payload.id,
					method: 'PUT',
					body: { ...payload.change },
				};
			},
		}),
		assignRole: build.mutation({
			query: (payload) => {
				return {
					url: 'assign_role',
					method: 'POST',
					body: {
						role: payload.role,
						emailToPromote: payload.email,
						demote: payload.demote,
					},
				};
			},
		}),
		getUsersByRole: build.query({
			query: (payload) => {
				let url = 'get_users_by_role';
				if (payload?.role) {
					const params = qs.stringify(
						{ role: payload.role },
						{ arrayFormat: 'comma' }
					);
					url += '?' + params;
				}
				return { url: url };
			},
		}),
		sortTag: build.mutation({
			query: (payload) => ({
				url: 'sort_tag',
				method: 'POST',
				body: payload,
			}),
		}),
		uploadLexicon: build.mutation({
			query: (payload) => {
				return {
					url: 'upload_lexicon',
					method: 'POST',
					body: payload,
				};
			},
		}),
		addTeam: build.mutation({
			query: (payload) => {
				return {
					url: 'add_team',
					method: 'POST',
					body: payload,
				};
			},
		}),
		getTeams: build.query({
			query: () => ({
				url: 'get_teams',
				method: 'GET',
			}),
		}),
		assignTeam: build.mutation({
			query: (payload) => {
				return {
					url: 'assign_team',
					method: 'POST',
					body: payload,
				};
			},
		}),
		deleteFileInternal: build.mutation({
			query: (arg) => ({
				url: `files/delete/${arg.glob_file_id}`,
				method: 'DELETE',
			}),
		}),
	}),
});

export const useEntriesLazyQuery = internalApi.endpoints.entries.useLazyQuery;
export const useVaxEntryLazyQuery = internalApi.endpoints.vaxEntry.useLazyQuery;
export const useHealthDamageUsageLazyQuery =
	internalApi.endpoints.healthDamageUsage.useLazyQuery;
export const useHealthDamageDirtyLazyQuery =
	internalApi.endpoints.healthDamagesDirty.useLazyQuery;
export const useDirtyTagsLazyQuery =
	internalApi.endpoints.dirtyTags.useLazyQuery;
export const useAllHealthDamagesLazyQuery =
	internalApi.endpoints.allHealthDamages.useLazyQuery;

export const {
	useDirtyTagsQuery,
	useEntriesQuery,
	useUpdateVaxEntryMutation,
	usePublishVaxEntryMutation,
	useDeleteVaxEntryMutation,
	useSendErrorMailMutation,
	useEditUserDataMutation,
	useRefreshTokenMutation,
	useEditAvatarMutation,
	useInternalPasswordChangeMutation,
	useAddTagMutation,
	useCombineTagsMutation,
	useSplitTagMutation,
	useChangeTagMutation,
	useDeleteTagMutation,
	useAssignRoleMutation,
	useGetUsersByRoleQuery,
	useSortTagMutation,
	useUpdateHealthDamageMutation,
	useUploadLexiconMutation,
	useAddTeamMutation,
	useGetTeamsQuery,
	useAssignTeamMutation,
	useDeleteFileInternalMutation,
} = internalApi;
