import { useManufacturerCountsLazyQuery } from 'api/vax.api.js';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/store.ts';
import { masterDataActions } from 'store/masterDataSlice';
import { cloneDeep, orderBy } from 'lodash';

const useVaxManufacturerCounts = () => {
	const dispatch = useDispatch();
	const language = useSelector((state: AppState) => state.settings.language);
	const [fetchVaxManufacturerCounts] = useManufacturerCountsLazyQuery();

	const getVaxManufacturerCounts = async () => {
		const vaxManufacturerCounts = await fetchVaxManufacturerCounts(
			null
		).unwrap();
		dispatch(
			masterDataActions.update({
				vaxManufacturerCounts: orderBy(
					cloneDeep(vaxManufacturerCounts.data),
					['vax_entry_vaccinations_count'],
					['desc']
				),
			})
		);
	};

	useEffect(() => {
		getVaxManufacturerCounts();
	}, [language]);

	return [getVaxManufacturerCounts];
};

export default useVaxManufacturerCounts;
