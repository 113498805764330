import {
	UploadBar,
	TimesIcon,
	FormHelperText,
	SpinnerIcon,
	ArrowDownward,
	ArrowUpward,
} from 'components/ui/index.js';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { base62 } from 'mudder';
import { useHover } from '@reactuses/core';
import getFileIndex from 'store/helpers/getFileIndex.js';
import { useTranslation } from 'react-i18next';
import { toHtml, icon } from '@fortawesome/fontawesome-svg-core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const getSVGURI = (faIcon, color) => {
	const abstract = icon(faIcon).abstract[0];
	if (color) abstract.children[0].attributes.fill = color;
	return `data:image/svg+xml;base64,${btoa(toHtml(abstract))}`;
};

const backgroundImage = getSVGURI(faSpinner, '#your-icon-color');

let img = '';

const Styled = styled.div`
	display: flex;
	min-width: 64px;
	width: 100%;
	min-height: 48px;
	height: 96px;
	padding: 0px;
	cursor: pointer;
	border: 4px solid lightgray;
	border-radius: 4px;
	background: rgba(180, 180, 180, 0.3);
	background-position: left 40px top 0px;
	background-image: url(${img});
	/* background-image: radial-gradient(
		rgba(180, 180, 180, 0.3),
		rgba(200, 200, 200, 0.4)
	); */
	background-size: contain;
	background-repeat: no-repeat;
	margin: 28px 16px 0px 0px;
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	> .progress-bar {
	}
	@media (min-width: 0px) and (max-width: 576px) {
		// xs
		margin: 4px;
	}
	&.hovered {
		box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	}
	.loading-spinner-active {
		visibility: hidden;
		width: 0;
	}
`;

const RemoveBtn = styled.div`
	position: absolute;
	right: -16px;
	top: -16px;
	width: 28px;
	height: 28px;
	border-radius: 8px;
	background-color: rgba(196, 196, 196, 0.7);
	cursor: pointer;
	> svg > path {
		fill: red;
	}
	> svg {
		position: absolute;
		top: 1px;
		right: 5px;
	}
`;

// const TimesIconStyled = styled(TimesIcon)`
// 	/* font-size: 6px; */
// 	/* > .svg > path {
// 		fill: red;
// 	}
// 	> .svg-inline--fa {
// 		height: 8px;
// 	} */
// `;

// const TrashIconStyled = styled(TrashIcon)`
// 	font-size: 12px;
// `;

const UploadPreview = (props) => {
	const dispatch = useDispatch();
	const previewElem = useRef(null);
	const hovered = useHover(previewElem);
	const { t } = useTranslation();

	const [loading, setLoading] = useState(true);

	const handleLoadStart = () => setLoading(true);
	const handleLoadedData = () => setLoading(false);

	useEffect(() => {
		if (props.glob_file_id && previewElem.current) {
			// eslint-disable-next-line max-len
			previewElem.current.style.backgroundImage = `url('${process.env.API_URL}v1/glob/files/${props.glob_file_id}/${props.filename}')`;
		} else if (props.src && previewElem.current) {
			// eslint-disable-next-line max-len
			previewElem.current.style.backgroundImage = `url('${props.src}')`;
		}
	}, [props.src, props.glob_file_id]);

	const fileExtension = props.filename?.split('.').pop().toLowerCase();

	let fileType = 'image';
	switch (fileExtension) {
		case '3gp':
		case 'mp4':
		case 'bin':
		case 'mov':
		case 'webm':
			fileType = 'video';
			break;
		case 'pdf':
			fileType = 'document';
			break;
	}

	const onDelete = (e) => {
		e.stopPropagation();
		props.removeFile(props);
	};

	const moveUp = () => {
		let startIndex;
		let stopIndex;
		if (props.files.length == 0) {
			//do nothing
			return;
		}
		if (position == 0) {
			//do nothing
			return;
		}
		if (position == 1) {
			startIndex = '1';
			stopIndex = props.files[0].lexical_order;
		} else {
			startIndex = props.files[position - 2].lexical_order;
			stopIndex = props.files[position - 1].lexical_order;
		}
		const newLexicalOrder = base62.mudder(startIndex, stopIndex)[0];
		dispatch(
			props.changeFileOrder({
				filename: props.filename,
				lexical_order: newLexicalOrder,
			})
		);
	};

	const moveDown = () => {
		let startIndex;
		let stopIndex;
		if (props.files.length == 0) {
			//do nothing
			return;
		}
		if (position == props.files.length - 1) {
			//do nothing
			return;
		}
		if (position == props.files.length - 2) {
			startIndex = props.files[props.files.length - 1].lexical_order;
			stopIndex = 'z';
		} else {
			startIndex = props.files[position + 1].lexical_order;
			stopIndex = props.files[position + 2].lexical_order;
		}
		const newLexicalOrder = base62.mudder(startIndex, stopIndex)[0];

		dispatch(
			props.changeFileOrder({
				filename: props.filename,
				lexical_order: newLexicalOrder,
			})
		);
	};

	const position = getFileIndex(props.files, { filename: props.filename });

	return (
		<>
			<Styled
				title={props.filename}
				ref={previewElem}
				className={hovered ? 'hovered' : ''}
				backgroundImage={backgroundImage}
			>
				{fileType === 'video' && (
					<div className='w-100 d-flex justify-content-between position-relative'>
						{hovered && (
							<div className='position-absolute d-flex flex-column justify-content-between m-2 h-75 py-1'>
								<div
									onClick={moveUp}
									style={{
										visibility:
											position == 0 || props.files.length == 1
												? 'hidden'
												: 'visible',
									}}
								>
									<ArrowUpward sx={{ filter: 'blur(4px)' }} color='primary' />
								</div>
								<div
									onClick={moveDown}
									style={{
										visibility:
											position == props.files.length - 1 ||
											props.files.length == 1
												? 'hidden'
												: 'visible',
									}}
								>
									<ArrowDownward sx={{ filter: 'blur(4px)' }} color='primary' />
								</div>
							</div>
						)}
						<div className='d-flex flex-column justify-content-between m-2 h-75 py-1'>
							<div
								style={{
									visibility:
										position == 0 || props.files.length == 1
											? 'hidden'
											: 'visible',
								}}
							>
								<ArrowUpward />
							</div>
							<div
								style={{
									visibility:
										position == props.files.length - 1 ||
										props.files.length == 1
											? 'hidden'
											: 'visible',
								}}
							>
								<ArrowDownward />
							</div>
						</div>
						{loading && (
							<div
								className='d-flex justify-content-center align-items-center'
								style={{ width: '156px', height: '88px' }}
							>
								<SpinnerIcon />
							</div>
						)}
						<video
							onLoadedData={handleLoadedData}
							onLoadStart={handleLoadStart}
							className={loading ? 'loading-spinner-active' : ''}
							src={`${process.env.API_URL}v1/glob/files/${props.glob_file_id}/${props.filename}`}
							height='88px'
						>
							Your browser does not support the video tag.
						</video>
						<div className='flex-grow-1 d-flex justify-content-center pt-3'>
							<div className='d-flex flex-column'>
								<div>{props.filename}</div>
								{props.error && (
									<div style={{ color: 'red' }}>
										{t('error.fehler_beim_hochladen') +
											' ' +
											props.error.status}
									</div>
								)}
							</div>
						</div>
					</div>
				)}
				{fileType !== 'video' && (
					<div className='w-100 d-flex flex-row justify-content-between m-2 py-1 position-relative'>
						{hovered && (
							<div className='position-absolute d-flex flex-column justify-content-between py-1'>
								<div
									onClick={moveUp}
									style={{
										visibility:
											position == 0 || props.files.length == 1
												? 'hidden'
												: 'visible',
									}}
								>
									<ArrowUpward sx={{ filter: 'blur(4px)' }} color='primary' />
								</div>
								<div
									onClick={moveDown}
									style={{
										visibility:
											position == props.files.length - 1 ||
											props.files.length == 1
												? 'hidden'
												: 'visible',
									}}
								>
									<ArrowDownward sx={{ filter: 'blur(4px)' }} color='primary' />
								</div>
							</div>
						)}
						<div className='d-flex flex-column justify-content-between h-100'>
							<div
								style={{
									visibility:
										position == 0 || props.files.length == 1
											? 'hidden'
											: 'visible',
								}}
							>
								<ArrowUpward />
							</div>
							<div
								style={{
									visibility:
										position == props.files.length - 1 ||
										props.files.length == 1
											? 'hidden'
											: 'visible',
								}}
							>
								<ArrowDownward />
							</div>
						</div>
						<div className='flex-grow-1 d-flex justify-content-center pt-3'>
							<div className='d-flex flex-column'>
								<div>{props.filename}</div>
								{props.error && (
									<div style={{ color: 'red' }}>
										{t('error.fehler_beim_hochladen') +
											' ' +
											props.error.status}
									</div>
								)}
							</div>
						</div>
					</div>
				)}
				<RemoveBtn style={{ fontSize: '26px' }} onClick={onDelete}>
					<TimesIcon style={{ alignItems: 'center' }}></TimesIcon>
				</RemoveBtn>
				<div>
					<UploadBar progress={props.progress || 0} error={props.error} />
				</div>
			</Styled>
			{props.error && (
				<>
					<div className='w-100 d-flex justify-content-center'>
						<FormHelperText style={{ color: '#dc3545' }}>
							{t('common.please_remove_files_with_errors')}
						</FormHelperText>
					</div>
					{props?.error?.data?.message && (
						<>
							<br />
							<div className='w-100 d-flex justify-content-center'>
								<FormHelperText style={{ color: '#dc3545' }}>
									{t(props.error.data.message)}
								</FormHelperText>
							</div>
						</>
					)}
				</>
			)}
		</>
	);
};

UploadPreview.propTypes = {
	progress: PropTypes.number,
	filename: PropTypes.string,
	glob_file_id: PropTypes.string,
	src: PropTypes.string,
	removeFile: PropTypes.func,
	lexical_order: PropTypes.string,
	files: PropTypes.array,
	index: PropTypes.number,
	changeFileOrder: PropTypes.func,
	validation: PropTypes.object,
};

export { UploadPreview };
