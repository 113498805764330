import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const kosmosApi = createApi({
	reducerPath: 'kosmosApi',
	baseQuery: fetchBaseQuery({
		baseUrl: 'https://api.kosmos.click/',
		prepareHeaders: (headers) => {
			headers.set('accept', 'application/json');
			return headers;
		},
	}),
	endpoints: (build) => ({
		countries: build.query({
			query: (lang) => {
				return {
					url: 'countries',
					params: {
						display_lang: lang?.substring(0, 2) || 'en',
					},
				};
			},
		}),
		languages: build.query({
			query: (lang) => ({
				url: 'languages',
				params: {
					lang_iso_code: lang,
					// translated: true,
					display_lang: lang,
				},
			}),
		}),
	}),
});

export const useCountriesLazyQuery = kosmosApi.endpoints.countries.useLazyQuery;
export const useLanguagesLazyQuery = kosmosApi.endpoints.languages.useLazyQuery;

export const { useCountriesQuery, useLanguagesQuery } = kosmosApi;
