import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';


const DatePicker = ({ onChange, required, isInvalid, value, ...rest }) => {
	const dateValue = typeof value === 'string' ? dayjs(value) : value;
	const language = useSelector((state) => state.settings.language);
	if (!language) {
		console.error('language is not set', language);
	}
	return (
		<LocalizationProvider
			dateAdapter={AdapterDayjs}
			adapterLocale={language || 'en-US'}
		>
			<MuiDatePicker
				{...rest}
				value={dateValue}
				onChange={onChange}
				required={required}
				renderInput={(params) => <TextField {...params} />}
				error={!!isInvalid}
			/>
		</LocalizationProvider>
	);
};

DatePicker.propTypes = {
	label: PropTypes.string,
	value: PropTypes.object || PropTypes.string,
	onChange: PropTypes.func,
	required: PropTypes.bool,
	isInvalid: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export { DatePicker };
