import useUpdateCheck from 'hooks/useUpdateCheck.js';
import { useEffect, useState } from 'react';

const useLocalStoredState = (initialState, storageName) => {
	const [state, setState] = useState(initialState);
	useEffect(() => {
		const { hasUpdate } = useUpdateCheck();
		let storedValue;
		if (!hasUpdate()) {
			storedValue = JSON.parse(localStorage.getItem(storageName))?.item;
		}
		if (typeof storedValue !== 'undefined') {
			setState(storedValue);
		} else {
			storeState(initialState);
		}
	}, []);
	const storeState = (newVal) => {
		localStorage.setItem(storageName, JSON.stringify({ item: newVal }));
		setState(newVal);
	};
	return { state, setState, storeState };
};

export default useLocalStoredState;
