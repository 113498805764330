/* eslint-disable max-len */
const Logo = ({ color }) => {
	return (
		<svg
			width='255'
			height='229'
			viewBox='0 0 255 229'
			fill={color}
			xmlns='http://www.w3.org/2000/svg'
		>
			<path d='M225 120C225 68.0852 182.915 26 131 26C121.615 26 112.552 27.3753 104 29.9353L105 33.8126C113.232 31.3328 121.96 30 131 30C180.706 30 221 70.2944 221 120C221 142.81 212.514 163.639 198.527 179.5L203.694 179.531L204 179.225C217.131 163.06 225 142.449 225 120Z' />
			<path d='M37 120C37 138.8 42.5188 156.31 52.0246 171H56.8346C46.8473 156.504 41 138.935 41 120C41 97.4287 49.3089 76.7981 63.035 61L60.3443 58C45.8115 74.5485 37 96.2452 37 120Z' />
		</svg>
	);
};

export { Logo };
