import { Card } from 'react-bootstrap';
import styled from 'styled-components';

export const InfoCard = styled(Card)`
	color: #5e5e5e;
	width: 369px;
`;

export const AssistantCard = styled(Card)`
	padding: 30px;
	min-height: 530px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

export const SquareShadowCard = styled(Card)`
	border-radius: 0;
	border: 0.5px solid #c4c4c4;
	box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.09);
`;

export const StyledEntryCard = styled(Card)`
	border: none;
	box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.09);
	&.hovered {
		box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	}
`;

export { Card };
