import { LexiconButton } from 'VaxForm_08_Impfschaeden/components/LexikonButton.js';
import PropTypes from 'prop-types';

const TagLexiconFilter = ({
	selected,
	onSelect,
	setSelectChilds,
	selectChilds,
}) => {
	return (
		<LexiconButton
			selected={selected}
			onSelect={onSelect}
			filter={true}
			setSelectChilds={setSelectChilds}
			selectChilds={selectChilds}
		/>
	);
};

TagLexiconFilter.propTypes = {
	selected: PropTypes.array,
	onSelect: PropTypes.func,
	setSelectChilds: PropTypes.func,
	selectChilds: PropTypes.bool,
};

export { TagLexiconFilter };
