import { createSlice } from '@reduxjs/toolkit';
import { initialVaccinationData } from 'VaxForm_09_Impfungen/VaxForm_09.js';
import getFileIndex from './helpers/getFileIndex';

const presetData = {
	user: {},
	vax_entry_vaccinations: [initialVaccinationData],
	vax_entry_files: [],
	age_unit: 'jahr',
	glob_age_groups_id: '1',
};

const getInitialState = () => {
	return {
		...presetData,
		// ...devData, // TODO remove before publish
	};
};

const vaxEditDataSlice = createSlice({
	name: 'vaxEditData',
	initialState: getInitialState,
	reducers: {
		update(state, action) {
			return {
				...state,
				...{ edited: true },
				...action.payload,
			};
		},
		updateUser(state, action) {
			state.edited = true;
			state.user = {
				...state.user,
				...action.payload,
			};
		},
		updateVaccination(state, action) {
			state.vax_entry_vaccinations = action.payload;
			state.edited = true;
		},
		changeFileOrder(state, { payload }) {
			const index = getFileIndex(state.vax_entry_files, payload);
			state.vax_entry_files[index].lexical_order = payload.lexical_order;
			state.edited = true;
		},
		upload(state, { payload }) {
			const index = getFileIndex(state.vax_entry_files, payload);
			if (index > -1) {
				state.vax_entry_files[index].filename = payload.filename;
				state.vax_entry_files[index].src = payload.src;
			} else {
				state.edited = true;
				state.vax_entry_files.push({
					filename: payload.filename,
					src: payload.src,
				});
			}
		},
		uploadStatus(state, { payload }) {
			const index = getFileIndex(state.vax_entry_files, payload);
			const progress = Math.round((100 * payload.loaded) / payload.total);
			state.vax_entry_files[index].progress = progress;
		},
		uploadFinished(state, { payload }) {
			const index = getFileIndex(state.vax_entry_files, payload);
			state.vax_entry_files[index] = {
				...payload,
				progress: 100,
				src: `${process.env.API_URL}v1/glob/files/${payload.glob_file_id}/${payload.filename}`,
			};
		},
		download() {},
		removeFile(state, { payload }) {
			const index = getFileIndex(state.vax_entry_files, payload);
			state.vax_entry_files.splice(index, 1);
		},
	},
});

export const vaxEditDataActions = vaxEditDataSlice.actions;

export default vaxEditDataSlice.reducer;
