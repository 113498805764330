import { createSlice } from '@reduxjs/toolkit';

const navigationSlice = createSlice({
	name: 'navigation',
	initialState: { activeNavLinkId: '', clickAction: false },
	reducers: {
		setActiveNavLinkId(state, { payload }) {
			state.activeNavLinkId = payload;
		},
		setClickAction(state, { payload }) {
			state.clickAction = payload;
		},
	},
});

export const navigationActions = navigationSlice.actions;

export default navigationSlice.reducer;
