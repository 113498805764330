import {
	FacebookIcon,
	TelegramIcon,
	TwitterSquareIcon,
} from 'components/ui/Icon';

const SocialIcons = () => {
	return (<span></span>);

// TODO: Activate Social Icons, when correct linked

// 	return (
// 		<div>
// 			<span className='mx-1'>
// 				<FacebookIcon size='2x' />
// 			</span>
// 			<span className='mx-1'>
// 				<TelegramIcon size='2x' />
// 			</span>
// 			<span className='mx-1'>
// 				<TwitterSquareIcon size='2x' />
// 			</span>
// 		</div>
// 	);
};

export default SocialIcons;
